let LGBTQIA = [
  {
    text: "Behind these letters, there are lives, dreams and projects.",
    tags: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-lesbica.svg`, name: 'lesbians'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-gays.svg`, name: 'gays'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-bissexuais.svg`, name: 'bisexuals'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-transgeneros.svg`, name: 'transgender & travestis'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-queers.svg`, name: 'queers'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-interssexuais.svg`, name: 'intersex'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-assexuais.svg`, name: 'asexuals'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-outros.svg`, name: '+others'},
    ]
  },
  {
    title: "Sexual Orientation",
    subtitle: "It is what indicates the physical and/or emotional attraction of a person to another.",
    text: "There's no one who can say how to love. <br/> Relationships are meant to be lived free of ties.",
    cards: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-assexual.svg`, name: 'Asexual', text:'Does not feel sexual attraction'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-bissexual.svg`, name: 'Bisexual', text:'Feels attraction for two or more genres'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-pansexual.svg`, name: `<span class="tooltip-container">PANSEXUAL <span class="tooltip-text bg-orange tooltip-top">Over time, the difference between bisexual and pansexual has narrowed. Currently, both identities contemplate people who are attracted to more than one gender and/or regardless of gender. The preference for one or the other classification depends on each person.</span></span>`, text:'Feels attraction regrdless of genre'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-heterossexual.svg`, name: 'HETEROSEXUAL', text:'Feels attraction for the opposite genre'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-homossexual.svg`, name: 'HOMOSEXUAL', text:'Feels attraction for the same genre'}
    ]
  },
  {
    title: "Homophobia and Transphobia",
    subtitle: 'They are forms of prejudice and discrimination against homosexuals, lesbians, bisexuals, trans and transvestites. On a daily basis, they manifest themselves as feelings of aversion, exclusion in advertising and even disguised as "opinions" and "jokes".',
    text: "Some anti-discrimination laws",
    description: "LGBTphobia goes beyond physical violence. <br/>Words can also hurt.",
    box: [
      {year: '2003', country: 'México', flag: '🇲🇽', law: 'Federal Law to Prevent and Eradicate Discrimination'},
      {year: '2011', country: 'Colômbia', flag: '🇨🇴', law: 'Law 1.482'},
      {year: '2012', country: 'Argentina', flag: '🇦🇷', law: 'Law 26.791'},
      {year: '2012', country: 'Chile', flag: '🇨🇱', law: 'Law Zamudio'},
      {year: '2017', country: 'Peru', flag: '🇵🇪', law: 'Legislative Decree 1.323'},
      {year: '2019', country: 'Brasil', flag: '🇧🇷', law: 'Law 7.716/1989'}
    ]
  }
]
export function getLGBTQIAEN() {
  return LGBTQIA
}