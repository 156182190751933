import React from 'react'
import { useParams } from "react-router-dom"

import Estrategia from '../../components/Diversidade/Estrategia'
import Inovacao from '../../components/Diversidade/Inovacao'
import Publicidade from '../../components/Diversidade/Publicidade'
import Perfil from '../../components/Diversidade/Perfil'
import Esteriotipos from '../../components/Esteriotipos'
import Representatividade from '../../components/Representatividade'
import Rotina from '../../components/Rotina'
import BoasPraticas from '../../components/BoasPraticas'

import Infos from '../../components/Infos'
import Prev from '../../components/ArrowPrev'
import Next from '../../components/ArrowNext'
import Breadcrumb from '../../components/Breadcrumb'

import * as S from './styled'

function Hero({ data }) {
  const params = useParams()
  const currentiItem = data.menu.filter(item => item.slug === params.pageId)
  let length = data.menu.length - 1

  return (
    <div>
      <Breadcrumb data={data} current={ currentiItem[0] } />
      <S.Hero>
        <Prev current={ currentiItem[0] } />
        <S.Carousel 
          className={`hero-${currentiItem[0].slug}`}
          color={ currentiItem[0]?.hero?.subtitle === 'Latam' ? '#E54A5A' : '#94C15B'}
        >
          <S.Columns>
            <Infos current={currentiItem[0]} type={`hero-${currentiItem[0].slug}`} />
            <S.Content className='content'>
              {data.slug === 'esteriotipos' ?
                <Esteriotipos data={ currentiItem[0].hero } />
              : currentiItem[0].slug === 'estrategia' ? 
                <Estrategia data={ currentiItem[0].hero } /> 
              : currentiItem[0].slug === 'inovacao' ? 
                <Inovacao data={ currentiItem[0].hero } />
              : currentiItem[0].slug === 'publicidade-brasileira' ?
                <Publicidade data={ currentiItem[0].hero } />
              : currentiItem[0].slug === 'perfil' ? 
                <Perfil data={ currentiItem[0].hero.cards } slug={ data.slug } margin={'0px'} />
              : data.slug === 'representatividade' ?
                <Representatividade data={ currentiItem[0].hero } slug={ data.slug } />
              : data.slug === 'rotina' ?
                <Rotina data={ currentiItem[0].hero } slug={ data.slug } />
              : <BoasPraticas data={ currentiItem[0].hero } slug={ data.slug } />
              }
            </S.Content>
          </S.Columns>
        </S.Carousel>
        <Next current={ currentiItem[0] } last={length} />
      </S.Hero>

      <S.Hero className='mobile'>
        <Prev current={ currentiItem[0] } />
        <Next current={ currentiItem[0] } last={length} />
      </S.Hero>
    </div>
  )
}
export default Hero