import React from 'react'
import CallToAction from '../../components/CallToAction'
import Cards from '../../components/Card'
import Hero from '../../containers/Hero'
import Layout from '../../containers/Layout'
import * as S from './styled'

function Subpage({ data }) {
  let cards = data.menu
  if (data.slug === 'representatividade' ) {
    cards = data.menu.filter(item => item.hero.subtitle !== 'Latam')
  }
  return (
    <Layout>
      <Hero data={ data } />
      <S.Container>
        <S.CardsContainer>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <h3>Veja também</h3>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <h3>See too</h3>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <h3>Vea también</h3>
          : null}
          <Cards data={cards} slug={data.slug} />
        </S.CardsContainer>
        <CallToAction />
      </S.Container>
    </Layout>
  )
}
export default Subpage
