import React from 'react'
import * as S from './styled'

function ImageText({ data }) {
  return (
    <S.Container>
      <S.Img src={data?.banner} alt="futebol" />
      <S.Columns>
        {data?.columns.map((column, index) => (
          <S.Column key={index}>
            <S.Title>{column.title}</S.Title>
            <S.Text dangerouslySetInnerHTML={{__html: column.text}} /> 
          </S.Column>
        ))}
      </S.Columns>
    </S.Container>
  )
}
export default ImageText