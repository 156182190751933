let LGBTQIA = [
  {
    text: "Por detrás de estas letras, hay vidas, sueños y proyectos.",
    tags: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-lesbica.svg`, name: 'lesbianas'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-gays.svg`, name: 'homosexuales'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-bissexuais.svg`, name: 'bisexuales'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-transgeneros.svg`, name: 'transgénero y travestis'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-queers.svg`, name: 'queers'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-interssexuais.svg`, name: 'intersexual'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-assexuais.svg`, name: 'asexuales'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-outros.svg`, name: '+otros'},
    ]
  },
  {
    title: "Orientación Sexual",
    subtitle: "Es lo que indica la atracción física y/o emocional de una persona hacia otra.",
    text: "No hay quien pueda decirte como amar. <br/>Las relaciones deben vivirse libres de amarras.",
    cards: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-assexual.svg`, name: 'ASEXUAL', text:'No siente atracción sexual'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-bissexual.svg`, name: 'BISEXUAL', text:'Siente atracción por dos o más géneros'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-pansexual.svg`, name: `<span class="tooltip-container">PANSEXUAL <span class="tooltip-text bg-orange tooltip-top">Con el tiempo, la diferencia entre bisexuales y pansexuales se ha reducido. Actualmente, ambas identidades contemplan personas que se sienten atraídas por más de un género y/o sin importar el género. La preferencia por una u otra clasificación depende de cada persona.</span></span>`, text:'Siente atracción independientemente del género'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-heterossexual.svg`, name: 'HÉTEROSEXUAL', text:'Siente atracción por el sexo opuesto'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-homossexual.svg`, name: 'HOMOSEXUAL', text:'Siente atracción por el mismo género'}
    ]
  },
  {
    title: "Homofobia y Transfobia",
    subtitle: 'Son formas de prejuicio y discriminación contra homosexuales, lesbianas, bisexuales, trans y travestis. En el día a día se manifiestan como sentimientos de aversión, exclusión en la publicidad e incluso disfrazados de "opiniones" y "chistes".',
    text: "Algunas leyes contra la discriminación",
    description: "La LGBTfobia va más allá de la violencia física. <br/>Las palabras también pueden doler.",
    box: [
      {year: '2003', country: 'México', flag: '🇲🇽', law: 'Ley Federal para Prevenir y Erradicar la Discriminación'},
      {year: '2011', country: 'Colombia', flag: '🇨🇴', law: 'Ley 1.482'},
      {year: '2012', country: 'Argentina', flag: '🇦🇷', law: 'Ley 26.791'},
      {year: '2012', country: 'Chile', flag: '🇨🇱', law: 'Ley Zamudio'},
      {year: '2017', country: 'Peru', flag: '🇵🇪', law: 'Decreto legislativo 1.323'},
      {year: '2019', country: 'Brasil', flag: '🇧🇷', law: 'Ley 7.716/1989'}
    ]
  }
]
export function getLGBTQIAES() {
  return LGBTQIA
}