let intro = {
  logo: `${process.env.PUBLIC_URL}/assets/logo-white.svg`,
  button: 'clique para avançar',
  nav: ' OK! Estou ciente',
  title: `Antes de mais nada, vamos fazer alguns combinados! <br/> 3 premissas que ninguém pode esquecer.`,
  list: [{
    title: "A luta por uma publicidade diversa e um ambiente de trabalho inclusivo começa com você.",
    text: "Esteja aberto para aprender: escute, leia e pense duas, três, quatro vezes antes de julgar uma realidade que você não conhece ou não vivencia em seu corpo",
  },{
    title: "Transformar o conhecimento em ações é uma tarefa de todos. Ninguém muda uma empresa, um mercado ou o mundo sozinho.",
    text: " O diálogo é uma necessidade básica. Valorize o conhecimento de cada membro da sua equipe, pensem juntos em como promover espaços igualitários e justos.",
  },{
    title: "O aprendizado é uma constante.",
    text: "Você não se tornará especialista em diversidade e inclusão com este Guia, mas está dando um passo importante. Não pare aqui! Nós te encorajamos a ativamente buscar por mais informações, dados e pesquisas.",
  }]
}
export function getIntroPT() {
  return intro
}