import { getIntroPT } from './pt/intro'
import { getIntroEN } from './en/intro'
import { getIntroES } from './es/intro'

import { getHeaderPT } from './pt/geral'
import { getHeaderEN } from './en/geral'
import { getHeaderES } from './es/geral'

import { getFooterPT } from './pt/geral'
import { getFooterEN } from './en/geral'
import { getFooterES } from './es/geral'

import { getMenuPT } from './pt/menu'
import { getMenuEN } from './en/menu'
import { getMenuES } from './es/menu'

import { getClassesPT } from './pt/classe'
import { getClassesEN } from './en/classe'
import { getClassesES } from './es/classe'

import { getLGBTQIAPT } from './pt/LGBTQIA'
import { getLGBTQIAEN } from './en/LGBTQIA'
import { getLGBTQIAES } from './es/LGBTQIA'

import { getAdsPT } from './pt/ads'
import { getAdsEN } from './en/ads'
import { getAdsES } from './es/ads'

import { getDosPT } from './pt/dos'
import { getDosEN } from './en/dos'
import { getDosES } from './es/dos'

import { getDiversityPT, getRepresentsPT, getStereotypesPT, getRoutinePT, getPracticesPT } from './pt/page'
import { getDiversityEN, getRepresentsEN, getStereotypesEN, getRoutineEN, getPracticesEN } from './en/page'
import { getDiversityES, getRepresentsES, getStereotypesES, getRoutineES, getPracticesES } from './es/page'

export function intro() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getIntroPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getIntroEN() 

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getIntroES()

    : null
  )
}

export function header() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getHeaderPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getHeaderEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getHeaderES()

    : null
  )
}

export function footer() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getFooterPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getFooterEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getFooterES()

    : null
  )
}

export function menu() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getMenuPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getMenuEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getMenuES()

    : null
  )
}

export function classe() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getClassesPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getClassesEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getClassesES()

    : null
  )
}

export function LGBTQIA() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getLGBTQIAPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getLGBTQIAEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getLGBTQIAES()

    : null
  )
}

export function ads() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getAdsPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getAdsEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getAdsES()

    : null
  )
}

export function dos() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getDosPT()

    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getDosEN()

    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getDosES()

    : null
  )
}

export function diversity() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getDiversityPT()
    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getDiversityEN()
    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getDiversityES()
    : null
  )
}

export function represent() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getRepresentsPT()
    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getRepresentsEN()
    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getRepresentsES()
    : null
  )
}

export function stereotypes() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getStereotypesPT()
    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getStereotypesEN()
    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getStereotypesES()
    : null
  )
}

export function routine() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getRoutinePT()
    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getRoutineEN()
    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getRoutineES()
    : null
  )
}

export function practices() {
  return (
    process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
      getPracticesPT()
    : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
      getPracticesEN()
    : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
      getPracticesES()
    : null
  )
}