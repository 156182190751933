import styled from 'styled-components'
import { colors } from '../../../globalStyles';

export const Grid = styled.div`
  display: grid;
  gap: clamp(0.75rem, -0.25rem + 1.25vw, 1.25rem); // 14 - 20 > 1280 1920
  grid-template-columns: repeat(2, 1fr);
`
export const Item = styled.div`
  background-color: ${props => props.color ? props.color : colors.white};
  padding: clamp(1.25rem, 0.75rem + 0.625vw, 1.5rem) clamp(1.375rem, 0.125rem + 1.5625vw, 2rem); // 20 - 24 | 22 - 32 > 1280 1920
  border-radius: 12px;
  grid-column: span ${props => props.span ? props.span : 1};
  @media screen and (max-width: 1000px) {
    grid-column: span 2;
  }
`
export const Title = styled.h3`
  color: ${colors.white};
  font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18 > 1280 1920
  margin: 0 0 clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem); // 10 - 20 > 1280 1920
`
export const Text = styled.p`
  color: ${colors.white};
  font-size: clamp(0.75rem, 0.5rem + 0.3125vw, 0.875rem); // 12 - 14 > 1280 1920
  margin: 0 0 clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem); // 10 - 20 > 1280 1920
  span {
    color: ${colors.green};
    font-weight: 600;
  }
`