import React, { useState } from 'react'
import * as S from './styled'
import { classe } from '../../../../json'

function Classes() {
  const [active, setActive] = useState(0)
  const [tooltip, setTooltip] = useState(true)
  const data = classe()

  const onClickDot = (id) => {
    setActive(id)
    setTooltip(false)
  }

  return(
    <S.Carousel>
      <S.Slider className='slider'>
        {data.map((item, index) => (
          <S.Item className={`${active === index ? 'active' : ''}`}>
            <section key={index}>
              <h2>{item.title}</h2>
              {item.text.map((paragraph, index) => (
                <div dangerouslySetInnerHTML={{__html: paragraph}} key={index} />
              ))}
            </section>
            <figure>
              <img src={item.img} alt={item.title} />
            </figure>
          </S.Item>
        ))}
      </S.Slider>
      <S.Dots className='dots'>
        {data.map((item, index) => (
          <S.Dot 
            onClick={() => onClickDot(index)}
            className={`${active === index ? 'active' : ''}`}
            color={data.color}
          >
            {index + 1}
          </S.Dot>
        ))}
        <S.Tooltip color={data.color} style={{opacity: tooltip ? '1' : '0'}}>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <span>Clique para navegar entre conteúdos</span>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <span>Click to browse content</span>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <span>Haga clic para explorar el contenido</span>
          : null}
        </S.Tooltip>
      </S.Dots>
    </S.Carousel>
  )
}
export default Classes