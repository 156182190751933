let intro = {
  logo: `${process.env.PUBLIC_URL}/assets/logo-white_en.svg`,
  button: 'click to go foward',
  nav: 'OK! I am aware.',
  title: `First of all, let’s make some agreements! <br/> 3 premises that no one can forget.`,
  list: [{
    title: "The fight for diverse advertising and an inclusive work environment starts with you.",
    text: " Be open to learning: listen, read and think twice, three, or four times before judging a reality you don’t know or don’t experience in your body.",
  },{
    title: "Transforming knowledge into actions is everyone’s task. No one changes a company, a market, or the world alone.",
    text: "Dialog is a basic need. Value the knowledge of each team member, and think together about how to promote egalitarian and fair spaces.",
  },{
    title: "Learning is a constant",
    text: " You won’t become an expert on diversity and inclusion with this Guide, but you are taking an important step. Don’t stop here! We encourage you to actively seek more information, data and research.",
  }]
}
export function getIntroEN() {
  return intro
}