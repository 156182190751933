let header = {
  download: 'baixe o guia',
  download_link: `${process.env.PUBLIC_URL}/assets/guia_da_diversidade.pdf`,
  menu: 'escolher trilha',
  dropdown: [{
    name: 'Diversidade',
    slug: 'diversidade'
  },{
    name: 'Representatividade',
    slug: 'representatividade'
  },{
    name: 'Esteriótipos',
    slug: 'esteriotipos'
  },{
    name: 'Rotina',
    slug: 'rotina'
  },{
    name: 'Boas Práticas',
    slug: 'boas-praticas'
  }],
  nav: [{
    name: 'Home',
    slug: 'trilhas',
    type: '#'
  },{
    name: 'Diversidade',
    slug: 'diversidade',
    type: '#page'
  },{
    name: 'Representatividade',
    slug: 'representatividade',
    type: '#page'
  },{
    name: 'Esteriótipos',
    slug: 'esteriotipos',
    type: '#page'
  },{
    name: 'Rotina',
    slug: 'rotina',
    type: '#page'
  },{
    name: 'Boas Práticas',
    slug: 'boas-praticas',
    type: '#page'
  },{
    name: "Do's and dont's",
    slug: 'dos-and-donts',
    type: '#'
  }]
}

let footer = {
  text: 'Produzido pelo Núcleo de Diversidade BlacKO e SILVA',
  copyright: 'Copyright © 2022 Guia de Diversidade',
}

export function getHeaderPT() {
  return header
}

export function getFooterPT() {
  return footer
}