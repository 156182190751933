import styled from 'styled-components'
import { colors } from '../../../globalStyles';

export const Carousel = styled.div`
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`
export const Slider = styled.ul`
  margin: 0 0 clamp(2rem, -0.5rem + 3.125vw, 3.25rem); // 32 - 52 > 1280 - 1920
`
export const Item = styled.li`
  background-color: ${props => props.bg ? props.bg : 'transparent'};
  display: none;
  padding: ${props => props.bg ? '42px' : 0 } ${props => props.bg ? '56px' : 0 };
  border-radius: 8px;
  &.active {
    display: block;
  }
`
export const Graph = styled.img`
  display: block;
  margin: 0 auto clamp(1.875rem, -1.875rem + 4.6875vw, 3.75rem); // 30 - 60 > 1280 - 1920
  max-width: 100%;
  @media screen and (max-width: 1300px) {
    max-width: 100%;
    max-height: 240px;
  }
`
export const Title = styled.h4`
  font-size: clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem); // 18 - 24 > 1280 - 1920
  margin: 0 0 20px;
  font-family: 'TCCC-UnityHeadline-Bold';
`
export const Text = styled.p`
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16 > 1280 - 1920
  line-height: 1.6;
  margin: 0 0 10px;
  max-width: 600px;
  span {
    font-size: 14px;
    font-family: 'TCCC-UnityHeadline-Bold';
    font-weight: 700;
  }
`
export const Dots = styled.nav`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
`
export const Tooltip = styled.p`
  font-size: clamp(0.625rem, 0.125rem + 0.625vw, 0.875rem); // 10 - 14 > 1280 - 1920
  font-family: TCCC-UnityText-Medium;
  background-color: ${props => props.color ? props.color : colors.black};
  color: ${colors.white};
  padding: 7px 12px;
  border-radius: 8px;
  position: relative;
  margin-left: 20px;
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    left: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background-color: ${props => props.color ? props.color : colors.black};
  }
  @media screen and (max-width: 700px) {
    width: 50%;
  }
`
export const Dot = styled.button`
  font-family: 'TCCC-UnityHeadline-Black';
  background-color: ${colors.black};
  color: ${colors.white};
  width: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  height: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  font-size: clamp(0.75rem, 0.25rem + 0.625vw, 1rem); // 12 - 16 > 1280 - 1920
  font-weight: 800;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  &.active {
    background-color: ${props => props.color ? props.color : colors.green};
  }
`