let classes = [
  {
    title: "Igualdade",
    text: [
      '<p><b>É quando tratamos todas as pessoas da mesma forma.</b> Como se numa corrida fosse justo deixar que pessoas que enfrentam mais obstáculos que outras partam do mesmo ponto de largada</p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_01.jpg`
  },
  {
    title: "Desigualdade",
    text: [
      '<p>É quando as pessoas não têm acesso às mesmas oportunidades.</p>',
      '<p>Enquanto classes sociais e econômicas mais favorecidas têm acesso a boas escolas, boas faculdades e, consequentemente, a bons empregos; <b>outros, em função da falta de acesso, sequer conseguem participar da corrida por boas oportunidades.</b></p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_03.jpg`
  },
  {
    title: "Equidade",
    text: [
      '<p><b>É quando tratamos as pessoas diferentes de acordo com as suas diferenças.</b> É preciso garantir que numa corrida pessoas que enfrentam mais e menos obstáculos partam de pontos de largada distintos, mas é preciso também lutar para que esses obstáculos sejam destruídos.</p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_02.png`
  },

]
export function getClassesPT() {
  return classes
}