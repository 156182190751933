import React from 'react'
import * as S from './styled'

function Infos({ current, type, color }) {
  return (
    <S.Info className={ type } color={ color }>
      {current?.hero?.image && <img src={current.hero.image} alt={current.name} /> }
      <h2 dangerouslySetInnerHTML={{__html: current?.hero?.name}} />
      {current?.hero?.subtitle && <h3>{current?.hero?.subtitle}</h3>}
      {current?.hero?.description.map((paragraph, index) =>
        <p key={index} dangerouslySetInnerHTML={{__html: paragraph}} />
      )}
    </S.Info>
  )
}
export default Infos