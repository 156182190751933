import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  opacity: ${props => props.perfil ? '1' : '.4'};
  background-color: ${props => props.bg ? props.bg : colors.white};
  svg {
    width: 100%;
    position: relative;
    .st0, .st2, .st3, .st4, .st5 { fill:none; stroke-width:25; stroke-miterlimit:10; }
    .st0 {  stroke: ${props => props.color ? props.color : colors.yellow}; }
	  .st1 {  fill: ${props => props.color ? props.color  : colors.yellow}; }
	  .st2 {  stroke: ${props => props.color ? props.color : props.bg === '#E54A5A' ? colors.yellow : colors.red}; }
	  .st3 {  stroke: ${props => props.color ? props.color : colors.blue};  }
	  .st4 {  stroke: ${props => props.color ? props.color : colors.turquoise};  }
	  .st5 {  stroke: ${props => props.color ? props.color : colors.green};  }
  }
`
export const Texture = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: contain;
  background-position: top center;  
`