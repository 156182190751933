import React, { useState } from 'react'
import * as S from './styled'
import { ads } from '../../../json/'

function Box() {
  const [tab, setTab] = useState(0)
  const data = ads()
  const onChangeTab = (index) => {
    setTab(index)
  }
  return (
    <S.Box>
      <nav>
        {data.map((item, index) => (
          <S.Title
            key={index}
            onClick={() => onChangeTab(index)}
            style={{borderBottom: tab === index ? '1px solid #000' : 'none', opacity: tab === index ? '1' : '.4'}}
          >
            {item.title}
          </S.Title>
        ))}
      </nav>
      {data.map((item, index) => (
        <S.Card
          key={index}
          color={item.color}
          style={{display: tab === index ? 'block' : 'none'}}
        >
          <S.Text dangerouslySetInnerHTML={{__html: item.text}} />
        </S.Card>
      ))}
    </S.Box>
  )
}
export default Box