import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'

function Prev({ current, color }) {
  return (
    <S.Arrow>
      <Link smooth to={`/${current.prev.slug}`}>
        <S.NavArrow color={ color }>
          <svg width="8" height="11" viewBox="0 0 8 11">
            <path 
              d="M7.69471 10.7813C7.79144 10.7114 7.86823 10.6283 7.92062 10.5366C7.97302 10.445 8 10.3467 8 10.2474C8 10.1482 7.97302 10.0499 7.92062 9.95823C7.86823 9.8666 7.79144 9.78343 7.69471 9.71352L2.96767 6.26947C2.87093 6.19956 2.79415 6.11639 2.74175 6.02476C2.68935 5.93312 2.66238 5.83483 2.66238 5.73556C2.66238 5.63629 2.68935 5.53801 2.74175 5.44637C2.79415 5.35474 2.87093 5.27157 2.96767 5.20166L7.69471 1.75761C7.79144 1.6877 7.86823 1.60453 7.92062 1.5129C7.97302 1.42126 8 1.32297 8 1.2237C8 1.12443 7.97302 1.02615 7.92062 0.93451C7.86823 0.842875 7.79144 0.759706 7.69471 0.6898C7.50133 0.549743 7.23974 0.47113 6.96707 0.47113C6.69441 0.47113 6.43282 0.549743 6.23944 0.6898L1.50209 4.14137C0.922247 4.56436 0.596558 5.13774 0.596558 5.73556C0.596558 6.33339 0.922247 6.90677 1.50208 7.32976L6.23944 10.7813C6.43282 10.9214 6.69441 11 6.96707 11C7.23974 11 7.50133 10.9214 7.69471 10.7813Z"
              fill={current.color ? current.color : '#fff'}
            />
          </svg>
        </S.NavArrow>
        <S.NavText color={ color }>{current.prev.name}</S.NavText>
      </Link>
    </S.Arrow>
  )
}
export default Prev