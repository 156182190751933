let menu = {
  logo: `${process.env.PUBLIC_URL}/assets/logo.svg`,
  column1: [
    '<b>Entender que pessoas e grupos são únicos e devem ser respeitados é o primeiro passo para compreender a diversidade humana.</b>',
    'A nossa jornada busca a valorização das diferenças no que diz respeito à gênero, raça, classe, sexualidade e corpos. Principalmente no ambiente de trabalho. Até porque, uma companhia que prioriza ter o seu time diverso só tem a ganhar — literalmente.',
    'Segundo pesquisas da McKinsey, empresas que investem em diversidade têm mais chances de obter melhor desempenho financeiro.'
  ],
  column2: [
    'A inclusão é um dever de cada um de nós, por um mercado profissional mais plural e uma sociedade mais justa.',
    'O material reunido aqui é um ponto de partida. <b>Em cinco trilhas de aprendizagem</b>, você vai encontrar dados, dicas, pesquisas, números e argumentos que vão te ajudar a desconstruir estereótipos comuns no mercado publicitário.',
    'Faça disso uma missão e siga pesquisando para que a evolução seja constante. Mudar é possível e isso começa agora.'
  ],
  nav: [{
    name: 'Diversidade',
    slug: 'diversidade',
    color: '#F0AB1B'
  },{
    name: 'Representatividade',
    slug: 'representatividade',
    color: '#EA6833'
  },{
    name: 'Esteriótipos',
    slug: 'esteriotipos',
    color: '#94C15B'
  },{
    name: 'Rotina',
    slug: 'rotina',
    color: '#3594C9'
  },{
    name: 'Boas Práticas',
    slug: 'boas-praticas',
    color: '#4CBF85'
  }],
}
export function getMenuPT() {
  return menu
}