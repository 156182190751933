import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'
import { header } from '../../json'

const Header = ({ modal, currentPage, intro }) => {
  const [openLang, setOpenLang] = useState(false)
  const [openTrail, setOpenTrail] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const data = header()

  const pt = process.env.REACT_APP_CURRENT_LANG_PT
  const en = process.env.REACT_APP_CURRENT_LANG_EN
  const es = process.env.REACT_APP_CURRENT_LANG_ES
  const currentLang = process.env.REACT_APP_CURRENT_LANG

  const path = window.location.pathname

  const onOpenDropdown = () => {
    setOpenLang(!openLang)
  }

  const onOpenModal = () => {
    setOpenModal(!openModal)
  }

  const onOpenTrail = () => {
    setOpenTrail(!openTrail)
  }

  return (
    <S.Menu data-modal={modal} intro={intro}>
      <img
        className="logo"
        alt="The coca cola company"
        src={modal ? `${process.env.PUBLIC_URL}/assets/logo-coca-white-small.svg` : `${process.env.PUBLIC_URL}/assets/logo-coca.svg`}
      />
      
      <S.MenuNav>
        <Link smooth to={'/trilhas/#'}>home</Link>
        <div>
          <button onClick={onOpenDropdown}>
            <span>{currentLang === 'pt' ? 'PT-BR' : currentLang} </span>
            <img
              src={modal ? `${process.env.PUBLIC_URL}/assets/arrow-white.svg` : `${process.env.PUBLIC_URL}/assets/arrow.svg`}
              alt="Arrow down"
            />
          </button>
          <ul open={openLang}>
            <li><a href={`${pt}${path}`}>PT-BR</a></li>
            <li><a href={`${en}${path}`}>EN</a></li>
            <li><a href={`${es}${path}`}>ES</a></li>
          </ul>
        </div>
      </S.MenuNav>

      <S.MenuButtons className='menu-button'>
        {currentLang === 'pt' && <Link className="guide" to={data.download_link} target="_blank">{data.download}</Link>}

        <div className='menu-dropdown'>
          <button onClick={onOpenTrail}>{data.menu}</button>
          <nav open={openTrail} onClick={onOpenTrail}>
            {data?.dropdown?.map((item, index) => (
              <Link
                key={index}
                smooth 
                to={`/${item.slug}/#page`}
                data-active={currentPage === item.slug}
              >{item.name}</Link>
            ))}
          </nav>
        </div>

        <Link to={'/dos-and-donts/#'}>do's and dont's</Link>
      </S.MenuButtons>

      <S.ModalButton onClick={onOpenModal} open={openModal}>
        <span></span>
      </S.ModalButton>

      <S.Modal open={openModal} onClick={onOpenModal} data-modal={modal}>
        <nav>
          {data?.nav?.map((item, index) => {
            const slug = item.slug === 'trilhas' ? '' : item.slug
            return(
              <Link
                key={index}
                smooth 
                to={`/${slug}/${item.type}`}
                data-active={currentPage === slug}
              >{item.name}</Link>
            )
          })}
          {currentLang === 'pt' && <Link to={data.download_link} target="_blank">{data.download}</Link>}
          <ul>
            <li open={currentLang === 'pt'}><a href={`${pt}${path}`}>PT-BR</a></li>
            <li open={currentLang === 'en'}><a href={`${en}${path}`}>EN</a></li>
            <li open={currentLang === 'es'}><a href={`${es}${path}`}>ES</a>ES</li>
          </ul>
        </nav>
      </S.Modal>
    </S.Menu>     
  )
}
export default Header