import styled from 'styled-components'
import { colors } from '../../globalStyles'

export const Row = styled.div`
  @media screen and (max-width: 1300px) {
    padding: 0;
  }
`
export const List = styled.ul`
`
export const Item = styled.li`
  .percent {
    font-size: clamp(4.125rem, 0.375rem + 4.6875vw, 6rem); // 66 - 96 > 1280 - 1920
    line-height: 1;
    font-family: 'TCCC-UnityCondensed-Bold';
    margin: 0 30px 0 0;
  }
  .box {
    background-color: ${props => props.color ? props.color : colors.black};
    color: ${colors.white};
    padding: clamp(1rem, -1rem + 2.5vw, 2rem) clamp(1.4375rem, -1.4375rem + 3.5937vw, 2.875rem); // 16 - 32 | 23 - 46 > 1280 - 1920

    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: clamp(0.625rem, 0.5rem + 0.625vw, 1.25rem) 0; // 10 - 20
    .black {
      color: ${colors.black};
      span {
        color: ${colors.white};
      }
    }
    &.center {
      margin: 60px 0;
      justify-content: center;
      color: ${colors.black};
      p {
        text-align: center;
      }
    }
    p {
      max-width: 100%;
      font-size: clamp(1.125rem, -0.125rem + 1.5625vw, 1.75rem); // 18 - 28 > 1280 - 1920
      line-height: 1.3;
      font-family: 'TCCC-UnityText-Bold';
      margin: 0;
      &.legend {
        margin: 10px 0 0;
        font-size: 14px;
        font-family: 'TCCC-UnityText-Regular';
        a {
          text-decoration: underline;
        }
      }
      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
    }
  }
  p {
    font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16 > 1280 - 1920
    line-height: 1.6;
    margin: 0 0 10px;
    max-width: 650px;
  }
`
export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Column = styled.div`
  width: 45%;
`
export const Paragraph = styled.p`
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16 > 1280 - 1920
  margin: 0 0 clamp(0.625rem, 0.5rem + 0.625vw, 1.25rem); // 10 - 20
`
export const Title = styled.h4`
  font-family: TCCC-UnityText-Bold;
  font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18
  margin: 0 0 20px;
`
export const Text = styled.div`
  margin: 0 0 24px;
  p {
    font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16
    line-height: 1.4;
  }
`
export const TableTitle = styled.h5`
  font-family: TCCC-UnityText-Bold;
  font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18
  text-transform: uppercase;
  margin: 0 0 clamp(1.125rem, -1.125rem + 2.8125vw, 2.25rem); // 18 - 36
`
export const Table = styled.div`
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16
  margin: 0 0 clamp(0.9375rem, -0.9375rem + 2.3438vw, 1.875rem); // 15 - 30
  ul {
    display: flex;
    justify-content: space-between;
    border: 1px solid ${colors.black};
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
      padding: 0;
    }
    &.title {
      li:first-of-type {
        width: 130px;
        border-right: 1px solid ${colors.black};
        @media screen and (max-width: 700px) {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid ${colors.black};
        }
      }
      &:first-of-type {
        border-bottom: none;
      }
    }
    li {
      font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16
      padding: clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem); // 18 - 24
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 700px) {
        padding: clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem) 10px
        width: 32%;
        text-align: center;
      }
      .simbol {
        display: block;
        width: 100%;
        margin: 0 0 10px;
      }
      .table-title {
        font-family: TCCC-UnityText-Bold;
        font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18
      }
      .table-percent {
        margin: 0 0 5px;
        font-size: clamp(2rem, 0rem + 2.5vw, 3rem); // 32 - 48
        font-family: TCCC-UnityCondensed-Bold;
      }
      .table-country {
        font-size: clamp(0.875rem, 0.375rem + 0.625vw, 1.125rem); // 14 - 18
        font-family: TCCC-UnityText-Medium;
      }
      .table-flag {
        font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18
      }
    }
  }
`
export const Legend = styled.p`
  font-weight: bold;
  font-size: clamp(0.75rem, 0.5rem + 0.3125vw, 0.875rem); // 12 - 14
`