let dos = {
  modal: `
    <h1>Checklist</h1>
    <div>
      <p>The fight for diversity demands daily work.</p>
      <p>The checklist is your ally on this journey towards innovative, inclusive, and plural advertising. During your campaign's production, check where you are at towards a fairer world.</p>
    </div>
  `,
  modal_btn: 'Know more',
  list: [{
    title: `<h2><img src="${process.env.PUBLIC_URL}/assets/check.svg" alt="do's" /> <span>Do's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>For more plural campaigns!</h3>
        <p>Build castings that represent the reality of the Brazilian population <b>(IBGE - PNAD, 2012-2019): 42.7% white people, 46.8% “pardos”, 9.4% black people, and 1.1% as Asian or indigenous people.</b></p>

        <h3>Care about your well-being from the casting!</h3>
        <p>Hire a production company with people who understand the message that the casting conveys and know how to deal with it. Adequate transport, food, and beauty production are not secondary aspects. The casting needs to be welcomed in its entirety: from basic needs to interpersonal treatment respectful.</p>
        
        <h3>Practice what you stand for!</h3>
        <p>The team behind the cameras must seem like the diversity that is being represented in front of the cameras.</p>
        <p>Prioritize hiring producers who think this way and consider it in your next team signings.</p>
      </div>

      <div>
        <h3>Understand the reality of the other!</h3>
        <p>Negotiate more suitable payment terms to the reality of the contracted producer.</p>
        <p>Minor/smaller companies, as well as low-income people, are more sensitive to long payment terms.</p>
        
        <h3>The campaign doesn't end after it went on the air!</h3>
        <p>Prepare the SAC and customer service team to deal with future discomforts. Remember that nothing pleases everyone and we must always be prepared to best deal with negative comments.</p>
      </div>
    `
  }, {
    title: ` <h2><img src="${process.env.PUBLIC_URL}/assets/x.svg" alt="dont's" /> <span>Dont's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>Don't run away from responsibility!</h3>
        <p>Do not use the fact that you are not part of certain social groups to exempt yourself from the responsibility to look for healthier representations.</p>
        <p>It is important that everyone knows how to deal with plurality and understand that the place of speech is not about silencing anyone, but about making room so that diverse voices are heard and taken seriously.</p>
        
        <h3>Do not delegate tasks to only one person!</h3>
        <p>Do not centralize decisions related to diversity, whether structural or by design, in just one single person. Distribute tasks equally among your team. </p>
        
        <h3>Nobody is a token of diversity!</h3>
        <p>Don't use a person on your team as a token of diversity. If you look at your team and just a person or two represent diversity, your team is not diverse.</p>
      </div>
      <div>
        <h3>Don't ignore your team!</h3>
        <p>Do not ignore team guidelines specialists, no matter how much it influences the production deadlines. tight deadline can't be an excuse for a campaign to reproduce negative or misguided stereotypes. review the strategic and production planning during the process can ensure that the campaign does not reverberate negatively in the future</p>

        <h3>Don't forget the real purpose!</h3>
        <p>Don't make campaigns just for opportunism. Before talking about a cause, study which are the main demands and issues of a given group and understand how your project can collaborate with them. Be really engaged with the cause and take your actions beyond the commemorative date on which that group is usually represented.</p>
        <p>The connection only becomes real if it is daily.</p>
      </div>
    `
  }, {
    title: `<h2>Don't drop the ball!</h2>`,
    class: 'line',
    box: `
      <div>
        <h3>Understand the power of verbal and non-verbal language!</h3>
        <p>Language can do many things. It can be easily comprehended, it can confuse, it can cause something good, and also harm. Therefore, it is important to pay attention to the symbols and images used, as well as the words and their intonation. Always ask yourself what to talk about, how to talk, when to talk, and where to talk helps prevent negative interpretations and misconceptions with messages about diversity and inclusion.</p>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist01.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> The family doesn't represent the Latin-American reality</figcaption>
        </figure>
        <h3 style="color:#94C15B">Do</h3>
        <p>Be aware of the world! By depicting encounters between friends, family and even strangers remember that people of different ethnic and national origins live in Latin America.</p>
      </div>
    `
  }, {
    title: `<h2>Don't drop the ball!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist02.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> Subordination from black man: while white people have fun, he drives the car.</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>The way we are portrayed matters! Diversity is not just about who is on stage, it's also about who does what. Nobody wants to see black people in positions of servile or standing men leading women who, seated, sound like passive agents</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>By real image banks! The repository of photographs must be carefully composed and frequently re-evaluated. Think about the new family models. Cherish the ties between LGBTQIA+ people. Make sure, for example, that the motherhood of women black is made visible!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>Don't drop the ball!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist03.png" alt='imagem' />
          <figcaption class='legend'><span class='green'>√</span> Men take care, cook, and are affectionate.</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>The places say things about people! Do not represent women only in the space of home and family. When you do that, again and again, it denies their individuality</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Women are everywhere! They work, have fun, meet friends, travel, lead companies… Think big!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>Don't drop the ball!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist04.png" alt='imagem' />
          <figcaption class='legend'>
            <b class='black tooltip-container'>White woman<span class='tooltip-text bg-black'>It is important not to confuse the wearing of turbans by white Western women with the different coverings worn by Muslim women. Always consider the sociocultural context.</span></b> wearing turban and black man without protagonism and not even face
          </figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>Be careful with appropriation! Do not represent white people wearing clothing, tattoos or other items that are symbols of native peoples, “quilombolas”, black and Asian people origins, among others.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Know the public debate! You avoid cultural appropriation by hiring experts, studying, or even googling.</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: ``,
    class: 'text',
    box: `
      <div>
        <h3>On the five trails of knowledge you have been through, you had contact with arguments, data, and surveys that allow you to know what and how to do.</h3>
        <p>Whenever you have any questions, you can go back to the material gathered in this Guide or even go beyond it. The key is not to accommodate because the world is changing. And our commitment is to make this world a fairer place, where everyone can have access to opportunities and life quality. It's just not worth pushing the fight against race inequality, class, and gender to the other. The fight for inclusive advertising, and genuinely concerned with diversity, is a task of each one of us.</p>
        <p>We deconstruct stereotypes in advertising escaping from the commonplace! And together, with this Guide, we took the first steps: we learned to observe reality as it is, not the from personal assumptions.</p>
      </div>

      <div>
        <p>Tell stories that truly come close to our consumers is what motivates us. But that only happens when we strive, as a company and as individuals, to listen to demands, understand expectations, and structure actions responsibly.</p>
        <p>If you've come this far, you know that our only and true commitment is to the real Latin America. People of flesh and blood, with bodies and plural desires matter; and no one can be excluded.</p>
        <p>Inclusion in work environments only happens when every day you wonder what you can do with the tools at your disposal.</p>
        <p>Now that you know some of the main perceptions and expectations of Latin Americans, Make decisions, make it happen!</p>
        <h3>The goal is to have diversity not only on screens but also in teams. The inclusion must be strategic practice daily, the pillar of your actions.</h3>
      </div>
    `
  }]
}
export function getDosEN() {
  return dos
}