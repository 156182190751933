import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.footer`
  color: ${colors.white};
  background-color: ${colors.red};
  display: flex;
  justify-content: space-between;
  // padding: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem) clamp(1.25rem, 0.25rem + 5vw, 6.25rem); // 12 - 24
  padding: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem)  clamp(1.25rem, 0.5357rem + 3.5714vw, 3.75rem); // 12 - 24 | 20 - 60
  width: 100%;
  position: ${props => props.intro ? 'fixed' : 'relative'};
  bottom: 0;
  z-index: 1;
  gap: clamp(0.3125rem, 0.25rem + 0.3125vw, 0.625rem); // 5 - 10
  p {
    font-size: clamp(0.625rem, 0.575rem + 0.25vw, 0.875rem); // 10 - 14
    font-weight: 700;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
  }
`;
