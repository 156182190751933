import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 90px 1fr 90px;
  padding: 0 clamp(1.25rem,0.5357rem + 3.5714vw,3.75rem);
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

export const Nav = styled.nav`
  grid-column: 2;
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 16px;
  margin: 0 auto 30px;
  color: #666;
  font-size: 14px;
  a {
    text-decoration: underline;
  }
  >div {
    display: flex;
    gap: 16px;
  }
  @media screen and (max-width: 1000px) {
    grid-column: 1;
  }
`