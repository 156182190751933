let intro = {
  logo: `${process.env.PUBLIC_URL}/assets/logo-white_es.svg`,
  button: 'hacer click para avanzar',
  nav: 'De acuerdo, soy consciente',
  title: `En primer lugar, ¡hagamos algunos acuerdos! <br/> 3 premisas que nadie puede olvidar.`,
  list: [{
    title: "La lucha por una publicidad diversa y un entorno de trabajo inclusivo empieza  por ti.",
    text: "Esté abierto a aprender: escucha, lee y piensa dos, tres, cuatro veces antes de juzgar una realidad que no conoces o que no vives en tu cuerpo.",
  },{
    title: "Transformar el conocimiento en acciones es una tarea de todos. Nadie cambia  una empresa, un mercado o un mundo solo.",
    text: "El diálogo es una necesidad básica. Valora los conocimientos de cada miembro de su  equipo, piensen juntos en como promover espacios equitativos y justos.",
  },{
    title: "El aprendizaje es una constante.",
    text: "No te convertirás en un experto en diversidad e inclusión con este Manual, pero estás dando un paso importante. ¡No te detengas aquí! Te animamos a que busques activamente más informaciones, datos e investigaciones.",
  }]
}
export function getIntroES() {
  return intro
}