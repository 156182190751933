import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Modal = styled.section`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  >p {
    color: ${colors.white};
    background-color: ${colors.blue};
    padding: 20px 40px;
    width: fit-content;
  }
`
export const Container = styled.section`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
  margin: 0 0 40px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  &[open] {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  &.list {
    position: relative;
    h1 {
      display: none;
    }
  }
  .logo-coca {
    padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      width: clamp(8.625rem, 5.7375rem + 14.4375vw, 23.0625rem); // 138 - 369
    }
  }
  h1 {
    text-align: center;
    margin: clamp(3.75rem, 3rem + 3.75vw, 7.5rem) 0; // 60 - 120
    padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    width: 100;
    img {
      display: block;
      max-width: 100%;      
      margin: 0 auto;
      width: clamp(14.625rem, 8.2125rem + 32.0625vw, 46.6875rem); // 234 - 747
    }
  }
  h2 {
    max-width: 700px;
    text-align: center;
    color: ${colors.white};
    font-size: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem); // 14 - 18
    font-weight: 700;
    font-family: 'TCCC-UnityHeadline-Bold';
  }
  ul {
    margin: 28px 0;
    display: flex;
    gap: 24px;
    justify-content: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
    li {
      background-color: ${colors.white};
      padding: clamp(1.5rem, 1.3rem + 1vw, 2.5rem) 24px; // 24 - 40
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 284px;
      @media screen and (max-width: 1000px) {
        max-width: 220px;
        &[data-aos^=fade][data-aos^=fade] {
          opacity: 1;
        }
        &[data-aos=fade-up] {
          transform: translate3d(0,0,0);
        }
      }
      @media screen and (max-width: 700px) {
        max-width: 284px;
      }
      h3 {
        color: ${colors.red};
        font-size: clamp(3rem, 2.35rem + 3.25vw, 6.25rem); // 48 - 100
        line-height: 1;
        font-weight: 800;
        margin: 0 0 clamp(1.25rem, 1rem + 1.25vw, 2.5rem); // 20 - 40
        font-family: 'TCCC-UnityHeadline-Black';
      }
      p {
        font-size: 14px;
        margin: 0 0 clamp(0.625rem, 0.5rem + 0.625vw, 1.25rem);
        b {
          font-weight: 700;
          font-family: 'TCCC-UnityHeadline-Bold';
        }
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
  button {
    display: block;
    img {
      display: block;
      width: clamp(1.125rem, 1.05rem + 0.375vw, 1.5rem); // 18 - 24
      margin: 0 auto;
    }
    p {
      color: #fff;
      font-weight: 700;
      margin: clamp(0.5rem, 0.45rem + 0.25vw, 0.75rem) 0 0; // 8 - 12
      font-size: clamp(0.75rem, 0.7rem + 0.25vw, 1rem); // 12 - 16
      font-family: 'TCCC-UnityHeadline-Bold';
    }
  }
  a {
    width: fit-content;
    text-align: center;
    font-size: 14px;
    padding: 14px 25px;
    font-weight: 700;
    font-family: 'TCCC-UnityHeadline-Bold';
    display: inline-block;
    border-radius: 25px;
    border: 1px solid ${colors.black};
    background-color: ${colors.white};
    cursor: pointer
  }
  @media screen and (max-width: 700px) {
    &.list {
      justify-content: flex-start;
      padding: 50px 20px 200px;
      h2 {
        text-align: left;
        max-width: 260px;
        margin: 0;
      }
      h1 {
        display: block;
        margin: 50px 0 40px;
      }
    }
  }
`