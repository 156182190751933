import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Wrapper = styled.section`
  margin: 52px 0 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  }
`
export const Container = styled.div`
  padding: 28px clamp(2rem, 1.6rem + 2vw, 4rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  color: ${colors.black};
  width: 100%;
  max-width: 1400px;
  border: 1px solid ${colors.black};
  border-radius: 12px;
  a {
    display: block;
    padding: 14px 38px;
    margin-left: 25px;
    color: ${colors.white};
    border-radius: 25px;
    font-weight: 700;
    min-width: 268px;
    text-align: center;
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    transition: all .4s ease;
    &:hover {
      color: ${colors.orange};
      background-color: ${colors.white};
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    a {
      margin: 24px 0 0;
    }
  }
`
export const Content = styled.div`
  h2 {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: 18px;
    font-weight: 800;
    margin: 0 0 10px;
  }
  p {
    font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem); // 14 - 16
    font-weight: 600;
    line-height: 1.6;
  }
`