import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.section`
  padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  h1 {
    text-align: center;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      width: clamp(17.5rem, 11.6625rem + 29.1875vw, 46.6875rem); // 280 - 747
    }
  }
`
export const Content = styled.div`
  background-image: radial-gradient(white, transparent);
  margin: clamp(2.5rem, 2rem + 2.5vw, 5rem) auto;
  max-width: 1000px;
  display: flex;
  gap: 18px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`
export const Column = styled.div`
  width: 50%;
  p {
    font-size: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem);
    margin: 0 0 20px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
export const Menu = styled.nav`
  display: flex;
  justify-content: center;
  gap: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem); // 12 - 24
  padding: 20px 0;
  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`
export const MenuItem = styled.div`
  font-family: 'TCCC-UnityHeadline-Bold';
  font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem); // 14 - 16
  font-weight: 700;
  border-radius: 25px;
  width: clamp(12.5rem, 12rem + 2.5vw, 15rem); // 200 - 240
  text-align: center;
  border: 1px solid ${props => props.color ? props.color : "#000"};
  color: ${props => props.color ? props.color : "#000"};
  background-color: ${colors.white};
  transition: all .4s ease;
  a {
    display: block;
    white-space: nowrap;
    padding: 14px clamp(1.25rem, 1.125rem + 0.625vw, 1.875rem); // 20 - 30
  }
  &[data-active=true] {
    background-color: ${props => props.color ? props.color : "#000"};
    color: #fff;
  }
  &:hover {
    color: ${colors.white};
    background-color: ${props => props.color ? props.color : "#000"};
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`