import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Modal = styled.section`
  min-height: 72vh;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-family: 'TCCC-UnityHeadline-Bold';
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  h1 {
    font-size: clamp(2.5rem, 1.8rem + 3.5vw, 6rem); // 40 - 96
  }
  div {
    margin: 20px 0 40px;
    max-width: 650px;
    p {
      font-size: clamp(0.875rem,0.825rem + 0.25vw,1.125rem);
    }
  }
  button {
    width: fit-content;
    text-align: center;
    font-size: 14px;
    padding: 14px 25px;
    font-weight: 700;
    font-family: 'TCCC-UnityHeadline-Bold';
    display: inline-block;
    border-radius: 25px;
    border: 1px solid ${colors.black};
    background-color: ${colors.white};
    color: ${colors.black};
    cursor: pointer;
  }
  &[open] {
    display: flex;
  }
`
export const Container = styled.section`
  margin: clamp(1.5625rem, 1.3125rem + 1.25vw, 2.8125rem) clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  color: ${colors.black};
  display: none;
  &[open] {
    display: block;
  }
`
export const Carousel = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 620px;
  @media screen and (max-width: 1024px) {
    height: auto;
    align-items: flex-start;
  }
  @media screen and (max-width: 700px) {
    margin: 60px 0 0;
  }
`
export const NavArrow = styled.button`
  width: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem); // 40 - 50
  height: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem); // 40 - 50
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.red};
  border-radius: 100%;
  img {
    display: block;
  }
  &.next {
    img {
      transform: scaleX(-1);
    }
  }
  @media screen and (max-width: 700px) {
    position: absolute;
    top: -70px;
    &.next {
      right: 0;
    }
  }
`
export const Item = styled.div`
  display: flex;
  gap: 40px;
  width: 90%;
  justify-content: space-around;
  padding: 0 20px;
  margin: clamp(0rem, -1.25rem + 6.25vw, 6.25rem) 0 0; // 0 - 100
  color: ${colors.black}; 
  h2 {
    width: 30%;
    font-family: 'TCCC-UnityHeadline-Black';
    font-weight: 900;
    font-size: clamp(2rem, 1.2rem + 4vw, 6rem); // 32 - 96
    line-height: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    img {
      width: clamp(2.5rem, 2rem + 2.5vw, 5rem);
    }
    @media screen and (max-width: 1024px) {
      width: 80%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
export const Row = styled.div`
  width: 65%;
  display: flex;
  gap: clamp(1.375rem, 1.1rem + 1.375vw, 2.75rem); // 22 - 44
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 80%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  div {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  h3 {
    font-family: TCCC-UnityHeadline-Bold;
    font-size: 20px;
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem); // 16 - 20
    line-height: 1.4;
    margin: 0 0 10px;
  }
  p {
    font-size: clamp(0.75rem, 0rem + 0.9375vw, 1.125rem); // 12 - 18 > 1280 - 1920
    line-height: 1.6;
    margin: 0 0 20px;
  }
  img {
    display: block;
    width: auto;
    max-height: 320px;
    margin: 0 auto 30px;
    border-radius: 20px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-height: auto;
    }
  }
  &.text {
    width: 90%;
    max-width: 100%;
  }
  &.line {
    p, h3 {
      max-width: 800px;
      margin: 0 auto 20px;
    }
    h3 {
      margin: 0 auto 10px;
    }
    div {
      width: 100%;
    }
    figure {
      display: flex;
      justify-content: center;
      gap: 0 20px;
      margin: 0 0 20px;
      padding: 0;
      img {
        margin: 0;
        max-height: clamp(16.25rem, 3.75rem + 15.625vw, 22.5rem); // 260 - 360 > 1280 - 1920
      }
      .legend {
        width: 150px;
        font-size: 12px;
        margin: 20px 0 0;
        font-family: 'TCCC-UnityHeadline-Bold';
        span {
          color: ${colors.red};
          &.green {
            color: ${colors.green};
          }
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        .legend {
          font-size: 10px;
          width: 100%;
          margin: 5px 0 0;
        }
      }
    }
  }
  .column {
    display: flex;
    gap: 0 clamp(1.375rem, 1.1rem + 1.375vw, 2.75rem); // 22 - 44
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    h3 {
      margin: 0 0 10px;
    }
  }
`