import styled from 'styled-components'
import { colors } from '../../../../globalStyles';

export const Carousel = styled.div`
  width: 100%;
`
export const Slider = styled.ul`
  margin: 0 0 32px;
`
export const Item = styled.li`
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: ${colors.green};
  display: none;
  padding: clamp(1.3125rem, 1.05rem + 1.3125vw, 2.625rem) clamp(1.75rem, 1.4rem + 1.75vw, 3.5rem); // 21 - 42 | 28 - 56
  border-radius: 12px;
  &.active {
    display: flex;
  }
  h2 {
    font-family: 'TCCC-UnityHeadline-Bold';
    margin: 0 0 10px;
    font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem); // 14 - 16
  }
  p {
    font-size: clamp(0.75rem, 0.725rem + 0.125vw, 0.875rem); // 12 - 14
  }
  figure {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    // background-color: green;
  }
  section {
    width: 35%;
    padding: 0 20px 0 0;
  }
  img {
    display: block;
    width: auto;
    height: clamp(22.5rem, 12.5rem + 12.5vw, 27.5rem); // 360 - 440 > 1280 - 1920
  }
  &:nth-child(3n) {
    figure {
      position: relative;
      right: -38px;
      width: 75%;
      @media screen and (max-width: 1440px) {
        right: -31px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    height: auto;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    section {
      width: 100%;
      margin: 0 0 20px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    &:nth-child(2n) {
      figure {
        right: 0;
        width: 100%;
      }
    }
  }
`
export const Dots = styled.nav`
  display: flex;
  align-items: center;
`
export const Tooltip = styled.p`
  font-size: clamp(0.625rem, 0.125rem + 0.625vw, 0.875rem); // 10 - 14 > 1280 - 1920
  font-family: TCCC-UnityText-Medium;
  background-color: ${colors.yellow};
  color: ${colors.blue};
  padding: 7px 12px;
  border-radius: 8px;
  position: relative;
  margin-left: 20px;
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    left: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background-color: ${colors.yellow};
  }
  @media screen and (max-width: 700px) {
    width: 50%;
  }
`
export const Dot = styled.button`
  font-family: 'TCCC-UnityHeadline-Black';
  background-color: ${colors.white};
  color: ${colors.green};
  width: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  height: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  font-size: clamp(0.75rem, 0.25rem + 0.625vw, 1rem); // 12 - 16 > 1280 - 1920
  font-weight: 800;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  &.active {
    color: ${colors.white};
    background-color: ${colors.green};
  }
`