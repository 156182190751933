import styled from 'styled-components'
import { colors } from '../../../globalStyles';

export const Nav = styled.nav`
  display: flex;
  gap: 16px;
  a {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${colors.white};

    // padding: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);  

    // padding: clamp(0.875rem, 0.4107rem + 2.3214vw, 2.5rem); // 14 - 40

    // padding: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem); // 14 - 20

    // padding: clamp(0.625rem, 0.4464rem + 0.8929vw, 1.25rem); // 10 - 20

    // padding: 12px; // 10 - 20
    padding: 14px; // 10 - 20


    width: 20%;
    max-width: 284px;
    border-radius: 12px;
  }
  @media screen and (max-width: 1000px) {
    overflow-y: scroll;
    width: 100%;
    &::-webkit-scrollbar { 
      display: none;
    }
    a {
      width: 284px;
      min-width: 284px;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 80%;
  height: 100%;
  figure {
    margin: 0 0 20px;
    padding: 0;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: orange;
    @media screen and (max-width: 1300px) {
      height: 120px;
    }
  }
`;
export const Graph = styled.img`
  display: block;
  max-width: 100%;
  height: 100%;
  // max-width: 120px;
  margin: 0;
  @media screen and (max-width: 1300px) {
    max-width: 110px;
  }
`;
export const Title = styled.h2`
  font-family: 'TCCC-UnityHeadline-Bold';   
  // font-size: 18px;
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.color ? props.color : colors.black};
  margin: 0 0 16px;
`;
export const Description = styled.div`
`;
export const Text = styled.p`
  // font-size: clamp(0.75rem, 0.725rem + 0.125vw, 0.875rem);
  // font-size: 12px;
  font-size: clamp(0.625rem, 0.6rem + 0.125vw, 0.75rem); // 10 - 12
  font-weight: 400;
  color: ${props => props.color ? props.color : colors.black};
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'TCCC-UnityHeadline-Bold';
  font-size: 14px;
  font-weight: 700;
  color: ${colors.black};
  &[disabled] {
    display: none;
  }
  img {
    display: block;
    margin-left: 10px;
  }
`;