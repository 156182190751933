let dos = {
  modal: `
    <h1>Checklist</h1>
    <div>
      <p>A luta pela diversidade demanda trabalho diário.</p>
      <p>O checklist é o seu aliado nessa caminhada em prol de uma publicidade inovadora, inclusiva e plural. Durante a produção de sua campanha, verifique aqui em que ponto você está na direção de um mundo mais justo.</p>
    </div>
  `,
  modal_btn: 'Saiba mais',
  list: [{
    title: `<h2><img src="${process.env.PUBLIC_URL}/assets/check.svg" alt="do's" /> <span>Do's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>Por campanhas mais plurais!</h3>
        <p>Construa castings que representem a realidade da população brasileira <b>(IBGE - PNAD, 2012-2019): 42,7% brancos, 46,8% pardos, 9,4% como pretos e 1,1% como amarelos ou indígenas.</b></p>
        <h3>Preocupe-se com o bem-estar do casting!</h3>
        <p>Contrate uma produtora que tenha pessoas que entendam a mensagem que o casting passa e saibam lidar com ele. Transporte adequado, alimentação e produção de beleza não são aspectos secundários. O casting precisa ser bem acolhido em sua totalidade: das necessidades básicas ao tratamento interpessoal respeitoso.</p>
        <h3>Pratique o que você representa!</h3>
        <p>O time que está por trás das câmeras deve parecer com a diversidade que está sendo representada na frente das câmeras.</p>
        <p>Priorize a contratação de produtoras que pensem dessa forma e considere isso nas suas próximas contratações de time.</p>
      </div>
      <div>
        <h3>Entenda a realidade do outro!</h3>
        <p>Negocie prazos de pagamentos mais adequados à realidade da produtora contratada.</p>
        <p>Empresas menores, assim como pessoas de baixa renda, são mais sensíveis a longos prazos de pagamentos.</p>
        <h3>A campanha não acaba depois que foi para o ar!</h3>
        <p>Prepare o time de SAC e atendimento para lidar com desconfortos futuros. Lembre-se que nada agrada a todas e todos e devemos sempre estar preparados para lidar da melhor forma com comentários negativos.</p>
      </div>
    `
  }, {
    title: ` <h2><img src="${process.env.PUBLIC_URL}/assets/x.svg" alt="dont's" /> <span>Dont's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>Não fuja da responsabilidade!</h3>
        <p>Não use o fato de não fazer parte de determinados grupos sociais para se isentar da responsabilidade de buscar por representações mais saudáveis.</p>
        <p>É importante que todas e todos saibam lidar com a pluralidade e entendam que lugar de fala não é sobre calar ninguém, e sim sobre abrir espaço para que diversas vozes sejam ouvidas e levadas a sério.</p>
        <h3>Não delegue tarefas a apenas uma pessoa!</h3>
        <p>Não centralize decisões relacionadas à diversidade, sejam estruturais ou por projeto, em apenas uma única pessoa. Distribua as tarefas igualmente entre seu time.</p>
        <h3>Ninguém é token de diversidade! </h3>
        <p>Não use uma pessoa do seu time como token da diversidade. Se você olha para seu time e apenas uma pessoa ou duas representam a diversidade, seu time não é diverso.</p>
      </div>
      <div>
        <h3>Não ignore o seu time!</h3>
        <p>Não ignore as orientações dos times especializados por mais que isso influencie nos prazos de produção. Prazo apertado não pode ser desculpa para que uma campanha reproduza estereótipos negativos ou equivocados. Rever o planejamento estratégico e de produção durante o processo pode garantir que a campanha não reverbere negativamente no futuro.</p>
        <h3>Não esqueça do real propósito!</h3>
        <p>Não faça campanha apenas por oportunismo. Antes de falar de uma causa, estude quais são as principais demandas e questões de um dado grupo e entenda como seu projeto pode colaborar com elas. Esteja realmente engajado com a causa e leve suas ações para além da data comemorativa em que aquele grupo é geralmente representado.</p>
        <p>A conexão só se torna real se for diária!</p>
      </div>
    `
  }, {
    title: `<h2>Não dê mole!</h2>`,
    class: 'line',
    box: `
      <div>
        <h3>Entenda o poder da linguagem verbal e não-verbal!</h3>
        <p>A linguagem pode fazer muitas coisas. Pode ser de fácil compreensão, pode confundir, pode fazer bem e também fazer mal. Portanto, é importante se atentar aos símbolos e imagens usadas, bem como às palavras e sua entonação. Perguntar-se sempre sobre o que falar, como falar, quando falar e onde falar ajuda a evitar interpretações negativas e equívocos com mensagens sobre diversidade e inclusão.</p>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist01.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> Família não representativa da realidade latino-americana</figcaption>
        </figure>
        <h3 style="color:#94C15B">Do</h3>
        <p>Se atente ao mundo! Ao retratar encontros entre amigos, familiares e mesmo desconhecidos lembre que na América Latina vivem pessoas de diferentes origens étnicas e nacionais.</p>
      </div>
    `
  }, {
    title: `<h2>Não dê mole!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist02.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> Subalternização de homem negro: enquanto pessoas brancas se divertem, ele conduz o carro</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>A maneira como somos retratados importa! Diversidade não é somente sobre quem está em cena, é também sobre quem faz o quê. Ninguém quer ver pessoas negras em posições servis ou homens de pé liderando mulheres que, sentadas, soam como agentes passivos.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Por bancos de imagens reais! O repositório de fotografias deve ser cuidadosamente composto e frequentemente reavaliado. Pense nos novos modelos de família. Valorize os laços entre pessoas LGBTQIA+. Garanta, por exemplo, que a maternidade de mulheres negras seja visibilizada!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>Não dê mole!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist03.png" alt='imagem' />
          <figcaption class='legend'><span class='green'>√</span> Homens cuidam, cozinham e são afetivos.</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>Os locais dizem coisas sobre as pessoas! Não represente mulheres apenas no espaço da casa e da família. Quando você faz isso, de novo e de novo, nega a individualidade delas.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Mulheres estão em todos os lugares! Trabalham, se divertem, encontram amigas(os), viajam, lideram empresas… Pense grande!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>Não dê mole!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist04.png" alt='imagem' />
          <figcaption class='legend'>
            <b class='black tooltip-container'>Mulher branca<span class='tooltip-text bg-black'>É importante não confundir o uso de turbantes por mulheres brancas ocidentais com as diferentes coberturas utilizadas por mulheres muçulmanas. Considere sempre o contexto sociocultural.</span></b> busando turbante e homem negro sem protagonismo e nem mesmo rosto
          </figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>Cuidado com a apropriação! Não represente pessoas brancas utilizando vestuário, tatuagens ou outros ítens que sejam símbolos de povos originários, quilombolas e populações negras e de origem asiática, dentre outras.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Conheça o debate público! Você evita a apropriação cultural contratando especialistas, estudando ou até mesmo dando um google.</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: ``,
    class: 'text',
    box: `
      <div>
        <h3>Nas cinco trilhas de conhecimento que percorreu, você teve contato com argumentos, dados e pesquisas que te permitem saber o que e como fazer.</h3>
        <p>Toda vez que você tiver alguma dúvida, pode voltar ao material reunido neste Guia ou mesmo ir além dele. O fundamental é não nos acomodarmos porque o mundo está mundando. E o nosso compromisso é fazer desse mundo um lugar mais justo, onde todos possam ter acesso a oportunidades e qualidade de vida. Só não vale empurrar para o outro o combate à desigualdade de raça, classe e gênero. A luta por uma publicidade inclusiva, genuinamente preocupada com a diversidade, é uma tarefa de cada um de nós.</p>
        <p>A gente desconstrói estereótipos na publicidade fugindo do lugar-comum! E juntos, com este Guia, demos os primeiros passos: aprendemos a observar a realidade do jeito que ela é, e não a partir de suposições pessoais.</p>
      </div> 
      <div>
        <p>Contar histórias que se aproximam verdadeiramente dos nossos consumidores é o que nos motiva. Mas isso somente acontece quando nos esforçamos, como companhia e indivíduos, para ouvirmos demandas, entendermos expectativas e estruturamos ações com responsabilidade.</p>
        <p>Se você chegou até aqui, sabe que o nosso único e verdadeiro compromisso é com a América Latina real. As pessoas de carne e osso, com corpos e desejos plurais, importam; e ninguém pode ficar de fora.</p>
        <p>A inclusão nos ambientes de trabalho só acontece quando todos os dias você se pergunta o que pode fazer com as ferramentas que tem à disposição.</p>
        <p>Agora que já conhece algumas das principais percepções e expectativas dos latino-americanos, tome decisões, faça acontecer!.</p>
        <h3>A meta é ter diversidade não somente nas telas, mas também nos times. A inclusão deve ser prática estratégica diária, o pilar de suas ações</h3>
      </div>
    `
  }]
}
export function getDosPT() {
  return dos
}