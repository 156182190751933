import styled from 'styled-components'
import { colors } from '../../../../globalStyles'

export const Row = styled.div`
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const Item = styled.li`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem) clamp(1rem, -1rem + 2.5vw, 2rem); // 10 - 20 | 16 - 32
  
  &:nth-child(2) {
    flex-direction: row-reverse;
  }

  &:first-of-type {
    >div {
      max-width: 180px;
    }
    ul {
      width: 100%;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1300px) {
    &:first-of-type {
      flex-wrap: wrap;
      h3 {
        width: 30%;
        margin: 0 0 20px;
      }
      ul {
        margin: 0 0 20px;
      }
    }
  }
  &.colorism {
    h3 {
      font-family: TCCC-UnityCondensed-Bold;
      font-size: clamp(3rem, -1.5rem + 5.625vw, 5.25rem); // 48 - 84
      text-transform: uppercase;
      margin-left: 20px;
    }
  }
  &.racism {
    h3 {
      font-size: clamp(2rem, -0.25rem + 2.8125vw, 3.125rem); // 32 - 50
      font-family: TCCC-UnityHeadline-Black;
      margin-right: 40px;
    }
  }
  h3 {
    font-family: TCCC-UnityHeadline-Bold;
    font-size: clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem); // 18 - 24
    color: ${colors.orange};
    line-height: 1.2;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: clamp(0.875rem, -0.375rem + 1.5625vw, 1.5rem); // 14 - 24
    margin: 0 20px;
    li {
      h4 {
        font-family: TCCC-UnityCondensed-Bold;
        font-size: 40px;
        font-size: clamp(1.25rem, -1.25rem + 3.125vw, 2.5rem); // 20 - 40
      }
      p {
        font-family: TCCC-UnityHeadline-Bold;
        text-transform: uppercase;
        font-size: 14px;
        @media screen and (max-width: 1440px) {
          font-size: 10px;
        }
        @media screen and (max-width: 1280px) {
          font-size: clamp(0.75rem, 0.5rem + 0.3125vw, 0.875rem); // 12 - 14
        }
      }
      &:nth-child(1) {
        color: #9B3924;
      }
      &:nth-child(2) {
        color: #BA5232;
      }
      &:nth-child(3) {
        color: #DA6F42;
      }
      &:nth-child(4) {
        color: #E59A6D;
      }
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column !important;
    h3 {
      width: 100% !important;
      margin: 0 0 10px !important;
    }

  }
`
export const Paragraph = styled.p`
  font-size: clamp(0.75rem, 0.5rem + 0.3125vw, 0.875rem); // 12 - 14
  margin: 0 0 20px;
  b {
    font-family: TCCC-UnityHeadline-Bold;
  }
  span {
    color: #F4724A;
  }
  &:last-child {
    margin: 0;
  }
`