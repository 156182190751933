import React from 'react'
import * as S from './styled'

function Grid({ data }) {
  return (
    <S.Grid>
      {data.grid.map((item, index) => (
        <S.Item span={item.span} key={index} color={item.color}>
          <S.Title>{item.title}</S.Title>
          {item.text.map((paragraph, index) => (
            <S.Text dangerouslySetInnerHTML={{__html: paragraph}} key={index} /> 
          ))}
        </S.Item>
      ))}
    </S.Grid>
  )
}
export default Grid