import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Nav = styled.nav`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
    padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  }
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${colors.white};
    // padding: 24px;
    padding: 18px;
    width: ${props => props.size ? props.size + 'vw' : '25vw'};
    max-width: 284px;
    min-width: 200px;
    border-radius: 12px;
  }
  @media screen and (max-width: 1024px) {
    overflow-y: scroll;
    width: 100%;
    &::-webkit-scrollbar { 
      display: none;
    }
    a {
      width: 284px;
      min-width: 284px;
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.position === 'center' ? props.position : 'flex-start'};
  justify-content: ${props => props.position === 'center' ? 'space-between' : 'flex-start'};
  height: ${props => props.position === 'center' ? '70%' : '100%'};
  position: ${props => props.position === 'center' ? 'absolute' : 'static'};
  left: 50%;
  min-width: 80%;
  transform: ${props => props.position === 'center' ? 'translateX(-50%)' : 'translateX(0%)'};
  bottom: 0;
  figure {
    // background-color: pink;
    margin: 0 0 20px;
    padding: 0;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
export const Graph = styled.img`
  display: block;
  margin: 0 auto;
`
export const Title = styled.h2`
  font-family: 'TCCC-UnityHeadline-Bold';   
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 ${props => props.position === 'center' ? '24px' : '16px'};
  text-align: ${props => props.position === 'center' ? props.position : 'left'};
  color: ${props => props.color ? props.color : colors.black};
`
export const Description = styled.div`
`
export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 ${props => props.position === 'center' ? '24px' : '20px'};
  color: ${props => props.color ? props.color : colors.black};
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'TCCC-UnityHeadline-Bold';
  font-size: 14px;
  font-weight: 700;
  color: ${colors.black};
  &[disabled] {
    display: none;
  }
  img {
    display: block;
    margin-left: 10px;
  }
`