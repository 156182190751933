import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'

function Hero({ currentPage, data }) {
  return (
    <S.Container>
      <h1>
        {/* <img src={`${process.env.PUBLIC_URL}/assets/logo.svg`} alt="Guia da diversidade" /> */}
        <img src={data.logo} alt="Guia da diversidade" />
      </h1>
      <S.Content>
        <S.Column>
          {data.column1.map((column, index) => <p dangerouslySetInnerHTML={{__html: column}} key={index} /> )}
        </S.Column>
        <S.Column>
          {data.column2.map((column, index) => <p dangerouslySetInnerHTML={{__html: column}} key={index} /> )}
        </S.Column>
      </S.Content>
      <S.Menu id="page">
        {data.nav.map((item, index) => (
          <S.MenuItem key={index} color={item.color} data-active={currentPage === item.slug}>
            <Link smooth to={`/${item.slug}/#page`}>{item.name}</Link>
          </S.MenuItem>
        ))}
      </S.Menu>
    </S.Container>
  )
}
export default Hero