import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Info = styled.div`
  &.hero-perfil {
    width: 60%;
    max-width: 100%;
    p {
      font-size: clamp(0.875rem, 0.125rem + 0.9375vw, 1.25rem); // 14 - 20 > 1280 - 1920
      line-height: 1.6;
      max-width: 860px;
    }
  }
  &.hero-lgbtqia {
    h2 {
      color: ${colors.orange};
    }
    p {
      color: ${colors.black};
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 35%;
  @media screen and (max-width: 1300px) {
    max-width: 280px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }
  img {
    margin: 0 0 clamp(1.5625rem, -1.5625rem + 3.9063vw, 3.125rem); // 25 - 50
    max-width: 100%;
    display: block;
    @media screen and (max-width: 1000px) {
      max-width: 80%;
      max-height: 200px;
    }
  }
  h2 {
    font-family: 'TCCC-UnityHeadline-Black';
    font-size: clamp(1.5rem, 1.2rem + 1.5vw, 3rem); // 24 - 68
    margin: 0 0 20px;
    text-transform: uppercase;
    line-height: 1;
    width: 60%;
    color: ${props => props.color ? props.color : colors.black}
  }
  h3 {
    width: 100%;
    font-size: 48px;
    line-height: 1;
    font-family: 'TCCC-UnityHeadline-Light';
    color: ${props => props.color ? props.color : colors.green};
    text-transform: uppercase;
    margin: -10px 0 20px;
  }
  p {
    align-self: flex-start;
    width: 100%;
    font-size: clamp(0.75rem, 0.725rem + 0.125vw, 0.875rem); // 12 - 14
    max-width: 360px;
    margin: 0 0 10px;
    color: ${props => props.color ? props.color : colors.black}
  }
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    p {
      max-width: 100%;
    }
  }
`