import React, { useState } from 'react'
import * as S from './styled'

function Carousel({ data }) {
  const [active, setActive] = useState(0)
  const [tooltip, setTooltip] = useState(true)

  const onClickDot = (id) => {
    setActive(id)
    setTooltip(false)
  }

  return(
    <S.Carousel>
      <S.Slider className='slider'>
        {data.carousel.map((item, index) => (
          <S.Item bg={data.bg} key={index} className={`${active === index ? 'active' : ''}`}>
            <S.Graph src={item.image} alt="geometric" />
            <S.Title>{item.title}</S.Title>
            {item.text.map((paragraph, index) => (
              <S.Text dangerouslySetInnerHTML={{__html: paragraph}} key={index} /> 
            ))}
          </S.Item>
        ))}
      </S.Slider>
      <S.Dots className='dots'>
        {data.carousel.map((item, index) => (
          <S.Dot onClick={() => onClickDot(index)} className={`${active === index ? 'active' : ''}`} color={data.color}>
            {index + 1}
          </S.Dot>
        ))}
        <S.Tooltip color={data.color} style={{opacity: tooltip ? '1' : '0'}}>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <span>Clique para navegar entre conteúdos</span>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <span>Click to browse content</span>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <span>Haga clic para explorar el contenido</span>
          : null}
        </S.Tooltip>
      </S.Dots>
    </S.Carousel>
  )
}
export default Carousel