import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Hero = styled.section`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0  clamp(1.25rem, 0.5357rem + 3.5714vw, 3.75rem); // 20 - 60
  margin: 40px 0 20px;
  height: 75vh;
  position: relative;
  @media screen and (max-width: 1000px) {
    height: auto;
    align-items: flex-start;
  }
  &.mobile {
    display: none;
  }
  @media screen and (max-width: 700px) {
    &.mobile {
      display: flex;
    }
  }
`
export const Carousel = styled.div`
  width: 100%;
  max-width: 1400px;
  &.hero-perfil {
    >div {
      align-items: flex-start;
      flex-direction: column;
    }
    .content {
      width: 100%;
      margin: 30px 0 0; // 30 - 60 > 1280 - 1920
    }
  }
  @media screen and (max-width: 1000px) {
    margin: 80px 0 0;
    p {
      max-width: 100%;
    }
    .infos {
      align-items: flex-start;
      p {
        max-width: 100%;
      }
    }
  }
`
export const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 40px 0 0;
  } 
`
export const Content = styled.div`
  width: 65%;
  margin-left: clamp(1.25rem, -1.25rem + 3.125vw, 2.5rem); // 20 - 40 > 1280 - 1920
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black}; 
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 20px 0 0;
  }
`
export const CardsContainer = styled.section`
  width: 100%;
  margin: 56px 0;
  padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  h3 {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto 30px;
    max-width: 1400px;
  }
  nav {
    margin: 0 auto;
    max-width: 1400px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0;
    h3 {
      padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    }
    nav {
      padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    }
  }
`