import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'

function Breadcrumb({ data, current, page }) {
  return ( 
    <S.Container>
      <S.Nav className={page}>
        {page === 'perfil' ?
          <div>
            <Link smooth to={`/diversidade/`}>
              {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
                <span>Diversidade</span>
              : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
                <span>Diversity</span>
              : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
                <span>Diversidad</span>
              : null}
            </Link>
            <span>{'>'}</span>
            <Link smooth to={`/diversidade/perfil`}>
              {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
                <span>Perfil</span>
              : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
                <span>Profile</span>
              : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
                <span>Perfil</span>
              : null}
            </Link>
          </div>
        : 
          <Link smooth to={`/${data.slug}`}>{data.title}</Link>
        }
        <span>{'>'}</span>
        <span>{current.name}</span>
      </S.Nav>
    </S.Container>
  )
}
export default Breadcrumb