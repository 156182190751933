let header = {
  download: 'download guide',
  download_link: `${process.env.PUBLIC_URL}/assets/guia_da_diversidade.pdf`,
  menu: 'choose trail',

  dropdown: [{
    name: 'Diversity',
    slug: 'diversidade'
  },{
    name: 'Representativeness',
    slug: 'representatividade'
  },{
    name: 'Stereotypes',
    slug: 'esteriotipos'
  },{
    name: 'Routine',
    slug: 'rotina'
  },{
    name: 'Good Pratices',
    slug: 'boas-praticas'
  }],

  nav: [{
    name: 'Home',
    slug: 'trilhas',
    type: '#'
  },{
    name: 'Diversity',
    slug: 'diversidade',
    type: '#page'
  },{
    name: 'Representativeness',
    slug: 'representatividade',
    type: '#page'
  },{
    name: 'Stereotypes',
    slug: 'esteriotipos',
    type: '#page'
  },{
    name: 'Routine',
    slug: 'rotina',
    type: '#page'
  },{
    name: 'Good Pratices',
    slug: 'boas-praticas',
    type: '#page'
  },{
    name: "Do's and dont's",
    slug: 'dos-and-donts',
    type: '#'
  }]
}

let footer = {
  text: 'Produced by the Diversity Core BlacKO and SILVA',
  copyright: 'Copyright © 2022 Diversity Paths',
}

export function getHeaderEN() {
  return header
}

export function getFooterEN() {
  return footer
}