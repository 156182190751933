import React from 'react'
import * as S from './styled'

function Practices({ data }) {
  return (
    <S.Columns>
      {data?.columns.map((column, index) => (
        <S.Column key={index}>
          {column.map((paragraph, index) => (
            <S.Paragraph dangerouslySetInnerHTML={{__html: paragraph}} key={index} /> 
          ))}
        </S.Column>
      ))}
    </S.Columns>
  )
}
export default Practices