import styled from 'styled-components'
import { colors } from '../../../globalStyles';

export const Box = styled.div`
  nav {
    display: flex;
    gap: 20px
  }
`
export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 24px;
  font-family: 'TCCC-UnityHeadline-Bold';
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 0 2px;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`
export const Card = styled.div`
  background-color: ${props => props.color ? props.color : colors.yellow};
  padding: clamp(1.5rem, -1.75rem + 4.0625vw, 3.125rem); // 24 - 50 > 1280 - 1920
  padding-right: clamp(1.375rem, -14.625rem + 20vw, 9.375rem); // 22 - 150 > 1280 - 1920
  border-radius: 12px;
  height: 400px;
  @media screen and (max-width: 700px) {
    height: auto;
  }
`
export const Text = styled.div`
  .columns {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin: 0;
      @media screen and (max-width: 700px) {
        margin: 0 0 20px;
      }
    }
    ul {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      
      li {
        h5 {
          line-height: 1.2;
          margin: 0 0 4px;
          font-size: clamp(1.75rem, 1.5rem + 1.25vw, 3rem); // 28 - 48
          font-family: TCCC-UnityCondensed-Bold;
        }
        p {
          text-align: center;
          line-height: 1.2;
        }
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  a {
    display: inline;
    word-break: break-all;
  }
  p {
    font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16 > 1280 - 1920
    margin: 0 0 10px;
    line-height: 1.6;
    &.cite {
      margin: 0 0 20px;
      font-size: 14px;
      &.right {
        text-align: right;
      }
    }
  }
  h4 {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: clamp(1.125rem, 0.875rem + 0.3125vw, 1.25rem); // 18 - 20 > 1280 - 1920
    margin: 0 0 10px;
  }
`