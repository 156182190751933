let dos = {
  modal: `
    <h1>Checklist</h1>
    <div>
      <p>La lucha por la diversidad demanda un trabajo diario.</p>
      <p>El checklist es tu aliado en este camino hacia una publicidad innovadora, inclusiva y pluralista. Durante la producción de su campaña verifica aquí cuál es camino que estás recorriendo  hacia un mundo más justo.</p>
    </div>
  `,
  modal_btn: 'Sepa mas',
  list: [{
    title: `<h2><img src="${process.env.PUBLIC_URL}/assets/check.svg" alt="do's" /> <span>Do's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>¡Por más campañas plurales!</h3>
        <p>Construye castings que representen la realidad de la población brasileña <b>(IBGE - PNAD, 2012-2019):  2,7% blanco, 46,8% pardos, 9,4% como negros y el 1,1% como amarillos o indígenas.</b></p>
        
        <h3>Preócupate con el bienestar del casting!</h3>
        <p>Contrata a una productora que tenga gente que entienda el mensaje que transmite el casting y sepa cómo lidiar con él. Transporte adecuado, alimentación y producción de  belleza no son aspectos secundarios. El casting debe ser muy bien acogido en su totalidad: de las necesidades básicas al trato interpersonal respetuoso.</p>
        
        <h3>¡Practica lo que representas!</h3>
        <p>El equipo que está detrás de las cámaras debe parecerse con la diversidad  que se representa frente a las cámaras.</p>
        <p>Prioriza la contratación de productoras que piensen de ese modo y lo tengan en cuenta en sus próximas contrataciones de equipo.</p>
      </div>

      <div>
        <h3>¡Entiende la realidad del otro!</h3>
        <p>Negocie prazos de pagamentos mais adequados à realidade da produtora contratada.</p>
        <p>Empresas menores, assim como pessoas de baixa renda, são mais sensíveis a longos prazos de pagamento.</p>

        <h3>La campaña no termina ¡después de que haya salido al aire!</h3>
        <p>Prepara a tu equipo de atención al cliente (SAC) para hacer frente a futuras dificultades. Recuerda que nada agrada a todo el mundo y debemos estar siempre  preparados para lidiar de la mejor manera con comentarios negativos.</p>
      </div>
    `
  }, {
    title: ` <h2><img src="${process.env.PUBLIC_URL}/assets/x.svg" alt="dont's" /> <span>Dont's</span></h2>`,
    class: 'text',
    box: `
      <div>
        <h3>No huyas de la responsabilidad.</h3>
        <p>No utilices el hecho de no formar parte de determinados grupos sociales para eximirse  de la responsabilidad de buscar representaciones más saludables.</p>
        <p>Es importante que todo el mundo sepa cómo lidiar con la pluralidad y entienda que un  lugar de habla no se trata de silenciar a nadie, sino de abrir espacio para que las  diversas voces sean escuchadas y tomadas en serio.</p>
        
        <h3>No delegues tareas solamente a una persona!</h3>
        <p>No centralices las decisiones relacionadas con la diversidad, ya sean estructurales o por  proyecto, en una sola persona. Distribuye las tareas de forma equitativa entre tu equipo.</p>
        
        <h3>Nadie es un token de la diversidad!</h3>
        <p>No uses a una persona de su equipo como token de la diversidad. Si miras a tu equipo y sólo una o dos personas representan la diversidad, tu equipo no  es diverso.</p>
      </div>
      <div>
        <h3>¡No ignores a tu equipo!</h3>
        <p>No ignore las pautas de los equipos especializados por mucho que esto influya en los plazos de producción. Un plazo ajustado no puede ser excusa para que una campaña reproduzca estereotipos negativos o desacertados. Revisar la planificación estratégica y de producción durante el proceso puede garantizar que la campaña no repercuta negativamente en el futuro.</p>
        
        <h3>¡No olvides el verdadero propósito!</h3>
        <p>No haga campaña sólo por oportunismo. Antes de hablar de una causa, estudia cuáles son las principales demandas y problemas de un grupo determinado y comprende cómo tu proyecto puede colaborar con ellos. Esté realmente comprometido con la causa y lleve sus acciones más allá de la fecha conmemorativa cuando ese grupo suele estar representado.</p>
        <p>¡La conexión solo se vuelve real si es diaria!</p>
      </div>
    `
  }, {
    title: `<h2>¡No te ablandes!</h2>`,
    class: 'line',
    box: `
      <div>
        <h3>Comprende el poder del lenguaje ¡verbal y no verbal!</h3>
        <p>El lenguaje puede hacer muchas cosas. Puede ser de comprensión fácil , puede  confundir, puede hacer bien  y también hacer daño. Por eso, es importante prestar atención  a los símbolos e imágenes usadas, al igual que las palabras  y su entonación. Pregúntate siempre de qué hablar, cómo hablar, cuándo hablar y dónde hablar ayuda a evitar interpretaciones negativas y malentendidos con los mensajes sobre la diversidad y la inclusión.</p>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist01.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> Familia no representativa de la realidad latinoamericana</figcaption>
        </figure>
        <h3 style="color:#94C15B">Do</h3>
        <p>¡Sé consciente del mundo! Al retratar los encuentros entre amigos, familiares e incluso desconocidos recuérdate que en América Latina viven personas de diferentes orígenes étnicos y nacionales.</p>
      </div>
    `
  }, {
    title: `<h2>¡No te ablandes!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist02.png" alt='imagem' />
          <figcaption class='legend'><span>X</span> Subordinación del hombre negro: mientras los blancos se divierten, él maneja el auto</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>La forma como somos retratados es importante. Diversidad no es sólo cuestión de quién está en la escena sino también se trata de quién se encarga de hacer las cosas. Nadie quiere ver a los negros en posiciones serviles o hombres de pie guiando a mujeres que, sentadas, suenan como agentes pasivos.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>¡Por bancos de imágenes reales! El repositorio de las fotografías debe ser cuidadosamente compuesto y reevaluado con frecuencia.  Piensa en los nuevos modelos familiares. Valora los lazos entre las personas LGBTQIA+. Asegúrate, por ejemplo, que  la maternidad para las mujeres negras sea visibilizada!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>¡No te ablandes!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist03.png" alt='imagem' />
          <figcaption class='legend'><span class='green'>√</span> Homens cuidam, cozinham e são afetivos.</figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>Os locais dizem coisas sobre as pessoas! Não represente mulheres apenas no espaço da casa e da família. Quando você faz isso, de novo e de novo, nega a individualidade delas.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Mulheres estão em todos os lugares! Trabalham, se divertem, encontram amigas(os), viajam, lideram empresas… Pense grande!</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: `<h2>¡No te ablandes!</h2>`,
    class: 'line',
    box: `
      <div>
        <figure>
          <img src="${process.env.PUBLIC_URL}/assets/doandonts/checklist04.png" alt='imagem' />
          <figcaption class='legend'>
            <b class='black tooltip-container'>Blanca<span class='tooltip-text bg-black'>Es importante no confundir el uso de turbantes por parte de las mujeres occidentales blancas con las diferentes vestimentas que usan las mujeres musulmanas. Considere siempre el contexto sociocultural</span></b> busca turbante y un negro sin protagonismo y ni siquiera rostro
          </figcaption>
        </figure>
        <section class='column'>
          <div>
            <h3 style="color:#E54A5A">Don't</h3>
            <p>¡Los locales dicen cosas de la gente! No representes a las mujeres sólo en el espacio del hogar y de la familia. Cuando haces eso  de nuevo, y de nuevo tú niegas su individualidad.</p>
          </div>
          <div>
            <h3 style="color:#94C15B">Do</h3>
            <p>Las mujeres están en todas partes. Trabajan, se divierten, se reúnen con amigos, viajan, lideran empresas, Piensa grande</p>
          </div>
        </section>
      </div>
    `
  }, {
    title: ``,
    class: 'text',
    box: `
      <div>
        <h3>En los cinco caminos del conocimiento que recorriste, ha estado en contacto con  argumentos, datos e investigaciones que le permiten saber qué hacer y cómo hacerlo.</h3>
        <p>Siempre que tenga alguna duda, puede volver a consultar el material recogido en este  guía o incluso ir más allá de él. Lo fundamental es no relajarnos porque el mundo está cambiando. Y nuestro compromiso es hacer de este mundo un un lugar más justo, en el que todos  puedan tener acceso a las oportunidades oportunidades y calidad de vida. Solo no vale empujar hacia el otro el combate a la desigualdad de raza, clase y género.  La lucha por una publicidad inclusiva que esté realmente preocupada por la diversidad  es una tarea de cada uno de nosotros.</p>
        <p>Deconstruimos los estereotipos en la publicidad ¡escapando de lo común! Y juntos, con este Guía, hemos dado los primeros pasos: hemos aprendido a observar la realidad tal y como es, y no basarse en la en suposiciones personales.</p>
      </div> 
      <div>
        <p>Contar historias que realmente se conectan verdaderamente a nuestros consumidores es lo que nos motiva. Pero eso sólo ocurre cuando nos esforzamos, como compañía e individuos, para escuchar las demandas, comprender las expectativas y estructurar las acciones con responsabilidad.</p>
        <p>Si has llegado hasta aquí, sabes que nuestro único y verdadero compromiso es con la  América Latina real. Las personas de carne y hueso, con cuerpos y deseos plurales  importan; y no se puede dejar a nadie fuera</p>
        <p>La inclusión en los ambientes de trabajo solo se produce cuando cada día te preguntas qué puedes hacer con las herramientas que tienes a tu disposición. herramientas que tienes a tu disposición.</p>
        <h3>Toma decisiones, hazlas realidad, ahora que sabes algo de las principales  percepciones y expectativas de los latinoamericanos.</h3>
      </div>
    `
  }]
}
export function getDosES() {
  return dos
}