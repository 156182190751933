import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem) 40px;
  @media screen and (max-width: 1000px) {
    padding: 0 0 40px;
  }
`
export const Quote = styled.blockquote`
  width: fit-content;
  max-width: 1100px;
  margin: clamp(3rem, 2.15rem + 4.25vw, 7.25rem) 0 40px;
  @media screen and (max-width: 1000px) {
    padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
  }
  h3 {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
    margin: 0 0 20px;
    color: ${props => props.color ? props.color : colors.black};
    position: relative;
    z-index: 1;
    .paragraph {
      display: block;
      margin: 0 0 20px;
    }
    @media screen and (max-width: 1000px) {
      .tooltip-container {
        .tooltip-text {
          min-width: 120px;
        }
      }
    }
  }
  p {
    font-size: 18px;
    color: ${props => props.color ? props.color : colors.black};
  }
`