let classes = [
  {
    title: "Equality",
    text: [
      "<p><b>It's when we treat all people the same way.</b> As If in a race, everybody started from the same starting point, but some carried heavier backpacks on their back and others lighter.</p>"
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_01.jpg`
  },
  {
    title: "Inequality",
    text: [
      "<p>It's when people do not have access to the same opportunities. </p>",
      "<p>While most favored social and economical classes have access to good schools, good colleges, and, consequently, good jobs <b>others, due to lack of access, are not even able to participate in the race for good opportunities.</b></p>"
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_03.jpg`
  },
  {
    title: "Equity",
    text: [
      "<p><b>It's when we treat different people according to their differences,</b> considering that each one starts from an specific starting point, since they carry different weights on their backs.</p>"
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_02.png`
  },

]
export function getClassesEN() {
  return classes
}