let ads = [
  {
    title: "Como é hoje",
    color: "#F0AB1B",
    text: `
      <h4>Mais do mesmo</h4>
      <p>“O padrão de beleza idealizado no Brasil ainda é uma mulher branca, magra, com curvas, cabelos lisos e castanhos. Esse padrão aparece em 63% dentre as protagonistas de TV e de Facebook”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
      <h4>E você ainda acha que está tudo bem?</h4>
      <p>“O padrão de beleza masculino idealizado no Brasil é homem branco, musculoso (abdômen e braços traçados), cabelo liso castanho”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
    `
  },
  {
    title: "Como deveria ser",
    color: "#94C15B",
    text: `
      <h4>Menos curvas, mais corpos reais</h4>
      <p>“Segundo levantamento da Associação Brasileira de Normas Técnicas (ABNT), 76% das brasileiras têm o corpo no formato retangular, com mínima diferença entre tórax e quadril, e cintura pouco pronunciada.”</p>
      <p><b>DISPONÍVEL EM: <a href="https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/" target="_blank" rel="noreferrer">https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/</a></b></p>
      <div class="columns">
        <h4>Menos brancos,<br/> mais pretos, pardos,<br/> amarelos e indígenas</h4>
        <ul>
          <li>
            <h5>42,7%</h5>
            <p>Minoria <br/>Branca</p>
          </li>
          <li>
            <h5>57,3%</h5>
            <p>Maioria <br/>Diversa</p>
          </li>
        </ul>
      </div>
      <p class="cite right"><b>População brasileira (IBGE - PNAD, 2012-2019)</b></p>
    `
  },
]
export function getAdsPT() {
  return ads
}