import React, { useState } from 'react'
import Layout from '../../containers/Layout'
import { dos } from '../../json/'
import * as S from './styled'

function About() {
  const [open, setOpen] = useState(true)
  const [nav, setNav] = useState(0)
  const data = dos()
  const length = data.list.length - 1

  const onOpen = () => {
    setOpen(false)
  }

  const onClickArrow = (index) => {
    setNav(index)
  }

  return (
    <Layout bg={open ? '#E54A5A' : '#fff'} texture={open ? 'red' : ''} modal={open}>
      <S.Modal open={open}>
        <div dangerouslySetInnerHTML={{__html: data.modal}} />
        <button onClick={onOpen}>{data.modal_btn}</button>
      </S.Modal>

      <S.Container open={!open}>
        <S.Carousel>
          <S.NavArrow onClick={() => onClickArrow(nav - 1)} style={{ opacity: nav !== 0 ? '1' : '0', pointerEvents: nav !== 0 ? 'all' : 'none'}}>
            <img src={`${process.env.PUBLIC_URL}/assets/pageNav.svg`} alt="prev" />
          </S.NavArrow>

          {data.list.map((item, index) => (
            <S.Item key={index} style={{ display: nav === index ? 'flex' : 'none' }}>
              <div dangerouslySetInnerHTML={{__html: item.title}} />
              <S.Row className={item.class} dangerouslySetInnerHTML={{__html: item.box}} />
            </S.Item>
          ))}

          <S.NavArrow className='next' onClick={() => onClickArrow(nav + 1)} style={{ opacity: nav !== length ? '1' : '0', pointerEvents: nav !== length ? 'all' : 'none'}}>
            <img src={`${process.env.PUBLIC_URL}/assets/pageNav.svg`} alt="next" />
          </S.NavArrow>
        </S.Carousel>
      </S.Container>
    </Layout>
  )
}

export default About