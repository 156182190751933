let menu = {
  logo: `${process.env.PUBLIC_URL}/assets/logo_en.svg`,
  column1: [
    '<b>Understanding that people and groups are unique and must be respected is the first step to comprehend the human diversity.</b>',
    'Our journey seeks to value differences concerning gender, race, social class, sexuality and bodies. Especially in the work enviroment. Even becausa a company that prioratizes having its own diverse team only has to win - literally.',
    "According to McKinsey's research, companies that invest in diversity are more likely to achieve better financial development."
  ],
  column2: [
    'Inclusion is a duty of each one of us, for a professional market more plural and a fairer society.',
    'The material gathered here is a starting point. <b>In five learning trails</b>, you will find data, tips, research, numbers and arguments that will help you to deconstruct common stereotypes in the advertsing market',
    'Make it a mission and keep researching so that evolution remain constant. Changing is possible and this starts now.'
  ],
  nav: [{
    name: 'Diversity',
    slug: 'diversidade',
    color: '#F0AB1B'
  },{
    name: 'Representativeness',
    slug: 'representatividade',
    color: '#EA6833'
  },{
    name: 'Stereotypes',
    slug: 'esteriotipos',
    color: '#94C15B'
  },{
    name: 'Routine',
    slug: 'rotina',
    color: '#3594C9'
  },{
    name: 'Good Pratices',
    slug: 'boas-praticas',
    color: '#4CBF85'
  }],
}
export function getMenuEN() {
  return menu
}