import React from 'react'
import * as S from './styled'

function Raca({ data }) {
  return (
    <S.Row>
      {data?.row && <S.List>
        {data.row.map((item, index) => (
          <S.Item key={index} className={item.class}>
            <h3>{item.title}</h3>
            {item?.list && <ul>
              {item.list.map((el, j) => (
                <li key={j}>
                  <h4>{el.percent}</h4>
                  <p dangerouslySetInnerHTML={{__html: el.name}} />
                </li>
              ))} 
            </ul>}
            <div>
              {item?.text.map((paragraph, k) => (
                <S.Paragraph dangerouslySetInnerHTML={{__html: paragraph}} key={k} /> 
              ))}
            </div>
          </S.Item>
        ))}
      </S.List>}
    </S.Row>
  )
}
export default Raca