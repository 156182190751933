import styled from 'styled-components'
import { colors } from '../../globalStyles'

export const Row = styled.div`
  display: grid;
`
export const Title = styled.h3`
  font-size: clamp(1.125rem, -0.625rem + 2.1875vw, 2rem); // 18 - 32 > 1280 - 1920
  font-weight: 700;
  font-family: 'TCCC-UnityHeadline-Bold';
`
export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: clamp(1.25rem, -1.25rem + 3.125vw, 2.5rem) 0 0; // 20 - 40
  gap: 12px;
  border: 1px solid ${colors.black};
  padding: 20px;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
  }
`
export const Item = styled.li`
  text-align: center;
  @media screen and (max-width: 700px) {
    width: 80px;
  }
`
export const Legend = styled.p`
  color: ${colors.black};
  font-size: 12px;
  font-family: 'TCCC-UnityHeadline-Bold';
  margin: 12px 0 clamp(1.25rem, -1.25rem + 3.125vw, 2.5rem);
`
export const Percent = styled.h4`
  color: ${props => props.color ? props.color: colors.black};
  font-size: clamp(2.375rem, 1.125rem + 1.5625vw, 3rem); // 38 - 48
  font-family: 'TCCC-UnityCondensed-Bold';
`
export const Country = styled.p`
  color: ${props => props.color ? props.color: colors.black};
  font-size: clamp(1rem, 0.25rem + 0.9375vw, 1.375rem); // 16 - 22
  font-family: 'TCCC-UnityText-Medium';
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
`
export const Flag = styled.span`
  display: block;
  font-size: 18px;
  font-family: 'TCCC-UnityText-Medium';
`
export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`
export const Column = styled.div`
  width: 48%;
  .colorfull {
    color: ${colors.blue};
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`
export const Paragraph = styled.p`
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16
  margin: 0 0 20px;
`