import React from 'react'
import * as S from './styled'

function Representatividade({ data }) {
  return (
    <S.Row>
      {data?.text_title && <S.Title>{data?.text_title}</S.Title>}

      {data?.text &&  <S.Text>
        {data.text.map(( item, index ) => (
          <p dangerouslySetInnerHTML={{ __html: item }} key={index} />
        ))}
      </S.Text>}

      {data?.table_title && <S.TableTitle>{data?.table_title}</S.TableTitle>}
      
      {data?.table && 
        <S.Table>
          {data?.table?.row_1 && <ul className={data?.table?.type === 'title' ? 'title' : null}>
            {data?.table?.row_1.map(( item, index ) => (
              <li key={index}>
                {item.img && <img src={item.img} alt="simbol" className='simbol' />}
                <p className='table-title'>{item.title}</p>
                <h6 className='table-percent'>{item.percent}</h6>
                <p className='table-country'>{item.country}</p>
                <p className='table-flag'>{item.flag}</p>
              </li>
            ))}
          </ul>}
          {data?.table?.row_2 &&<ul className={data?.table?.type === 'title' ? 'title' : null}>
            { data?.table?.row_2.map(( item, index ) => (
              <li key={index}>
                {item.img && <img src={item.img} alt="simbol" className='simbol' />}
                <p className='table-title'>{item.title}</p>
                <h6 className='table-percent'>{item.percent}</h6>
                <p className='table-country'>{item.country}</p>
                <p className='table-flag'>{item.flag}</p>
              </li>
            ))}
          </ul>}
        </S.Table>
      }

      {data?.legend && <S.Legend>{data?.legend}</S.Legend>}

      {data?.row && <S.List>
        {data.row.map((item, index) => (
          <S.Item dangerouslySetInnerHTML={{__html: item}} key={index} color={data.color} />
        ))}
      </S.List>}

      {data?.columns && <S.Columns>
        {data.columns.map((column, index) => (
          <S.Column key={index}>
            {column.map((paragraph, index) => (
              <S.Paragraph dangerouslySetInnerHTML={{__html: paragraph}} key={index} /> 
            ))}
          </S.Column>
        ))}
      </S.Columns>}
    </S.Row>
  )
}
export default Representatividade