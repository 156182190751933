import React from 'react'
import Layout from '../../containers/Layout'
import Menu from '../../components/Menu'
import { menu } from '../../json/'

function Home() {
  return (
    <Layout>
      <Menu data={ menu() } />
    </Layout>
  )
}

export default Home