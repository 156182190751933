import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'

function Cards({ data }) {
  return (
    <S.Nav>
      {data.map((item, index) => (
        <Link
          smooth
          to={`/diversidade/perfil/${item.slug}/#`}
          key={index}
          style={{ border: `1px solid ${item.card.color}` }}
        >
          <S.Content>
            <figure>
              <S.Graph src={item.card.image} alt={item.name} />
            </figure>
            <div>
              <S.Title color={item.card.color}>{item.name}</S.Title>
              {item.card.description && item.card.description.length > 0 && <S.Description>
                {item.card.description.map((paragraph, index) => (
                  <S.Text key={index} color={item.card.color}>{paragraph}</S.Text>
                ))}
              </S.Description>}
            </div>
          </S.Content>
        </Link>
      ))}
    </S.Nav>
  )
}
export default Cards