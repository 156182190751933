import React from 'react'
import { Link } from 'react-router-dom'
import * as S from './styled'

function CallToAction() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <h2>Do's and Dont's</h2>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <p>Durante a produção de sua campanha, verifique aqui em que ponto você está na direção de um mundo mais justo.</p>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <p>During the production of your campaign, check here in which point you are on the way of a fairer world.</p>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <p>Durante la producción de tu campaña,  comprueba en qué punto te diriges hacia un mundo más justo. </p>
          : null}
        </S.Content>
        <Link to={'/dos-and-donts/'}>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <span>Saiba mais</span>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <span>Know More</span>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <span>Obtenga más información</span>
          : null}
        </Link>
      </S.Container>
    </S.Wrapper>
  )
}
export default CallToAction
