import React, { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import * as S from './styled'

import AOS from 'aos'
import 'aos/dist/aos.css'

function Cards({ data, slug, margin }) {

  useEffect(() => {
    AOS.init({
      duration : 1000,
      once: true
    });
  }, []);

  return ( 
    <S.Nav margin={margin} size={100/data.length}>
      {data.map((item, index) => (
        <Link
          smooth
          to={`/${slug}/${item.slug}/#`}
          key={index}
          style={{ minHeight: item.card.height + 'px', border: `1px solid ${item.card.color}`}}
          data-aos="fade-up"
          data-aos-delay={100 * index}
        >
          <S.Content position={item.card.position}>
            <figure>
              <S.Graph src={item.card.image} alt={item.name} />
            </figure>
            <S.Title color={item.card.color} position={item.card.position}>{item.name}</S.Title>
            {item.card.description && item.card.description.length > 0 && <S.Description>
              {item.card.description.map((paragraph, index) => (
                <S.Text key={index} color={item.card.color} dangerouslySetInnerHTML={{__html: paragraph}} />
              ))}
            </S.Description>}
          </S.Content>

          <S.Button disabled={item.card.disabled_link}>
            {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
              <span>Saiba mais </span>
            : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
              <span>Know More </span>
            : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
              <span>Obtenga más información </span>
            : null}
            <img src={`${process.env.PUBLIC_URL}/assets/arrow-right.svg`} alt="seta direita" />
          </S.Button>
        </Link>
      ))}
    </S.Nav>
  )
}
export default Cards