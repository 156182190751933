let diversity = {
  color: '#F0AB1B',
  slug: "diversidade",
  title: "Diversidade",
  description: `
    <span class="paragraph">Aqui você entende porquê as estratégias de negócios precisam considerar as singularidades de pessoas e grupos</span>
    “A gente não está discutindo filantropia, mas a sustentabilidade de um negócio, impacto positivo e também bem-estar e desenvolvimento das pessoas”.
  `,
  cite: "Helena Bertho, Diretora Global de Diversidade e Inclusão do Nubank.",
  menu: [
    {
      index: 0,
      name: "Estratégia",
      slug: "estrategia",
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-01.png`,
        description: [
          "Diversidade é estratégia de negócio e oportunidade de impactar positivamente toda uma cadeia produtiva.",
          "Diversidade não é somente assunto de campanha publicitária"
        ],
      },

      hero: {
        name: "Estratégia",
        color: '#E54A5A',
        type: 'grid',
        description: [
          "Diversidade é estratégia de negócio e oportunidade de impactar positivamente toda uma cadeia produtiva. Diversidade não é somente assunto de campanha publicitária."
        ],
        grid: [
          {
            span: 1,
            color: '#EA6833',
            title: 'Impacto',
            text: [
              'Times diversos com formação continuada criam estratégias inovadoras capazes de cativar um público maior.',
              'Em companhias comprometidas com a diversidade, <b>“os colaboradores estão 64% mais propensos a compartilhar ideias e boas práticas”.</b>',
              '<b>(Diversity Wins - McKinsey, 2020)</b>',
            ]
          },
          {
            span: 1,
            color: '#E54A5A',
            title: 'Clima Organizacional',
            text: [
              '“Sem diversidade, a empresa perde valor de mercado, perde no clima organizacional, na confiança e no respeito de seus colaboradores”.',
              'Thais Batista, Analista de Comunicação Interna Sênior da Schneider Eletric. (Olabi, 2021)',
              '<b>E, ano após ano, os dados da McKinsey reafirmam isso.</b>',
            ]
          },
          {
            span: 2,
            color: '#3594C9',
            title: 'Lucratividade',
            text: [
              '<span>2018</span> “Empresas com conselhos de administração com maior diversidade étnica/cultural em todo o mundo têm <b>43% mais chances</b> de obter lucros maiores.” <br/> <b>(Delivering Through Diversity - McKinsey, 2018)</b>',
              '<span>2020</span> “Empresas que investem em diversidade étnico-racial estão <b>36% mais propensas</b> a obter lucros.”<br/><b>(Diversity Wins - McKinsey, 2020)</b>',
            ]
          },
        ],
      },
      prev: {
        name: "Ir para Perfil",
        slug: "diversidade/perfil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "diversidade/inovacao",
      },
    },
    {
      index: 1,
      name: "Inovação",
      slug: 'inovacao',
      hero: {
        name: "Inovação",
        color: '#E54A5A',
        type: 'carousel',
        bg: false,
        description: [
          "“A diversidade possibilita o surgimento de ideias inovadoras, com produtos com qualidade e criatividade que um grupo de perfil único teria dificuldade em pensar e desenvolver; sem diversidade, não iríamos alavancar os negócios.”",
          "<b>Alessandra Karine, da Microsoft Brasil <br/>(Olabi, 2021)</b>"
        ],
        carousel: [
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-01.png`,
            title: 'Decisão de Compra',
            text: [
              'O público consumidor está cada vez mais atento à <b>responsabilidade social</b> de empresas, marcas e organizações.',
              'A decisão de compra de <b>63% dos brasileiros</b> tem como critérios diversidade e inclusão.',
              '<span><b>(Diversidade e Inclusão - Facebook, 2020)</b></span>',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-02.png`,
            title: 'Adaptação',
            text: [
              'A diversidade e a sustentabilidade nas empresas criam <b>culturas organizacionais capazes de se adaptar às transformações do mundo,</b> respeitando os impactos sofridos por pessoas a partir de marcadores como gênero, raça e classe.',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-03.png`,
            title: 'Pessoas',
            text: [
              '“Colaboradores de empresas que mantêm compromisso com a diversidade estão <b>152% mais inclinados a propor novas ideias e sentem-se 62% mais motivados para colaborar em equipes”.</b>',
              '<span>(Diversity Wins - McKinsey, 2020)</span>',
            ]
          },
        ],
      },
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-02.png`,
        description: [
          "“A diversidade possibilita o surgimento de ideias inovadoras, com produtos com qualidade e criatividade que um grupo de perfil único teria dificuldade em pensar e desenvolver”",
          "<b>Alessandra Karine, da Microsoft Brasil (Olabi, 2021)</b>"
        ],
      },
      prev: {
        name: "Ir para Estratégia",
        slug: "diversidade/estrategia",
      },
      next: {
        name: "Continuar a trilha",
        slug: "diversidade/publicidade-brasileira",
      },
    },
    {
      index: 2,
      name: "Publicidade Brasileira",
      slug: 'publicidade-brasileira',
      hero: {
        name: "Publicidade <br/> Brasileira",
        color: '#F0AB1B',
        type: '',
        description: [
          "“Você tem que agir como se fosse possível transformar radicalmente o mundo.",
          "E você tem que fazer isso o tempo todo”.",
          "<b>Angela Davis</b>"
        ],
      },
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-03.png`,
        description: [
          "“Você tem que agir como se fosse possível transformar radicalmente o mundo. E você tem que fazer isso o tempo todo”.",
          "<b>Angela Davis</b>"
        ],
      },
      prev: {
        name: "Ir para Inovação",
        slug: "diversidade/inovacao",
      },
      next: {
        name: "Continuar a trilha",
        slug: "diversidade/perfil",
      },
    },
    {
      index: 3,
      name: "Perfil",
      slug: 'perfil',
      hero: {
        name: "Perfil",
        color: '#E54A5A',
        type: 'perfil',
        description: [
          "A diversidade rompe a homogeneidade dos perfis e respeita a realidade latino-americana composta de histórias plurais em termos de:"
        ],
        cards: [
          {
            color: '#E54A5A',
            texture: 'red',
            name: "Gênero",
            slug: 'genero',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#E54A5A',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero.svg`,
              description: [
                "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/ dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas"
              ],
            },
            hero: {
              name: "Gênero",
              color: '#E54A5A',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero-white.svg`,
              description: [
                "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/dele), femininos (ela/dela) e neutros <b class='black tooltip-container'>(elu/delu)<span class='tooltip-text'>A linguagem neutra é fruto das reivindicações de pessoas que não se enxergam de modo binário, masculino ou feminino, e propõem que pronomes e adjetivos sejam flexionados sem a marcação de gênero. As letras “e” e “u”, como em Elu/Delu, enriquecem o nosso vocabulário, permitindo “novas” formas de nomeação e existência.</span></b> é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/cisgenero.svg`,
                  name: "Cisgênero",
                  description: [
                    "Pessoas que se identificam com o gênero atribuído ao seu nascimento.",
                    "<b>Não é regra, então não aja como se fosse obrigatoriedade.</b>"
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/trans.svg`,
                  name: `Pessoas trans e <span class="tooltip-container">
                  travestis
                    <span class="tooltip-text bg-red short">
                      Categoria latino-americana utilizada para referir-se a pessoas que não se identificam com o sexo atribuído ao nascimento e tem performance de gênero associada ao feminino. Vale sinalizar que as categorias mulher trans e travesti surgiram em períodos históricos específicos e foram associadas a valores também específicos, tendo sido a identidade travesti frequentemente associada à marginalização social e à prostituição. Assumi-la é também um ato político. As mulheres trans podem se identificar como mulheres, independentemente de terem passado por cirurgias, e as travestis devem ser tratadas no feminino. Ambas as identidades surgiram associadas ao feminino, mas isso não quer dizer que não existam travestis não-binárias ou pessoas que usam as categorias trans e travesti ao mesmo tempo.
                    </span>
                  </span>`,
                  description: [
                    "Pessoas que se identificam com um gênero diferente daquele que lhe foi atribuído ao nascimento e determinam a própria identidade.",
                    "<b>Pessoas são livres para serem o que quiserem.</b>"
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/nao-binarios.svg`,
                  name: "Não-binários",
                  description: [
                    "Pessoas que não se identificam nem com o gênero masculino, nem com o gênero feminino.",
                    "<b>Ninguém precisa se definir ou rotular.</b>"
                  ],
                }
              ]
            },
            prev: {
              name: "Ir para Classe",
              slug: "diversidade/perfil/classe",
            },
            next: {
              name: "Continuar a trilha",
              slug: "diversidade/perfil/raca",
            },
          },
          {
            color: '#EA6833',
            texture: 'orange',
            name: "Raça",
            slug: 'raca',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#EA6833',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca.svg`,
              description: [
                "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante."
              ],
            },
            hero: {
              name: "Raça",
              color: '#EA6833',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca-white.svg`,
              description: [
                "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante."
              ],
              row: [
                {
                  class: "percent",
                  title: "Classificação de cor/raça (IBGE - PNAD, 2012-2019)",
                  list: [
                    {
                      percent: "56,2%",
                      name: `Pretos e <span class="tooltip-container">
                                pardos
                                <span class="tooltip-text bg-orange short">
                                  Termo aglutinador utilizado no recenseamento brasileiro dos anos 2000 “para a pessoa que se enquadrar como parda ou se declarar mulata, cabocla, cafuza, mameluca ou mestiça” (Manual do recenseador – IBGE, 2000). Quando perguntado ao entrevistado sua cor, ele tem a opção de responder pardo, preto, indígena, amarelo e branco – não devendo o entrevistador pressupor que negro, por exemplo, é sinônimo de preto ou de pardo. Cabe ao entrevistado, a autoclassificação. Embora o censo brasileiro dos anos 2000 sugira aproximações entre as categorias pardo e mestiço, optou-se pela não tradução imediata porque essas categorias têm histórias sociais e administrativas diferentes, especialmente no que se refere, no Brasil, à relação entre mestiçagem e política de embranquecimento da nação.
                                </span>
                              </span>`,
                    },
                    {
                      percent: "42,7%",
                      name: "Brancos"                      
                    },
                    {
                      percent: "0,7%",
                      name: "Amarelo"                      
                    },
                    {
                      percent: "0,4%",
                      name: "Indígenas"                      
                    }
                  ],
                  text: [
                    "Minorias, como amarelos e indígenas, devem ser respeitadas. E maiorias, como pretos e pardos, não devem ser minorizadas.",
                  ]
                },
                {
                  class: "colorism",
                  title: "Colorismo",
                  text: [
                    "É uma forma de discriminação baseada na tonalidade da pele. Quanto mais pigmentada uma pessoa, mais exclusão ela sofre. Na publicidade, o colorismo resulta na preferência por negros de pele clara, como se todos que se aproximam do branco fossem mais humanos.",
                    "<b>'Vidas negras importam', sem seletividade.</b>",
                  ]
                },
                {
                  class: "racism",
                  title: "Racismo",
                  text: [
                    "O racismo acontece quando um grupo com poder, historicamente branco, discrimina pessoas indígenas, negras e de origem asiática, dentre outras. Essa discriminação pode ser violenta ou silenciosa, mas resulta sempre em hierarquias: brancos são alçados ao topo enquanto outros humanos são inferiorizados.",
                    "<b>Não basta não ser racista, é preciso ser <span>Antirracista!</span></b>",
                  ]
                }
              ]
            },
            prev: {
              name: "Ir para Gênero",
              slug: "diversidade/perfil/genero",
            },
            next: {
              name: "Continuar a trilha",
              slug: "diversidade/perfil/lgbtqia",
            },
          },
          {
            color: '#F0AB1B',
            texture: 'yellow',
            name: "LGBTQIA+",
            slug: 'lgbtqia',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#F0AB1B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia.svg`,
              description: [
                "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
              ],
            },
            hero: {
              name: "LGBTQIA+",
              color: '#EA6833',
              type: 'carousel',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia-white.svg`,
              description: [
                "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades",
              ],
              carousel: [
                {index:0},
                {index:1},
                {index:2},
              ],
            },
            prev: {
              name: "Ir para Raça",
              slug: "diversidade/perfil/raca",
            },
            next: {
              name: "Continuar a trilha",
              slug: "diversidade/perfil/corpos-plurais",
            },
          },
          {
            color: '#94C15B',
            texture: 'green',
            name: "Corpos Plurais",
            slug: 'corpos-plurais',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#94C15B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais.svg`,
              description: [
                "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
              ],
            },
            hero: {
              name: "Corpos Plurais",
              color: '#94C15B',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais-white.svg`,
              description: [
                "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/capacitismo.svg`,
                  name: "Capacitismo",
                  description: [
                    "Preconceito contra pessoas com deficiência. É acreditar que alguns seres humanos são inferiores e/ou menos capazes que outros em razão de alguma de suas características.",
                    "<b>Diferente é quem não respeita as diferenças do outro.</b>"
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/etarismo.svg`,
                  name: "Etarismo",
                  description: [
                    "Intolerância contra indivíduos ou grupos etários com base em estereótipos associados à idade. Pessoas mais velhas não são limitadas.",
                    "<b>Limitado é esse pensamento.</b>"
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/gordofobia.svg`,
                  name: "Gordofobia",
                  description: [
                    "Manifestações discriminatórias contra pessoas gordas que resultam em apagamento e inferiorização de seus corpos.",
                    "<b>Não queira mudar a forma das pessoas, mas sim a maneira como você as enxerga.</b>"
                  ],
                }
              ]
            },
            prev: {
              name: "Ir para LGBTQIA+",
              slug: "diversidade/perfil/lgbtqia",
            },
            next: {
              name: "Continuar a trilha",
              slug: "diversidade/perfil/classe",
            },
          },
          {
            color: '#3594C9',
            texture: 'blue',
            name: "Classe",
            slug: 'classe',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#3594C9',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe.svg`,
              description: [
                "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
              ],
            },
            hero: {
              name: "Classe",
              color: '#3594C9',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe-white.svg`,
              description: [
                "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos brasileiros."
              ],
            },
            prev: {
              name: "Ir para Corpos",
              slug: "diversidade/perfil/corpos-plurais",
            },
            next: {
              name: "Continuar a trilha",
              slug: "representatividade",
            },
          }
        ]
      },
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-04.png`,
        description: [
          "A diversidade rompe a homogeneidade dos perfis e respeita a realidade latino-americana composta de histórias plurais."
        ],
      },
      prev: {
        name: "Ir para Publicidade",
        slug: "diversidade/publicidade-brasileira",
      },
      next: {
        name: "Continuar a trilha",
        slug: "diversidade/perfil/genero",
      },
    },
  ]
};

let represents = {
  color: '#EA6833',
  slug: 'representatividade',
  title: "Representatividade",
  description: `
    <span class="paragraph">Aqui você encontra argumentos e números que te ajudam a perceber que de fato importa trazer corpos diversos para a publicidade</span>
    Em um mundo em transformação, abrir espaços para as múltiplas <span class='tooltip-container'>identidades<span class='tooltip-text bg-orange'>O conjunto de atributos que caracteriza uma pessoa, podendo distingui-la ou localizá-la num grupo. Por exemplo, a Beyoncé é uma mulher cisgênera negra. Esta é sua identidade.</span></span> é necessário para desviar do perigo de uma história única sobre quem as pessoas são e o que podem ser.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Gênero",
      slug: 'genero-brasil',
      hero: {
        name: "Gênero",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
        ],
        row: [
          '<p>Homens, mulheres, cis, trans, travestis ou pessoas não-binárias devem estar representadas na publicidade e nas companhias, <b>mas algumas pessoas são invisibilizadas simplesmente em função de serem quem são.</b></p>',
          `
            <div class="box">
              <div class="percent">74%</div>
              <div class="text">
                <p>das mulheres brasileiras que aparecem são brancas.</p>
                <p class="legend"><b>(Todxs, 9º Onda)</b></p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>Há poucos dados sobre pessoas trans. Isso também é consequência da invisibilização. E o que sabemos? Que o Brasil é o país que mais mata mulheres trans e travestis pelo 13º ano consecutivo.</p>
                <p class="legend"><b>(Antra, 2022)</b></p>
              </div>
            </div>
          `,
          '<p>A publicidade deve utilizar o seu alcance para reforçar a ideia de que todas as pessoas merecem ocupar espaços de forma igualitária.</p>',
        ],
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
        ],
      },
      prev: {
        name: "Ir para Classe Latam",
        slug: "representatividade/classe-latam",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/genero-latam",
      },
    },
    {
      index: 1,
      name: "Gênero",
      slug: 'genero-latam',
      hero: {
        name: "Gênero",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p>Por toda a América Latina, mulheres e LGBTQIA+ seguem lutando por mais igualdade e inclusão. <b>Dentro desses dois grupos, existe um alto índice de pessoas que consideram importante essas reivindicações.</b> Isso mostra como os mesmos são os mais empenhados em não só entender, mas também correr atrás do que acreditam.</p>"
        ],
        table_title: "Relevância de Pauta",
        table: {
          type: "title",
          row_1: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
              title: "Mulheres"
            },
            {
              color: "#64193A",
              percent: "91%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#792240",
              percent: "90%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#97314A",
              percent: "89%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#B54253",
              percent: "88%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#D4565E",
              percent: "88%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#DF7C7A",
              percent: "85%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ],
          row_2: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
              title: "LGBTQIA+"
            },
            {
              color: "#64193A",
              percent: "72%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "70%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "67%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#D4565E",
              percent: "64%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#DF7C7A",
              percent: "52%",
              country: "Peru",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversidade e Inclusão - Facebook, 2020)",
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "Diz respeito à forma como nos identificamos e somos percebidos no mundo."
        ],
      },
      prev: {
        name: "Ir para Gênero Brasil",
        slug: "representatividade/genero-brasil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/raca-brasil",
      },
    },
    {
      index: 2,
      name: "Raça",
      slug: 'raca-brasil',
      hero: {
        name: "Raça",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante.",
        ],
        row: [
          '<p>Sabemos que o Brasil tem uma estrutura racista que privilegia pessoas brancas em detrimento de pessoas negras e indígenas.</p>',
          '<p><b>É preciso mudar o cenário e construir equipes com diversidade racial para que maiorias minorizadas sejam contempladas em sua pluralidade.</b></p>',
          `
            <div class="box">
              <div class="text black">
                <p>Mulheres negras são apenas <span>3%</span> entre líderes nas empresas</p>
                <p class="legend">Disponível em: <a href="https://www.terra.com.br/nos/mulheres-negras-sao-apenas-3-entre-lideres-nas-empresas-diz-estudo,cc51a786e4402676743ce2163f45fce9hj9rhyxq.html" target="_blank">Mulheres negras são apenas 3% entre líderes nas empresas, diz estudo</a> (terra.com.br)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>Apenas <span>4%</span> das novelas da principal emissora brasileira foram protagonizadas por mulheres não-brancas e <span>1%</span> por homens não-brancos.</p>
                <p class="legend">(GEMAA, 2015)</p>
              </div>
            </div>
          `,
          '<p><b>O casting precisa refletir a diversidade entre pessoas negras e indígenas, incluindo pessoas negras retintas e pessoas indígenas em posições de destaque.</b></p>',
        ],
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante.",
        ],
      },
      prev: {
        name: "Ir para Gênero Latam",
        slug: "representatividade/genero-latam",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/raca-latam",
      },
    },
    {
      index: 3,
      name: "Raça",
      slug: 'raca-latam',
      hero: {
        name: "Raça",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante.",
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p><b>Aos olhos da população latino-americana, falta diversidade e inclusão nas campanhas,</b> já que a publicidade ainda insiste em reproduzir estereótipos - até mesmo raciais.</p>",
          "<p>Em grande parte, mais da metade das pessoas entrevistadas concorda que os anúncios retratam negros ou indígenas em <b>posição de menos destaque.</b></p>"
        ],
        table_title: "Falta de notoriedade ou proeminência de pessoas não-brancas",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "60%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "60%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#97314A",
              percent: "59%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "54%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "49%",
              country: "Brasil",
              flag: "🇧🇷"   
            },
            {
              color: "#DF7C7A",
              percent: "48%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ]
        },
        legend: "(Diversidade e Inclusão - Facebook, 2020)",
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "Categorização social que se baseia em traços corporais e resulta em desigualdade para pessoas pretas e indígenas.",
        ],
      },
      prev: {
        name: "Ir para Raça Brasil",
        slug: "representatividade/raca-brasil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/lgbtqia-brasil",
      },
    },
    {
      index: 4,
      name: "LGBTQIA+",
      slug: 'lgbtqia-brasil',
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
        row: [
          '<p><b>Lésbicas, gays, bissexuais, transgêneros, travestis, queer, intersexo e assexuais são algumas das identidades que a sigla LGBTQIA+ contempla,</b> valorizando e respeitando a diversidade de gêneros, corpos e sexualidades.</p>',
          `
            <div class="box center">
              <p>Conhecer é importante, mas não é tudo.</p>
            </div>
          `,
          '<p><b>Pessoas LGBTQIA+ devem ser representadas na publicidade durante todo o ano e não apenas em datas comemorativas ou períodos de destaque. Do contrário, marcas serão lidas como interessadas apenas em <span class="tooltip-container">Pink Money<span class="tooltip-text bg-yellow">Termo usado para caracterizar a comercialização de produtos para o público LGBTQIA+ com o único intuito de aumentar o potencial de consumo.</span></span>.</b></p>',
        ],
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
      },
      prev: {
        name: "Ir para Raça Latam",
        slug: "representatividade/raca-latam",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/lgbtqia-latam",
      },
    },
    {
      index: 5,
      name: "LGBTQIA+",
      slug: 'lgbtqia-latam',
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p>Empresas que não atuam de forma ética podem ser mal recebidas pela comunidade LGBTQIA+ latino-americana. <b>Quando se posicionam em favor da inclusão apenas em períodos restritos, marcas podem ser vistas como oportunistas.</b> A maior parte dos entrevistados prefere comprar de empresas que realmente se preocupam com diversidade e inclusão.</p>"
        ],
        table_title: "Decisão de compra",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "76%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#792240",
              percent: "71%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#97314A",
              percent: "70%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#D4565E",
              percent: "60%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "58%",
              country: "México",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversidade e Inclusão / LGBTQIA+ - Facebook, 2021)",
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
      },
      prev: {
        name: "Ir para LGBTQIA+ Brasil",
        slug: "representatividade/lgbtqia-brasil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/corpos-plurais-brasil",
      },
    },
    {
      index: 6,
      name: "Corpos Plurais",
      slug: 'corpos-plurais-brasil',
      hero: {
        name: "Corpos <br/> Plurais",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário."
        ],
        row: [
          '<p>Nós somos diversos por natureza. <b>Contudo, algumas pessoas são invalidadas por suas diferenças,</b> como pessoas gordas, pessoas com deficiência, pessoas idosas e outros grupos sub-representados.</p>',
          `
            <div class="box">
              <div class="text black">
                <p>A publicidade é gordofóbica. Apenas <span>1%</span> das personagens em campanhas são gordas. <span>16%</span> apresentam corpo plus size.</p>
                <p class="legend">(Todx, 6º Onda)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>Cerca de <span>15%</span> da população mundial, mais de um bilhão de pessoas, tem alguma deficiência, ainda assim PCDs são mal representadas nas campanhas publicitárias</p>
                <p class="legend">(Relatório Mundial sobre a deficiência – OMS, 2011; Dados, Diversidade e Representação - Facebook)</p>
              </div>
            </div>
          `,
          '<p><b>É papel da publicidade pluralizar as representações de corpos e pessoas, priorizando cenas cotidianas e desfazendo <span class="tooltip-container">exotismos<span class="tooltip-text bg-green short">Termo utilizado quando diminuímos a expressão de alguém à mera qualidade de exótico, estranho, vulgar ou extravagante.</span></span>.</b></p>',
        ],
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
        ],
      },
      prev: {
        name: "Ir para LGBTQIA+ Latam",
        slug: "representatividade/lgbtqia-latam",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/corpos-plurais-latam",
      },
    },
    {
      index: 7,
      name: "Corpos Plurais",
      slug: 'corpos-plurais-latam',
      hero: {
        name: "Corpos <br/> Plurais",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p>As pessoas com deficiência na América Latina, além de considerarem que <b>as propagadas no geral ainda são muito estereotipadas, não se identificam com o que é representado.</b> Grande parte dos entrevistados que</p>",
          "<p>possui ou convive com alguém com deficiência considera que a maioria das propagandas ainda representa prioritariamente pessoas brancas, magras e ricas.</p>"
        ],
        table_title: "Falta de diversidade",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "80%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "73%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#97314A",
              percent: "72%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#B54253",
              percent: "71%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#D4565E",
              percent: "68%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "67%",
              country: "Peru",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversidade e Inclusão / Pessoas com Deficiência - Facebook, 2021)",
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
        ],
      },
      prev: {
        name: "Ir para Classe Brasil",
        slug: "representatividade/corpos-plurais-brasil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/classe-brasil",
      },
    },
    {
      index: 8,
      name: "Classe",
      slug: 'classe-brasil',
      hero: {
        name: "Classe",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
        ],
        row: [
          '<p>A desigualdade social caracteriza sociedades que têm alto nível de concentração de renda.</p>',
          '<p><b>Esse fenômeno impacta quem são as pessoas que produzem conteúdo e quem são aquelas que estão representadas.</b></p>',
          `
            <div class="box">
              <div class="text">
                <p>“Os homens brancos do 1% mais rico se apropriam demais renda do que todas as mulheres negras do país.”</p>
                <p class="legend">(NPE nº18, Made USP)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>69% dos brasileiros consideram que o mercado de trabalho é desigual, especialmente para as minorias e os grupos minorizados.</p>
                <p class="legend">(Diversidade e Inclusão - Facebook, 2020)</p>
              </div>
            </div>
          `,
          '<p><b>Times sem diversidade, mesmo que motivados por boas intenções, tem mais chances de representar símbolos de classe fora de contexto e tom. Isso pode acontecer, por exemplo, nos momentos em que casting e figurino soam como caricaturas.</b></p>',
        ],
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
        ],
      },
      prev: {
        name: "Ir para Corpos Plurais Latam",
        slug: "representatividade/corpos-plurais-latam",
      },
      next: {
        name: "Continuar a trilha",
        slug: "representatividade/classe-latam",
      },
    },
    {
      index: 9,
      name: "Classe",
      slug: 'classe-latam',
      hero: {
        name: "Classe",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p>Embora empresas diversas na América Latina sejam mais propensas a superar seus concorrentes <b>(Diversity Matters - Mckinsey, 2020)</b>, o mercado de trabalho é desigual na percepção dos latino-americanos. <b>Boa parte dos entrevistados acredita que as oportunidades de trabalho são restritas a grupos sociais historicamente privilegiados.</b></p>"
        ],
        table_title: "Menos oportunidades",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "69%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#792240",
              percent: "69%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "68%",
              country: "Colômbia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "68%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "63%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#DF7C7A",
              percent: "62%",
              country: "México",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversidade e Inclusão - Facebook, 2020)",
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada em todas as esferas da sociedade."
        ],
      },
      prev: {
        name: "Ir para Classe Brasil",
        slug: "representatividade/classe-brasil",
      },
      next: {
        name: "Continuar a trilha",
        slug: "esteriotipos",
      },
    },
  ]
};

let stereotypes = {
  color: '#94C15B',
  slug: 'esteriotipos',
  title: "Esteriótipos",
  description: `
    <span class="paragraph">Aqui você descobre o que fazer e o que não fazer toda vez que estiver concebendo criativamente uma campanha</span>
    Fuja do lugar-comum! Histórias devem ser contadas de formas diversas, com respeito às pessoas e sem generalizações enganosas. A realidade é complexa.
    `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Gênero",
      slug: 'genero',
      hero: {
        name: "Gênero",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image01.png`,
        description: [
          "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
        ],
        columns: [
          {
            title: 'Você sabia?',
            text: 'A sociedade define papéis de gênero diferentes para homens e mulheres, no que diz respeito à profissão, à aparência, à postura, entre outros.',
          },
          {
            title: 'Agora sabe!',
            text: 'Não limite a imagem da mulher somente à aparência e ao cuidado. Também não reproduza a ideia de que todos os homens precisam ser viris, másculos e insensíveis.',
          },
          {
            title: 'O que fazer?',
            text: 'As campanhas podem valorizar mulheres em diferentes posições, como a liderança e a força, e revelar outras características masculinas, como a sensibilidade e o companheirismo.',
          },
        ]
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero.svg`,
        description: [
          "Diz respeito à forma como nos identificamos e devemos ser chamados. O uso adequado dos pronomes masculinos (ele/ dele), femininos (ela/dela) e neutros (elu/delu) é uma forma de reconhecimento social e afirmação de existência digna. Pessoas trans e não-binárias devem ser respeitadas."
        ],
      },
      prev: {
        name: "Ir para Classe",
        slug: "esteriotipos/classe",
      },
      next: {
        name: "Continuar a trilha",
        slug: "esteriotipos/raca",
      },
    },
    {
      index: 1,
      name: "Raça",
      slug: 'raca',
      hero: {
        name: "Raça",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image02.png`,
        description: [
          "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante."
        ],
        columns: [
          {
            title: 'Você sabia?',
            text: 'Representações superficiais das histórias de pessoas negras, indígenas e de origem asiática são uma forma de violência. O racismo invisibiliza, limita e/ou enclausura o outro',
          },
          {
            title: 'Agora sabe!',
            text: 'Sendo assim, não retrate pessoas negras de forma hiperssexualidada ou raivosa. Não demarque pessoas indígenas como preguiçosas ou inocentes. Tampouco crie a ideia de que pessoas de origem asiática são uma minoria modelo e sem defeitos.',
          },
          {
            title: 'O que fazer?',
            text: 'A publicidade deve recusar os estereótipos de raça e destacar a presença de pessoas negras, indígenas e de origem asiática em diferentes espaços, funções e relações.',
          },
        ]
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca.svg`,
        description: [
          "Tem a ver com o uso histórico de traços corporais, como cabelos crespos, cor de pele preta e determinados formatos de olhos e narizes, enquanto sinais de inferioridade. Por isso, a luta pela afirmação do orgulho negro e indígena é tão importante."
        ],
      },
      prev: {
        name: "Ir para Gênero",
        slug: "esteriotipos/genero",
      },
      next: {
        name: "Continuar a trilha",
        slug: "esteriotipos/lgbtqia",
      },
    },
    {
      index: 2,
      name: "LGBTQIA+",
      slug: 'lgbtqia',
      hero: {
        name: "LGBTQIA+",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image03.png`,
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
        columns: [
          {
            title: 'Você sabia?',
            text: 'Pessoas LGBTQIA+ se sentem menos representadas em campanhas. Isso acontece não apenas porque estão menos presentes, mas também porque fala-se sobre elas de modo caricaturado. <p><b>(Diversidade e Inclusão - Facebook, 2020)</b></p>',
          },
          {
            title: 'Agora sabe!',
            text: 'Por isso, não associe a identidade de homens gays <b class="tooltip-container" style="z-index: 3"><u>somente</u><span class="tooltip-text bg-yellow short">Há homens gays que aderem símbolos femininos, há homens gays que aderem a símbolos masculinos e há homens gays que rejeitam ambos os símbolos. Todas as expressões são válidas. O importante aqui é não limitar uma identidade plural a uma representação única do que é ser gay.</span></b> a símbolos femininos, tampouco a identidade de mulheres lésbicas <b class="tooltip-container" style="z-index: 2"><u>somente</u><span class="tooltip-text bg-yellow">Há mulheres lésbicas que aderem símbolos femininos, há mulheres lésbicas que aderem símbolos masculinos e há mulheres lésbicas que rejeitam ambos os símbolos. Todas as expressões são válidas. O importante aqui é não limitar uma identidade plural a uma representação única do que é ser lésbica.</span></b> a símbolos masculinos. Sexualidade e <b class="tooltip-container"><u>expressão de gênero</u><span class="tooltip-text bg-yellow">A forma como alguém manifesta publicamente a sua identidade de gênero, seja por meio do nome, da vestimenta, dos símbolos ou do comportamento. Todas as expressões de gênero são válidas independentemente da identidade de gênero da pessoa.</span></b> são temas distintos! <b>Da mesma forma, não trate pessoas trans e travestis como alívio cômico ou com exotização.</b>',
          },
          {
            title: 'O que fazer?',
            text: 'É papel da publicidade ampliar o leque de representações LGBTQIA+, sem apostar sempre em narrativas de sofrimento, sátira ou superação.',
          },
        ]
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia.svg`,
        description: [
          "A sigla guarda-chuva representa o movimento político e social que defende a diversidade de gêneros e sexualidades."
        ],
      },
      prev: {
        name: "Ir para Raça",
        slug: "esteriotipos/raca",
      },
      next: {
        name: "Continuar a trilha",
        slug: "esteriotipos/corpos-plurais",
      },
    },
    {
      index: 3,
      name: "Corpos Plurais",
      slug: 'corpos-plurais',
      hero: {
        name: "Corpos <br/> Plurais",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image04.png`,
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
        ],
        columns: [
          {
            title: 'Você sabia?',
            text: 'A presença majoritária de pessoas brancas e magras em campanhas (66%) afasta pessoas com deficiência, pessoas gordas e pessoas idosas. Não basta incluí-las, é preciso representá-las com dignidade. <p><b>(Diversidade e Inclusão - Facebook, 2020)</b></p>',
          },
          {
            title: 'Agora sabe!',
            text: 'Não reduza pessoas gordas apenas a situações de humor. Não trate pessoas idosas sempre como ranzinzas ou dependentes. Também não limite a história de PCDs apenas a casos de superação ou tristeza.',
          },
          {
            title: 'O que fazer?',
            text: 'Crie espaço para desmistificar tabus em torno desses corpos. Represente PCDs ocupando espaços variados, como o mercado de trabalho e os esportes; pessoas gordas como símbolo de beleza e segurança pessoal; e pessoas idosas como capazes e felizes.',
          },
        ]
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais.svg`,
        description: [
          "Nenhum corpo é igual ao outro e essa diferença deve ser valorizada e respeitada pelo mercado publicitário. Entenda quais formas de violência esses corpos podem sofrer para não reproduzi-las."
        ],
      },
      prev: {
        name: "Ir para LGBTQIA+",
        slug: "esteriotipos/lgbtqia",
      },
      next: {
        name: "Continuar a trilha",
        slug: "esteriotipos/classe",
      },
    },
    {
      index: 4,
      name: "Classe",
      slug: 'classe',
      hero: {
        name: "Classe",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image05.png`,
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos brasileiros."
        ],
        columns: [
          {
            title: 'Você sabia?',
            text: 'Padrões de consumo, desejo e comportamento podem atravessar as classes sociais. Muitas vezes, é o preconceito que cria fronteiras, fazendo com que as pessoas e seus gostos sejam associados exclusivamente à quantidade de dinheiro que possuem.',
          },
          {
            title: 'Agora sabe!',
            text: 'Membros da classe C não devem ser vistos apenas como desejosos de riqueza ou como pessoas com estilos visuais duvidosos, enquanto membros de classe mais alta são heróis, bondosos e respeitáveis.',
          },
          {
            title: 'O que fazer?',
            text: 'Aposte nas histórias de pessoas reais, desfazendo a suposição de que existem lugares que uma classe não pode frequentar ou formas de se comportar nesses lugares que são mais adequadas.',
          },
        ]
      },
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe.svg`,
        description: [
          "A desigualdade econômica implica em desigualdade de oportunidades e afeta a qualidade de vida da maioria dos latino-americanos."
        ],
      },
      prev: {
        name: "Ir para Corpos Plurais",
        slug: "esteriotipos/corpos-plurais",
      },
      next: {
        name: "Continuar a trilha",
        slug: "rotina",
      },
    },
  ]
};

let routine = {
  color: '#3594C9',
  slug: 'rotina',
  title: "Rotina",
  description: `
    <span class="paragraph">Aqui você entende a importância de alinhar o que os latinoamericanos esperam das companhias com a sua rotina de trabalho</span>
    Criar uma publicidade mais inclusiva e diversa exige um exercício constante de análise. Olhar para os processos do dia a dia em relação ao que o público espera é uma ótima forma de começar.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Padrão Global",
      slug: 'padrao-global',
      hero: {
        name: "Padrão <br /> Global",
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        title: 'Carência de Identificação',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '86%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '81%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '79%',
            country: 'Colômbia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '78%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '76%',
            country: 'Brasil',
            flag: '🇧🇷',
          }
        ],
        legend: '(Diversidade e Inclusão - Facebook, 2020)',
        columns: [
          [
            'A diversidade presente nas campanhas deve estar alinhada com as realidades nacionais a fim de gerar identificação e demonstrar compromisso.',
            '<b>Mas as pessoas latino-americanas não sentem que isso acontece.</b>',
            '<b class="colorfull">Algo precisa ser feito.</b>',
          ],
          [
            '<b>Um dos caminhos é criar oportunidades para que a adaptação de campanhas globais alcance melhores resultados.</b> Isso começa com perguntas sobre a não-identificação.',
            'Os latino-americanos não se enxergam na publicidade <b>porque ela reproduz um padrão de diversidade global estereotipado,</b> com pouco apelo e reflexo em cada um dos países citados?',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        description: [],
      },
      prev: {
        name: "Ir para Negócio",
        slug: "rotina/negocio",
      },
      next: {
        name: "Continuar a trilha",
        slug: "rotina/fornecedores",
      },
    },
    {
      index: 1,
      name: "Fornecedores",
      slug: 'fornecedores',
      hero: {
        name: "Fornece- <br/> dores",
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        title: 'Grau de Otimismo',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '61%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '55%',
            country: 'Colômbia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '53%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '52%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#78B8DA',
            percent: '51%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversidade e Inclusão - Facebook, 2020)',
        columns: [
          [
            'Há otimismo entre as pessoas latino-americanas. Espera-se que a publicidade seja mais inclusiva e diversa nos próximos 5 anos.',
            'Para construir hoje o que as pessoas esperam ver amanhã, <b>deve-se criar e contratar times plurais capazes de fazer com que as expectativas se tornem realidade.</b>',
          ],
          [
            'Tire um tempo para pensar: você leva em conta a diversidade de gênero, raça, sexualidade e região quando realiza a contratação ou a subcontratação de agências?',
            '<b>Você conta com uma lista ampla de fornecedores plurais, ou repete sempre os mesmos? <span class="colorfull">Há diversidade entre os líderes dos times com os quais trabalha?</span></b>',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        description: [],
      },
      prev: {
        name: "Ir para Padrão Global",
        slug: "rotina/padrao-global",
      },
      next: {
        name: "Continuar a trilha",
        slug: "rotina/influenciadores",
      },
    },
    {
      index: 2,
      name: "Influenciadores",
      slug: 'influenciadores',
      hero: {
        name: "Influen-<br/>ciadores",
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        title: 'Falta de Representatividade',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '94%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '85%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#3C73A7',
            percent: '84%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#5093C4',
            percent: '81%',
            country: 'Colômbia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '80%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversidade e Inclusão - Facebook, 2020)',
        columns: [
          [
            'O mundo está se transformando rapidamente.',
            '<b>A Geração Z é a que menos acredita que a publicidade representa a pluralidade latino-americana.</b> Cada vez mais, o marketing inclusivo será necessário.',
            '<b>Dele depende o sucesso da comunicação nas plataformas sociais que permitem maior segmentação de público</b> e, ao mesmo tempo,  amplificam e pluralizam vozes antes sem espaço.',
          ],
          [
            '<b>A sua estratégia de contratação tem priorizado influenciadores que representam a diversidade do público? <span class="colorfull">Quais histórias que eles contam que interessam à sua marca?</span></b>',
            '<b>Essas agregam independente do número de seguidores?</b>',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        description: [],
      },
      prev: {
        name: "Ir para Fornecedores",
        slug: "rotina/fornecedores",
      },
      next: {
        name: "Continuar a trilha",
        slug: "rotina/comunicacao",
      },
    },
    {
      index: 3,
      name: "Comunicação",
      slug: 'comunicacao',
      hero: {
        name: "Comuni-<br/>cação",
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        title: 'Índice de Satisfação',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '36%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#2B578C',
            percent: '33%',
            country: 'Colômbia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '32%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '26%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '21%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#97D4EB',
            percent: '21%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversidade e Inclusão - Facebook, 2020)',
        columns: [
          [
            'São baixas as porcentagens das pessoas latino-americanas que estão satisfeitas com o desempenho da empresa para a qual trabalham no que se refere à diversidade e inclusão.',
            '<b>A mudança é necessária, e ela requer que todas as pessoas sejam ativas nesse processo.</b>',
            'É preciso promover debates e propagar boas práticas, com responsabilidade e sem receio de indisposições.',
          ],
          [
            '<b class="colorfull">Conversas difíceis não precisam ser desrespeitosas!</b>',
            'Pode não ser fácil dialogar sobre temas como raça, gênero e sexualidade no espaço de trabalho, mas é preciso! O debate é parte essencial da mudança e deve ser valorizado.',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        description: [],
      },
      prev: {
        name: "Ir para Influen- ciadores",
        slug: "rotina/influenciadores",
      },
      next: {
        name: "Continuar a trilha",
        slug: "rotina/negocio",
      },
    },
    {
      index: 4,
      name: "Negócio",
      slug: 'negocio',
      hero: {
        name: "Negócio",
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
        title: 'Critério para Aquisição',
        list: [
          {
            color: '#1D3E70',
            percent: '64%',
            country: 'Colômbia',
            flag: '🇨🇴',
          },
          {
            color: '#2B578C',
            percent: '60%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '59%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '55%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '50%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversidade e Inclusão - Facebook, 2020)',
        columns: [
          [
            'A maioria das pessoas latino-americanas acredita que o compromisso das marcas com a diversidade <b>é essencial para a sua decisão de compra.</b>',
            '<b>Ou seja, ignorar a temática pode significar perder a conexão com os consumidores.</b> As prioridades do público estão mudando, mas ainda não é',
          ],
          [
            'hora de entender a diversidade e a inclusão como metas de toda a cadeia produtiva?',
            '<b>Tempo para diversidade é tempo dedicado ao desenvolvimento de negócio.</b> Agora é a hora de criar uma rotina de trabalho em que a inclusão não seja secundária, mas sim estratégica.',
            '<b class="colorfull">Dedique-se ao que importa para os consumidores.</b>',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
      },
      prev: {
        name: "Ir para Comunicação",
        slug: "rotina/comunicacao",
      },
      next: {
        name: "Continuar a trilha",
        slug: "boas-praticas",
      },
    },
  ]
};

let practices = {
  color: '#4CBF85',
  slug: 'boas-praticas',
  title: "Boas Práticas",
  description: `
    <span class="paragraph">Conhecer erros, antecipar riscos e identificar oportunidades exige que se olhe de novo e de novo para as campanhas e suas mensagens. Fazer isso quando se está lidando com regiões e países diferentes é ainda mais urgente. Aqui estão boas práticas para campanhas inclusivas e inovadoras.</span>
    Aqui você entende a importância de trazer a América Latina real para dentro de cada mensagem das marcas da companhia
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Contexto",
      slug: 'contexto',
      hero: {
        name: "Contexto",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
        columns: [
          [
            '<b>Fazer com que as mensagens das marcas se adequem ao tempo presente requer inovação.</b>',
            'Represente formas de consumo menos usuais e amplie a diversidade dentro de contextos como festas, encontros entre amigos, jantares de família, entre outros.',
            '<span><b>Ao falar sobre o Natal, por exemplo, você pode escolher uma família liderada por mães solo e seus filhos. Ao retratar bares ou lugares tipicamente ligados a homens cis, você pode trazer mulheres cis como donas do local.</b></span>',
          ],
          [
            'Já em cenários esportivos, mulheres trans negras podem ser as protagonistas. Assim como em formaturas, pessoas indígenas podem receber o diploma.',
            'Como nossa sociedade está em constante mudança, é preciso se perguntar o tempo todo como representar pessoas de carne e osso: de que modo se comportam, do que gostam, como se relacionam?',
            '<b>Se você ficar desatualizado, a sua marca pode ficar para trás, apostando em cenas que perderam apelo!</b>',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
      },
      prev: {
        name: "Ir para Liderança",
        slug: "boas-praticas/lideranca",
      },
      next: {
        name: "Continuar a trilha",
        slug: "boas-praticas/local",
      },
    },
    {
      index: 1,
      name: "Local",
      slug: 'local',
      hero: {
        name: "Local",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
        columns: [
          [
            '<b>Campanhas não precisam falar sobre países repetindo sempre os mesmos símbolos e lugares.</b>',
            'Por que limitar culturas? A América Latina não é somente Brasil. O Brasil não é somente o Rio de Janeiro ou o Cristo Redentor. A Colômbia não é apenas Bogotá nem as praias de sua região caribenha. E o Peru definitivamente não é só Machu Picchu.',
            '<span><b>A representação dos países deve levar em conta as diversidades regionais. Do norte ao sul da América Latina, temos diferentes tipos de belezas e experiências.</b></span>',
          ],
          [
            'Os verões na Argentina são diferentes nos parques de Córdoba e nos Lagos da Patagônia. No Brasil, os verões têm sim a praia de Copacabana, mas também têm os rios de Belém e as cachoeiras da Chapada Diamantina. Em cada canto, pessoas magras, saradas, gordas, com deficiências e de todas as cores e características aproveitam o sol, a piscina, a laje.',
            '<b>Os verões são muitos e de todos. Valorize a América Latina real!</b>',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
      },
      prev: {
        name: "Ir para Contexto",
        slug: "boas-praticas/contexto",
      },
      next: {
        name: "Continuar a trilha",
        slug: "boas-praticas/apropriacao-cultural",
      },
    },
    {
      index: 2,
      name: "Apropriação Cultural",
      slug: 'apropriacao-cultural',
      hero: {
        name: "Apropriação <br/> Cultural",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
        columns: [
          [
            '<b>Em toda a América Latina, existem tradições culturais que devem ser enaltecidas por aquilo que são.</b>',
            'Os povos indígenas, por exemplo, são inúmeros e não devem ser retratados como se fossem um bloco homogêneo.',
            'Devemos ter cuidado com representações rasas de culturas milenares.',
            'Como acontece quando vestimentas que têm valor ritual e religioso para um grupo são utilizadas por pessoas e corpos não-indígenas. Elementos de uma cultura não podem ser tirados de um contexto e simplesmente colocados em outro.',
            '<span><b>O nome disso é <div class="tooltip-container">apropriação cultural<div class="tooltip-text bg-black">Consiste na utilização de símbolos ou valores de culturas sub-representadas de forma leviana e rasa, o que causa banalização e violência. Marcas não devem se apossar da história de povos para simplesmente vender produtos ou atingir seus objetivos.</div></div>, e seu impacto pode ser negativo para marcas e companhias.</b></span>',
          ],
          [
            'De que forma os povos e seus símbolos estão sendo representados nas suas campanhas?',
            '<b>Lembre que pessoas indígenas vivem em aldeias, mas também vivem nas cidades, também dirigem carros, estudam em faculdades, trabalham no Congresso Nacional.</b>',
            'Na dúvida, contrate pessoas indígenas, converse com especialistas e/ou estude sobre as discussões indígenas antirracistas.',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
      },
      prev: {
        name: "Ir para Local",
        slug: "boas-praticas/local",
      },
      next: {
        name: "Continuar a trilha",
        slug: "boas-praticas/descontextualizacao",
      },
    },
    {
      index: 3,
      name: "Descontextualização",
      slug: 'descontextualizacao',
      hero: {
        name: "Descontex- tualização",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
        columns: [
          [
            '<b>Os símbolos perdem força de identificação quando são movidos de uma realidade nacional para a outra.</b>',
            'Por exemplo, se, numa campanha para o público jovem brasileiro, a temática for embasada nas high schools norte-americanas, o público alvo terá dificuldades em se identificar com o conteúdo.',
            '<span><b>Se a intenção é comunicar ao público latino-americano, por que reproduzir o que funciona nos Estados Unidos?</b></span>',
            'Num processo criativo estratégico, leva-se em conta o público alvo desde o início, conhecendo-o e valorizando as suas particularidades para gerar identificação.',
          ],
          [
            '<b>Criar uma comunicação que efetivamente dialoga com o outro exige verdade, isto é, demanda mensagens que se respaldam em fatos concretos.</b>',
            'A publicidade estratégica não deveria se basear em suposições de profissionais de um país sobre aquilo que o público de outro deseja.',
            '<b>Publicidade aspiracional é mera idealização e não realidade.</b> Pesquisa e dados, sim, trazem segurança sobre os contextos nacionais e os desejos de cada target.',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
      },
      prev: {
        name: "Ir para Apropriação Cultural",
        slug: "boas-praticas/apropriacao-cultural",
      },
      next: {
        name: "Continuar a trilha",
        slug: "boas-praticas/lideranca",
      },
    },
    {
      index: 4,
      name: "Liderança",
      slug: 'lideranca',
      hero: {
        name: "Liderança",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
        columns: [
          [
            '<b>Para cada público alvo, o líder deve ser capaz de avaliar se a mensagem de uma campanha contempla a realidade endereçada.</b>',
            'Já pensou nisso? Se sim, como as suas campanhas são validadas? Quais critérios são utilizados? Eles são apropriados? Quem são as pessoas envolvidas nesse processo de certificação de uma campanha diversa e inclusiva?',
            '<span><b>O compromisso com a validação é importante porque nem toda ideia que parece boa produz impacto positivo, seja no público, seja na vendas.</b></span>',
          ],
          [
            'Acompanhe então os impactos gerados por campanhas tanto internamente, a título de negócios, quanto externamente, a respeito da adesão de pessoas.',
            '<b>Quais são as métricas e os indicadores de diversidade utilizados para avaliar resultados?</b>',
            'Eles permitem que a companhia saiba em que solo está pisando enquanto o mundo muda? Permitem que o alcance de sua campanha seja aferido, considerando gênero, sexualidade, classe e raça?',
            'Líderes e gestores que promovem uma cultura organizacional preocupada com diversidade, validação e resultados têm mais chance de serem inovadores e inclusivos.',
          ],
        ]
      },
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
      },
      prev: {
        name: "Ir para Descontex- tualização",
        slug: "boas-praticas/descontextualizacao",
      },
      next: {
        name: "Continuar a trilha",
        slug: "diversidade",
      },
    },
  ]
};

export function getDiversityPT() {
  return diversity
}
export function getRepresentsPT() {
  return represents
}
export function getStereotypesPT() {
  return stereotypes
}
export function getRoutinePT() {
  return routine
}
export function getPracticesPT() {
  return practices
}
