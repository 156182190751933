import styled from 'styled-components'
import { colors } from '../../../../globalStyles';

export const Nav = styled.nav`
  display: flex;
  gap: 16px;
  @media screen and (max-width: 1000px) {
    overflow-y: scroll;
    width: 100%;
    &::-webkit-scrollbar { 
      display: none;
    }
  }
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 24px;  
  width: 33%;
  max-width: 284px;
  border-radius: 12px;
  figure {
    margin: 0 0 20px;
    padding: 0;
    height: clamp(8.125rem, -5.625rem + 17.1875vw, 15rem);; // 130 - 240 > 1280 - 1920
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    width: 240px;
    min-width: 240px;
  }
`;
export const Graph = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const Title = styled.h2`
  font-family: 'TCCC-UnityHeadline-Bold';   
  font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18 > 1280 - 1920
  font-weight: 700;
  margin: 0 0 16px;
  color: ${props => props.color ? props.color : colors.black};
`;
export const Description = styled.div`
`;
export const Text = styled.p`
  font-size: clamp(0.75rem, 0.5rem + 0.3125vw, 0.875rem); // 12 - 14 > 1280 - 1920
  font-weight: 400;
  margin: 0 0 20px;
  color: ${props => props.color ? props.color : colors.black};
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'TCCC-UnityHeadline-Bold';
  font-size: 14px;
  font-weight: 700;
  color: ${colors.black};
  &[disabled] {
    display: none;
  }
  img {
    display: block;
    margin-left: 10px;
  }
`;