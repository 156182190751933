import React, { useState } from 'react'
import * as S from './styled'
import { LGBTQIA } from '../../../../json/'

function Carousel({ data }) {
  const [active, setActive] = useState(0)
  const [tooltip, setTooltip] = useState(true)

  const list = LGBTQIA()

  const onClickDot = (id) => {
    setActive(id)
    setTooltip(false)
  }

  return(
    <S.Carousel>
      <S.Slider className='slider'>
        <S.Item className={`${active === 0 ? 'active' : ''}`}>
          <S.TagContent>
            <div className='content'>
              {list[0].tags.map((item, index) => (
                <div className='tag' key={index}>
                  <img src={item.img} alt={item.name} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
            <h2>{list[0].text}</h2>
          </S.TagContent>
        </S.Item>

        <S.Item className={`${active === 1 ? 'active' : ''}`}>
          <S.CardContent>
            <div className='content'>
              <div className='text'>
                <div>
                  <h2>{list[1].title}</h2>
                  <p>{list[1].subtitle}</p>
                </div>
                <img src={`${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-orientacao.png`} alt="" />
              </div>
              <ul className='cards'>
                {list[1].cards.map((item, index) => (
                  <li key={index}>
                    <figure>
                      <img src={item.img} alt={item.name} />
                    </figure>
                    <h4 dangerouslySetInnerHTML={{__html: item.name}} />
                    <p>{item.text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <h3 dangerouslySetInnerHTML={{__html: list[1].text}} />
          </S.CardContent>
        </S.Item>

        <S.Item className={`${active === 2 ? 'active' : ''}`}>
          <S.RowContent>
            <div className='content'>
              <div>
                <h2>{list[2].title}</h2>
                <p>{list[2].subtitle}</p>
              </div>
              <div className='box'>
                <h2>{list[2].text}</h2>
                <ul>
                  {list[2].box.map((item, index) => (
                    <li>
                      <h3>{item.year}</h3>
                      <h4>{item.country}</h4>
                      <p className="flag">{item.flag}</p>
                      <p>{item.law}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <h3 className='description' dangerouslySetInnerHTML={{__html: list[2].description}} />
          </S.RowContent>
        </S.Item>
      </S.Slider>

      <S.Dots className='dots'>
        {data.carousel.map((item, index) => (
          <S.Dot onClick={() => onClickDot(index)} className={`${active === index ? 'active' : ''}`} color={data.color}>
            {index + 1}
          </S.Dot>
        ))}
        <S.Tooltip color={data.color} style={{opacity: tooltip ? '1' : '0'}}>
          {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
            <span>Clique para navegar entre conteúdos</span>
          : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
            <span>Click to browse content</span>
          : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
            <span>Haga clic para explorar el contenido</span>
          : null}
        </S.Tooltip>
      </S.Dots>
    </S.Carousel>
  )
}
export default Carousel