let LGBTQIA = [
  {
    text: "Por trás dessas letras, existem vidas, sonhos e projetos.",
    tags: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-lesbica.svg`, name: 'lésbicas'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-gays.svg`, name: 'gays'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-bissexuais.svg`, name: 'bissexuais'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-transgeneros.svg`, name: 'transgêneros & travestis'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-queers.svg`, name: 'queers'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-interssexuais.svg`, name: 'interssexuais'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-assexuais.svg`, name: 'assexuais'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-outros.svg`, name: '+outros'},
    ]
  },
  {
    title: "Orientação Sexual",
    subtitle: "É o que indica a atração física e/ou emocional de uma pessoa por outra.",
    text: "Não há quem possa dizer como amar. <br/>Relações são para serem vividas livres de amarras.",
    cards: [
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-assexual.svg`, name: 'Assexual', text:'Não sente atração sexual'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-bissexual.svg`, name: 'Bissexual', text:'Sente atração por dois ou mais gêneros'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-pansexual.svg`, name: `<span class="tooltip-container">Pansexual <span class="tooltip-text bg-orange tooltip-top">Com o passar do tempo, a diferença entre bissexual e pansexual diminuiu. Atualmente, ambas as identidades contemplam pessoas que se atraem por mais de um gênero e/ou independentemente do gênero. A preferência por uma ou outra classificação depende de cada pessoa.</span></span>`, text:'Sente atração independente do gênero'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-heterossexual.svg`, name: 'Héterossexual', text:'Sente atração pelo gênero oposto'},
      {img: `${process.env.PUBLIC_URL}/assets/diversidade/lgbtqia-card-homossexual.svg`, name: 'Homossexual', text:'Sente atração pelo mesmo gênero'}
    ]
  },
  {
    title: "Homofobia e Transfobia",
    subtitle: 'São formas de preconceito e dismicriminação contra homossexuais, lésbicas, bissexuais, trans e travestis. No dia a dia, se manifestam como sentimento de aversão, exclusão na publicidade e até mesmo disfarçadas de "opiniões" e “piadas”.',
    text: "Algumas leis de antidiscriminação",
    description: "LGTfobia vai além da violência física. <br/>As palavras também podem ferir.",
    box: [
      {year: '2003', country: 'México', flag: '🇲🇽', law: 'Lei Federal para Prevenir e Erradicar a Discriminação'},
      {year: '2011', country: 'Colômbia', flag: '🇨🇴', law: 'Lei 1.482'},
      {year: '2012', country: 'Argentina', flag: '🇦🇷', law: 'Lei 26.791'},
      {year: '2012', country: 'Chile', flag: '🇨🇱', law: 'Lei Zamudio'},
      {year: '2017', country: 'Peru', flag: '🇵🇪', law: 'Decreto Legislativo 1.323'},
      {year: '2019', country: 'Brasil', flag: '🇧🇷', law: 'Lei 7.716/1989'}
    ]
  }
]
export function getLGBTQIAPT() {
  return LGBTQIA
}