import { createGlobalStyle } from 'styled-components'

import TCCCUnityCondensedBold from './assets/fonts/Condensed/TTF/TCCC-UnityCondensed-Bold.ttf';
import TCCCUnityCondensed from './assets/fonts/Condensed/TTF/TCCC-UnityCondensed-Medium.ttf';

import TCCCUnityHeadlineBlack from './assets/fonts/Headline/TTF/TCCC-UnityHeadline-Black.ttf';
import TCCCUnityHeadlineBold from './assets/fonts/Headline/TTF/TCCC-UnityHeadline-Bold.ttf';
import TCCCUnityHeadlineLight from './assets/fonts/Headline/TTF/TCCC-UnityHeadline-Light.ttf';
import TCCCUnityHeadlineMedium from './assets/fonts/Headline/TTF/TCCC-UnityHeadline-Medium.ttf';
import TCCCUnityHeadlineRegular from './assets/fonts/Headline/TTF/TCCC-UnityHeadline-Regular.ttf';

import TCCCUnityTextBlack from './assets/fonts/Text/TTF/TCCC-UnityText-Black.ttf';
import TCCCUnityTextBold from './assets/fonts/Text/TTF/TCCC-UnityText-Bold.ttf';
import TCCCUnityTextLight from './assets/fonts/Text/TTF/TCCC-UnityText-Light.ttf';
import TCCCUnityTextMedium from './assets/fonts/Text/TTF/TCCC-UnityText-Medium.ttf';
import TCCCUnityTextRegular from './assets/fonts/Text/TTF/TCCC-UnityText-Regular.ttf';

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#E54A5A',
  blue: '#3594C9',
  green: '#94C15B',
  yellow: '#F0AB1B',
  turquoise: '#4CBF85',
  orange: '#EA6833',
  darkGray: '#363636',
  lightGray: '#EDEDED',
  gray: '#C4C4C4',
}
 
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'TCCC-UnityCondensed-Bold';
    src: url(${TCCCUnityCondensedBold});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityCondensed-Medium';
    src: url(${TCCCUnityCondensed});
    font-style: normal;
  }

  @font-face {
    font-family: 'TCCC-UnityHeadline-Black';
    src: url(${TCCCUnityHeadlineBlack});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityHeadline-Bold';
    src: url(${TCCCUnityHeadlineBold});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityHeadline-Light';
    src: url(${TCCCUnityHeadlineLight});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityHeadline-Medium';
    src: url(${TCCCUnityHeadlineMedium});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityHeadline-Regular';
    src: url(${TCCCUnityHeadlineRegular});
    font-style: normal;
  }



  @font-face {
    font-family: 'TCCC-UnityText-Black';
    src: url(${TCCCUnityTextBlack});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityText-Bold';
    src: url(${TCCCUnityTextBold});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityText-Light';
    src: url(${TCCCUnityTextLight});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityText-Medium';
    src: url(${TCCCUnityTextMedium});
    font-style: normal;
  }
  @font-face {
    font-family: 'TCCC-UnityText-Regular';
    src: url(${TCCCUnityTextRegular});
    font-style: normal;
  }

  .tooltip-container {
    display: inline;
    position: relative;
    transition: opacity .4s ease;
    cursor: pointer;
    border-bottom: 2px dashed;
    z-index: 1;
    .tooltip-text {
      z-index: 2;
      text-transform: none;
      text-align: left;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 150%;
      min-width: 210px;
      right: 0;
      background-color: white;
      display: block;
      padding: 10px 12px;
      border-radius: 8px;
      font-size: 10px;
      font-family: 'TCCC-UnityHeadline-Regular';
      color: ${colors.black};
      @media screen and (max-width: 1000px) {
        font-size: 9px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        right: 30px;
        top: -4px;
        transform: rotate(45deg);
        background-color: ${colors.white};
      }
      &.short {
        @media screen and (max-width: 1000px) {
          left: 0;
          &:before {
            left: 30px;
          }
        }
      }
      &.tooltip-top {
        top: auto;
        bottom: 150%;
        &:before {
          top: auto;
          bottom: -4px
        }
      }
      &.bg-black {
        background-color: ${colors.black};
        color: ${colors.white} !important;
        &:before {
          background-color: ${colors.black};
        }
      }
      &.bg-red {
        background-color: ${colors.red};
        color: ${colors.white};
        &:before {
          background-color: ${colors.red};
        }
      }
      &.bg-orange {
        background-color: ${colors.orange};
        color: ${colors.white};
        &:before {
          background-color: ${colors.orange};
        }
      }
      &.bg-yellow {
        background-color: ${colors.yellow};
        &:before {
          background-color: ${colors.yellow};
        }
      }
      &.bg-green {
        background-color: ${colors.green};
        &:before {
          background-color: ${colors.green};
        }
      }
    }
    &:hover {
      .tooltip-text {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  html {
    position: relative;
    body {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      font-family: 'TCCC-UnityHeadline-Regular', sans-serif;
      position: relative;
      width: 100vw;
      overflow-x: hidden;
      color: ${colors.black};

      -webkit-font-smoothing: antialiased;
      -moz-font-smoothing: antialiased;
      -o-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      ul,
      li,
      a,
      button,
      input,
      textarea {
        color: inherit;
        margin: 0;
        padding: 0;
      }
      ul,
      li {
        list-style: none;
      }
      b {
        font-family: 'TCCC-UnityHeadline-Bold';
      }
      a {
        text-decoration: none;
      }
      button {
        appearance: button;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        text-transform: none;
      }
      input,
      textarea {
        background: transparent;
        border: none;
        outline: none;
        resize: none;
      }
    }
  }
`;

export default GlobalStyle