import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem) 40px;
  @media screen and (max-width: 1000px) {
    padding: 0 0 40px;
  }
`
export const CardsContainer = styled.section`
  width: 100%;
  h3 {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto 30px;
    max-width: 1400px;
  }
  nav {
    margin: 0 auto;
    max-width: 1400px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0;
    h3 {
      padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    }
    nav {
      padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    }
  }
`