import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../containers/Layout'
import * as S from './styled'
import { intro } from '../../json'

import AOS from 'aos'
import 'aos/dist/aos.css'

function Home() {
  const [open, setOpen] = useState(false)
  const data = intro()

  const onOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    AOS.init({
      duration : 1000,
      once: true
    });
  }, []);

  return (
    <Layout modal={true} intro={true} bg={'#E54A5A'} texture={'red'}>
      <S.Modal>
        {open && <S.Container open={open} className="list">
          <h1>
            <img src={`${process.env.PUBLIC_URL}/assets/logo-white.svg`} alt="Guia da diversidade" />
          </h1>
          <h2 dangerouslySetInnerHTML={{__html: data.title}} />
          <ul>
            {data.list.map((item, index) => (
              <li 
                key={index} 
                data-aos="fade-up"
                data-aos-delay={100 * index}
              >
                <h3>{index + 1}</h3>
                <p><b>{item.title}</b></p>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          <Link to={'/trilhas'} className="menuButton">{data.nav}</Link>
        </S.Container>}

        <S.Container open={!open}>
          <div className='logo-coca'>
            <img src={`${process.env.PUBLIC_URL}/assets/logo-coca-white.svg`} alt="Coca cola company" />
          </div>
          <h1>
            <img src={data.logo} alt="Guia da diversidade" />
          </h1>
          <button onClick={onOpen}>
            <img src={`${process.env.PUBLIC_URL}/assets/clique-aqui.svg`} alt="clique para avançar" />
            <p>{data.button}</p>
          </button>
        </S.Container>  
      </S.Modal>
    </Layout>
  )
}

export default Home