import React from 'react'
import * as S from './styled'

function Cards({ data }) {
  return (
    <S.Nav>
      {data.map((item, index) => (
        <S.Content key={index}>
          <figure>
            <S.Graph src={item.image} alt={item.name} />
          </figure>
          <S.Title color={item.color} dangerouslySetInnerHTML={{__html: item.name}} />
          {item.description && item.description.length > 0 && <S.Description>
            {item.description.map((paragraph, index) => (
              <S.Text key={index} color={item.color} dangerouslySetInnerHTML={{__html: paragraph}} />
            ))}
          </S.Description>}
        </S.Content>
      ))}
    </S.Nav>
  )
}
export default Cards