import styled from 'styled-components'
import { colors } from '../../globalStyles'

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`
export const Column = styled.div`
  width: 48%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
export const Paragraph = styled.p`
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14, 16 > 1280 - 1920
  line-height: 1.6;
  margin: 0 0 clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem); // 10 - 20 > 1280 - 1920
  span {
    display: block;
    background-color: ${colors.turquoise};
    color: ${colors.white};
    padding: clamp(1rem, 0.25rem + 0.9375vw, 1.375rem) clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem); // 16 - 22 | 18 - 24
    border-radius: 8px;
  }
  img {
    display: block;
    width: 100%;
    background-color: gray;
    object-fit: cover;
  }
`