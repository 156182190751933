import styled from 'styled-components'
import { colors } from '../../../../globalStyles';

export const Carousel = styled.div`
  max-width: 100%;
  position: relative;
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    height: auto;
    justify-content: flex-start;
  }
`
export const Slider = styled.ul`
  margin: 0 0 clamp(1.375rem, -3.625rem + 6.25vw, 3.875rem); // 22 - 62 > 1280 - 1920
  height: 100%;
`
export const Item = styled.li`
  background-color: ${props => props.bg ? props.bg : 'transparent'};
  display: none;
  padding: ${props => props.bg ? '42px' : 0 } ${props => props.bg ? '56px' : 0 };
  border-radius: 8px;
  height: 100%;
  &.active {
    display: block;
  }
`
export const TagContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: clamp(1.375rem, 0.875rem + 2.5vw, 3.875rem);
  .content {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: clamp(0.5rem, 0.275rem + 1.125vw, 1.625rem); // 8 - 26
    @media screen and (max-width: 1000px) {
      height: 300px;
    }
    @media screen and (max-width: 700px) {
      height: 340px;
    }
    .tag {
      display: flex;
      font-size: clamp(0.875rem, 0.8rem + 0.375vw, 1.25rem); // 14 - 20
      padding: clamp(0.75rem, 0.7rem + 0.25vw, 1rem) clamp(1rem, 0.9rem + 0.5vw, 1.5rem); // 12 - 16 | 16 - 24
      border-radius: 10px;
      background-color: #fff;
      align-items: center;
      p {
        max-width: 150px;
        margin-left: 20px;
        font-family: TCCC-UnityHeadline-Black;
      }
      @media screen and (max-width: 700px) {
        align-items: center;
        justify-content: center;
        height: 60px;
        padding: 8px 10px;
        img {
          max-height: 40px;
        }
      }
    }
  }
  h2 {
    font-size: clamp(1.125rem, 1rem + 0.625vw, 1.75rem); // 18 - 28
    font-family: TCCC-UnityHeadline-Black;
    margin: 0 0 20px; // 10 - 20 > 1280 - 1920
  }
`
export const CardContent = styled.div`
  max-width: 100%;
  .content {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin: 0 0 20px;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      height: 300px;
      justify-content: space-between;
    }
    @media screen and (max-width: 700px) {
      height: 340px;
    }
    h2 {
      font-size: 24px;
      font-size: clamp(1.125rem, 0.375rem + 0.9375vw, 1.5rem); // 18 - 24  > 1280 - 1920
      margin: 0 0 10px;
    }
    p {
      font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem); // 14 - 16 > 1280 - 1920
    }
    h4 {
      text-transform: uppercase;
    }
    h2, h4 {
      font-family: TCCC-UnityHeadline-Bold;
    }
    .text {
      max-width: clamp(9.375rem, -4.375rem + 17.1875vw, 16.25rem); // 150 - 260 > 1280 - 1920
      img {
        display: block;
        width: 65%;
        @media screen and (max-width: 1300px) {
          max-width: 120px;
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      p {
        margin: 0 0 20px;
      }
      @media screen and (max-width: 1000px) {
        max-width: 100%;
        img {
          width: auto;
          max-height: 130px;
        }
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      gap: clamp(0.375rem, -0.375rem + 0.9375vw, 0.75rem); // 6 - 12 > 1280 - 1920
      @media screen and (max-width: 1000px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: unset;
        width: 100%;
        &::-webkit-scrollbar {
          width: 0;
          display: none;
        }
      }
      li {
        width: clamp(8.125rem, 3.125rem + 6.25vw, 10.625rem); // 130 - 170 > 1280 - 1920
        min-width: clamp(8.125rem, 3.125rem + 6.25vw, 10.625rem); // 130 - 170 > 1280 - 1920
        text-align: center;
        background-color: #fff;
        padding: 16px clamp(1rem, 0rem + 1.25vw, 1.5rem);
        border-radius: 10px;
        p, h4 {
          font-size: clamp(0.625rem, 0.125rem + 0.625vw, 0.875rem); // 10 - 14 > 1280 - 1920
        }
        figure {
          margin: 0 0 20px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 70px;
          @media screen and (max-width: 1300px) {
            height: 50px;
          }
          img {
            display: block;
            width: 100%;
            max-height: 70px;
            @media screen and (max-width: 1300px) {
              max-width: 50px;
            }
          }
        }
      }
    }
  }
  h3 {
    font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18 > 1280 - 1920
    font-family: TCCC-UnityHeadline-Bold;
    margin: 0 0 20px // 10 - 20 > 1280 - 1920
  }
`
export const RowContent = styled.div`
  h2 {
    font-family: TCCC-UnityText-Black;
    font-size: clamp(1rem, 0.5rem + 0.625vw, 1.25rem); // 16 - 20 > 1280 - 1920
    margin: 0 0 clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem); // 10 - 20 > 1280 - 1920
  }
  p {
    font-size: clamp(0.75rem, 0.25rem + 0.625vw, 1rem); // 12 - 16 > 1280 - 1920
  }
  .description {
    font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem); // 16 - 18 > 1280 - 1920
    font-family: TCCC-UnityHeadline-Bold;
    margin: 0 0 20px // 10 - 20 > 1280 - 1920
  }
  .content {
    margin: 0 0 20px;
    @media screen and (max-width: 1000px) {
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media screen and (max-width: 700px) {
      height: 340px;
    }
  }
  .box {
    padding: 20px clamp(1.25rem, -1.25rem + 3.125vw, 2.5rem); // 20 - 40 > 1280 - 1920
    border-radius: 10px;
    margin: 20px 0 0; // 20 - 40 > 1280 - 1920
    background-color: #fff;
    ul {
      display: flex;
      gap: clamp(0.75rem, 0.4rem + 1.75vw, 2.5rem); // 12 - 40
      li {
        width: clamp(5rem, 1.875rem + 3.9063vw, 6.5625rem); // 80 - 105 > 1280 - 1920
      }
      h3 {
        text-align: center;
        font-size: clamp(1.75rem, -0.75rem + 3.125vw, 3rem); // 28 - 48 > 1280 - 1920
        font-family: TCCC-UnityCondensed-Bold;
        @media screen and (max-width: 1000px) {
          font-size: 24px
        }
      }
      h4 {
        text-align: center;
        font-size: clamp(0.75rem, 0rem + 0.9375vw, 1.125rem); // 12 - 18 > 1280 - 1920
      }
      p {
        text-align: center;
        font-size: clamp(0.625rem, 0.125rem + 0.625vw, 0.875rem); // 10 - 14 > 1280 - 1920
        @media screen and (max-width: 1000px) {
          font-size: 8px; 
        }
        &.flag {
          font-size: 20px;
          margin: 0 0 clamp(0.625rem, -0.625rem + 1.5625vw, 1.25rem); // 10 - 20 > 1280 - 1920
          @media screen and (max-width: 1000px) {
            margin: 0 0 5px; 
          }
        }
      }
    }
    @media screen and (max-width: 1000px) {
      padding: 16px;
      h2 {
        font-size: 14px;
      }
      ul {
        flex-wrap: wrap;
      }
    }
    @media screen and (max-width: 700px) {
      background-color: transparent;
      padding: 0;
      ul {
        gap: 8px;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; 
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          padding: 16px;
          border-radius: 10px;
          background-color: #fff;
          width: 105px;
          min-width: 105px;
        }
      }
    }
  }
`
export const Dots = styled.nav`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
`
export const Tooltip = styled.p`
  font-size: clamp(0.625rem, 0.125rem + 0.625vw, 0.875rem); // 10 - 14 > 1280 - 1920
  font-family: TCCC-UnityText-Medium;
  background-color: #FFB94A;
  color: ${colors.orange};
  padding: 7px 12px;
  border-radius: 8px;
  position: relative;
  margin-left: 20px;
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    left: -4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background-color: #FFB94A;
  }
  @media screen and (max-width: 700px) {
    width: 50%;
  }
`
export const Dot = styled.button`
  font-family: 'TCCC-UnityHeadline-Black';
  background-color: ${colors.black};
  color: ${colors.white};
  width: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  height: clamp(1.25rem, 0rem + 1.5625vw, 1.875rem); // 20 - 30 > 1280 - 1920
  font-size: clamp(0.75rem, 0.25rem + 0.625vw, 1rem); // 12 - 16 > 1280 - 1920
  font-weight: 800;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  &.active {
    background-color: ${props => props.color ? props.color : colors.green};
  }
`