import React from 'react'
import * as S from './styled'

function Rotina({ data }) {
  return (
    <S.Row>
      {data?.title && <S.Title>{data.title}</S.Title>}
      {data?.list && <S.List>
        {data.list.map((item, index) => (
          <S.Item key={index}>
            <S.Percent color={item.color}>{item.percent}</S.Percent>
            <S.Country color={item.color}>{item.country}</S.Country>
            <S.Flag>{item.flag}</S.Flag>
          </S.Item>
        ))}
      </S.List>}
      <S.Legend>{data.legend}</S.Legend>
      {data?.columns && <S.Columns>
        {data.columns.map((column, index) => (
          <S.Column key={index}>
            {column.map((paragraph, index) => (
              <S.Paragraph dangerouslySetInnerHTML={{__html: paragraph}} key={index} /> 
            ))}
          </S.Column>
        ))}
      </S.Columns>}
    </S.Row>
  )
}
export default Rotina