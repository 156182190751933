import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Menu = styled.header`
  position: relative;
  z-index: 1;
  padding: 40px  clamp(1.25rem, 0.5357rem + 3.5714vw, 3.75rem) 0; // 20 - 60
  display: ${props => props.intro ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-between;
  .logo {
    width: clamp(3.875rem, 3.55rem + 1.625vw, 5.5rem); // 62 - 88
  }
  a, button {
    font-family: 'TCCC-UnityHeadline-Bold';
    font-size: 16px;
    font-weight: 700;
  }
  &[data-modal="true"] {
    a, button {
      color: ${colors.white};
    }
    .menu-button {
      a {
        color: ${colors.black};
        &:first-of-type {
          color: ${colors.white};
          border: 1px solid ${colors.yellow};
          background-color: ${colors.yellow};
        }
      }
    }
    nav {
      ul {
        background-color: #fff;
        li {
          color: #000;
        }
      }
    }
  }
`
export const MenuNav = styled.nav`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  a {
    margin-right: 28px;
  }
  button {
    position: relative;
  }
  ul {
    border-radius: 12px;
    position: absolute;
    margin: 10px 0 0;
    width: 100px;
    display: none;
    &[open] {
      display: block;
    }
    background-color: #E54A5A;
    padding: 20px;
    li {
      cursor: pointer;
      color: #fff;
      margin: 0 0 15px;
      font-weight: 700;
      a {
        display: block;
        margin: 0;
      }
      
      &:last-of-type {
        margin: 0;
      }
      &:hover {
        opacity: .6;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`
export const MenuButtons = styled.div`
  display: flex;
  gap: 8px;
  a, button {
    width: 140px;
    text-align: center;
    font-size: 12px;
    padding: 14px;
    display: inline-block;
    border-radius: 24px;
    border: 1px solid ${colors.black};
    background-color: ${colors.white};
  }
  .guide {
    color: ${colors.white};
    background-color: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }
  button {
    color: ${colors.white};
    border: 1px solid ${colors.red};
    background-color: ${colors.red};
  }
  .menu-dropdown {
    position: relative;
    nav {
      position: absolute;
      right: 0;
      margin: 10px 0 0;
      padding: 20px;
      background-color: ${colors.red};
      border-radius: 12px;
      display: none;
      &[open] {
        display: block;
      }
      a {
        display: block; 
        padding: 0;
        margin: 0 0 15px;
        color: ${colors.white} !important;
        background-color: transparent !important;
        border: none !important;
        width: fit-content;
        &:last-of-type {
          margin: 0;
        }
        &[data-active=true] {
          opacity: .6;
          text-decoration: underline;
        }
        &:hover {
          opacity: .6;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`
export const ModalButton = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  width: 24px;
  height: 24px;
  position: relative;
  &:after, &:before, span {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background-color: ${colors.black};
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    transition: all .4s ease;
  }
  &:after {
    top: 0;
  }
  &:before {
    bottom: 0;
  }
  span {
    top: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  &[open]{
    &:after {
      top: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
    &:before {
      top: 50%;
      bottom: auto;
      transform: translateX(-50%) rotate(-45deg);
    }
    span {
      opacity: 0;
    }
  }
`

export const Modal = styled.div`
  position: absolute;
  background-color: ${colors.red};
  width: fit-content;
  height: 0;
  overflow: hidden;
  top: 100%;
  right: 0;
  padding: 0 clamp(1.25rem, 0.25rem + 5vw, 6.25rem) 0 30px;
  border-radius: 24px;
  border-radius: 24px;
  display: none;
  transition: height .6s ease, padding .6s ease;
  &[data-modal="true"] {
    nav {
      ul {
        background-color: transparent;
      }
    }
  }
  nav {
    opacity: 0;
    transition: opacity .3s ease;
  }
  @media screen and (max-width: 1024px) {
    display: block;
    &[open] {
      height: 480px;
      padding: 40px clamp(1.25rem, 0.25rem + 5vw, 6.25rem) 40px 30px;
      nav {
        opacity: 1;
      }
    }
  }
  nav {
    a {
      font-family: 'TCCC-UnityHeadline-Bold';
      font-size: 16px;
      display: block;
      margin: 0 0 20px;
      color: ${colors.white};
      &[data-active=true] {
        opacity: .6;
        text-decoration: underline;
      }
      &:hover {
        opacity: .6;
      }
    }
    ul {
      margin: 40px 0 0;
      display: flex;
      align-items: center;
      gap: 20px;
      li {
        cursor: pointer;
        font-size: 12px;
        color: ${colors.white};
        font-family: 'TCCC-UnityHeadline-Bold';
        &[open] {
          pointer-events: none;
          background-color: #fff;
          color: ${colors.red};
          border-radius: 50px;
          padding: 8px 14px;
        }
      }
    }
  }
`