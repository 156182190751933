let menu = {
  logo: `${process.env.PUBLIC_URL}/assets/logo_es.svg`,
  column1: [
    '<b>Entender que las personas y sus grupos son únicos y deben ser respetados es el primer paso para comprender la diversidad humana.</b>',
    'Nuestra jornada busca la valoración de las diferencias respecto al género, raza, sexualidad y cuerpos. Principalmente en el ámbito laboral. Sobre todo porque, una compañía que prioriza  tener su equipo diverso tiene mucho que ganar - literalmente.',
    'Según investigaciones de  McKinsey, empresas que invierten en diversidade tienen más chances de obtener mejor desempeño financeiro.'
  ],
  column2: [
    'La inclusión es un deber de cada uno de nosotros, luchemos por un mercado profesional más plural y una sociedade más justa.',
    'El material que reunimos aqui es el punto de partida. <b>En cinco caminos de apredizaje</b>, tú encontrarás datos, tips, investigaciones, números y argumentos que te ayudarán  a descontruir estereotipos cómunes en el mercado publicitario.',
    'Hazlo como una misión y sigue buscando para que la evolución sea constante. Cambiar es posible y eso empieza ahora.'
  ],
  nav: [{
    name: 'Diversidad',
    slug: 'diversidade',
    color: '#F0AB1B'
  },{
    name: 'Representatividad',
    slug: 'representatividade',
    color: '#EA6833'
  },{
    name: 'Estereotipos',
    slug: 'esteriotipos',
    color: '#94C15B'
  },{
    name: 'Rutina',
    slug: 'rotina',
    color: '#3594C9'
  },{
    name: 'Buenas Prácticas',
    slug: 'boas-praticas',
    color: '#4CBF85'
  }],
}
export function getMenuES() {
  return menu
}