import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Hero = styled.section`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0  clamp(1.25rem, 0.5357rem + 3.5714vw, 3.75rem); // 20 - 60
  height: 75vh;
  position: relative;
  margin: 48px 0 clamp(3rem, 2.15rem + 4.25vw, 7.25rem);  // 48 - 116

  @media screen and (max-width: 1000px) {
    height: auto;
    align-items: flex-start;
    margin: 0 0 clamp(3rem, 2.15rem + 4.25vw, 7.25rem);
  }
  &.mobile {
    display: none;
  }
  @media screen and (max-width: 700px) {
    &.mobile {
      display: flex;
    }
  }
`
export const Carousel = styled.div`
  width: 100%;
  max-width: 1400px;
  &.hero-perfil {
    >div {
      align-items: flex-start;
      flex-direction: column;
    }
    .content {
      width: 100%;
      margin: 20px 0 0; // 30 - 60 > 1280 - 1920
    }
  }
  @media screen and (max-width: 1000px) {
    margin: 80px 0 0;
    p {
      max-width: 100%;
    }
  }
`
export const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 40px 0 0;
  } 
`
export const Content = styled.div`
  background-image: radial-gradient(white 70%, transparent);
  width: 65%;
  margin-left: 40px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black}; 
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 20px 0 0;
    background-image: radial-gradient(white, transparent);
  }
`