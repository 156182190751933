import React from 'react'
import CallToAction from '../../components/CallToAction'
import Cards from '../../components/Card'
import Layout from '../../containers/Layout'
import Menu from '../../components/Menu'
import * as S from './styled'
import { menu } from '../../json/'

function Page({ data }) {
  let cards = data.menu

  if (data.slug === 'representatividade') {
    cards = data.menu.filter(item => item.hero.subtitle !== 'Latam')
  }

  return (
    <Layout color={data.color} currentPage={data.slug}>
      <Menu currentPage={ data.slug } data={ menu() } />
      <S.Container>
        <S.Quote color={data.color}>
          <h3 dangerouslySetInnerHTML={{__html: data.description}} />
          <p>{ data.cite }</p>
        </S.Quote>
        <Cards data={cards} slug={data.slug} />
        <CallToAction />
      </S.Container>
    </Layout>
  )
}
export default Page