let header = {
  download: 'descargar guía',
  download_link: `${process.env.PUBLIC_URL}/assets/guia_da_diversidade.pdf`,
  menu: 'elegir camino',

  dropdown: [{
    name: 'Diversidad',
    slug: 'diversidade'
  },{
    name: 'Representatividad',
    slug: 'representatividade'
  },{
    name: 'Estereotipos',
    slug: 'esteriotipos'
  },{
    name: 'Rutina',
    slug: 'rotina'
  },{
    name: 'Buenas prácticas',
    slug: 'boas-praticas'
  }],

  nav: [{
    name: 'Home',
    slug: 'trilhas',
    type: '#'
  },{
    name: 'Diversidad',
    slug: 'diversidade',
    type: '#page'
  },{
    name: 'Representatividad',
    slug: 'representatividade',
    type: '#page'
  },{
    name: 'Estereotipos',
    slug: 'esteriotipos',
    type: '#page'
  },{
    name: 'Rutina',
    slug: 'rotina',
    type: '#page'
  },{
    name: 'Buenas prácticas',
    slug: 'boas-praticas',
    type: '#page'
  },{
    name: "Do's and dont's",
    slug: 'dos-and-donts',
    type: '#'
  }]
}

let footer = {
  text: 'Producido por el Núcleo de Diversidad BlacKO y Silva',
  copyright: 'Copyright © 2022 Caminos de Diversidad',
}

export function getHeaderES() {
  return header
}

export function getFooterES() {
  return footer
}