let diversity = {
  color: '#F0AB1B',
  slug: "diversidade",
  title: "Diversidad",
  description: `
    <span class="paragraph">Aquí entiende por qué las estrategias comerciales deben considerar la singularidad de las personas y los grupos.</span>
    “Nosotros no estamos discutiendo filantropía, sino la sustentabilidad de un negocio, el impacto positivo y también el bienestar y desarrollo de las personas.”.
  `,
  cite: "Helena Bertho, Directora Global de Diversidad e Inclusión de Nubank.",
  menu: [
    {
      index: 0,
      name: "Estrategia",
      slug: "estrategia",
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-01.png`,
        description: [
          "Diversidad es estrategia de negocio y oportunidad de impactar positivamente toda una cadena productiva.",
          "Diversidad no es solamente asunto de campaña publicitaria.",
        ],
      },
      hero: {
        name: "Estrategia",
        color: '#E54A5A',
        type: 'grid',
        description: [
          "Diversidad es estrategia de negocio y oportunidad de impactar positivamente toda una cadena productiva. Diversidad no es solamente asunto de campaña publicitaria."
        ],
        grid: [
          {
            span: 1,
            color: '#EA6833',
            title: 'Impacto',
            text: [
              'Equipos diversos con formación continua crean estrategias innovadoras capaces de cautivar a un público más amplio.',
              'En las empresas comprometidas con la diversidad, <b>“los empleados son un 64% más propensos a compartir ideas y mejores prácticas”.</b>',
              '<b>(Diversity Wins - McKinsey, 2020)</b>',
            ]
          },
          {
            span: 1,
            color: '#E54A5A',
            title: 'Clima Organizacional',
            text: [
              '“Sin diversidad, la empresa pierde valor de mercado, pierde en el clima organizacional, en la confianza y el respeto de sus colaboradores”.',
              'Thais Batista, analista senior de Comunicación Interna Senior en Schneider Electric. (Olabi, 2021)',
              '<b>Y año tras año, los datos de McKinsey lo reafirman.</b>',
            ]
          },
          {
            span: 2,
            color: '#3594C9',
            title: 'Rentabilidad',
            text: [
              '<span>2018</span> "Las empresas con consejos de administración más diversos desde el punto de vista étnico/cultural en todo el mundo tienen un <b>43% más de probabilidades</b> de obtener mayores ganancias." </br><b>(Delivering Through Diversity - McKinsey, 2018)</b>',
              '<span>2020</span> “Las empresas que invierten en diversidad racial/étnica tienen un <b>36% más de posibilidades</b> de obtener beneficios.”<br/><b>(Diversity Wins - McKinsey, 2020)</b>',
            ]
          },
        ],
      },
      prev: {
        name: "Ir al Perfil",
        slug: "diversidade/perfil",
      },
      next: {
        name: "Continuar el camino",
        slug: "diversidade/inovacao",
      },
    },
    {
      index: 1,
      name: "Innovación",
      slug: 'inovacao',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-02.png`,
        description: [
          "“La diversidad posibilita el surgimiento de ideas innovadoras, con productos con calidad y creatividad que un grupo de perfil único tendría dificultad en pensar y desarrollar;”",
          "<b>Alessandra Karine, da Microsoft Brasil (Olabi, 2021)</b>"
        ],
      },
      hero: {
        name: "Innovación",
        color: '#E54A5A',
        type: 'carousel',
        bg: false,
        description: [
          "“La diversidad permite la aparición de ideas innovadoras, con productos de calidad y creatividad que un grupo de un solo perfil difícilmente podría pensar y desarrollar; sin la diversidad, no impulsaríamos el negocio;”",
          "<b>Alessandra Karine, da Microsoft Brasil <br/>(Olabi, 2021)</b>"
        ],
        carousel: [
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-01.png`,
            title: 'Decisión de compra',
            text: [
              'El público consumidor está cada vez más atento a la <b>responsabilidad social</b> de las empresas, marcas y organizaciones.',
              'La decisión de compra del <b>63% de los brasileños</b> tiene como criterio la diversidad y la inclusión.',
              '<span><b>(Diversidad e Inclusión - Facebook, 2020)</b></span>',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-02.png`,
            title: 'Adaptación',
            text: [
              'La diversidad y la sostenibilidad en las empresas crean <b>culturas organizacionales capaces de adaptarse a las transformaciones del mundo,</b> respetando los impactos que sufren las personas a partir de marcadores como el género, la raza y la clase.',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-03.png`,
            title: 'Personas',
            text: [
              '“Los colaboradores de las empresas que mantienen un compromiso con la diversidad están <b>152% más inclinados a proponer nuevas ideas y se sienten 62% más motivados a colaborar en equipo.”.</b>',
              '<span>(Diversity Wins - McKinsey, 2020)</span>',
            ]
          },
        ],
      },
      prev: {
        name: "Ir al Estrategia",
        slug: "diversidade/estrategia",
      },
      next: {
        name: "Continuar el camino",
        slug: "diversidade/publicidade-brasileira",
      },
    },
    {
      index: 2,
      name: "Publicidad brasileña",
      slug: 'publicidade-brasileira',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-03.png`,
        description: [
          "“Tienes que actuar como si fuera posible transformar radicalmente el mundo. Y tienes que hacerlo todo el tiempo”.",
          "<b>Angela Davis</b>"
        ],
      },
      hero: {
        name: "Publicidad <br/> brasileña",
        color: '#F0AB1B',
        type: '',
        description: [
          "“Tienes que actuar como si fuera posible transformar radicalmente el mundo.",
          "Y tienes que hacerlo todo el tiempo.”.",
          "<b>Angela Davis</b>"
        ],
      },
      prev: {
        name: "Ir al Innovación",
        slug: "diversidade/inovacao",
      },
      next: {
        name: "Continuar el camino",
        slug: "diversidade/perfil",
      },
    },
    {
      index: 3,
      name: "Perfil",
      slug: 'perfil',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-04.png`,
        description: [
          "La diversidad rompe la homogeneidad de los perfiles y respeta la realidad brasileña compuesta de historias plurales."
        ],
      },
      hero: {
        name: "Perfil",
        color: '#E54A5A',
        type: 'perfil',
        description: [
          "La diversidad rompe la homogeneidad de los perfiles y respeta la realidad brasileña compuesta por historias plurales en términos de:"
        ],
        cards: [
          {
            color: '#E54A5A',
            texture: 'red',
            name: "Género",
            slug: 'genero',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#E54A5A',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero.svg`,
              description: [
                "Se trata de cómo nos identificamos y deberíamos llamarnos. Uso adecuado de los pronombres masculino (él/él), femenino (ella/ella) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
              ],
            },
            hero: {
              name: "Género",
              color: '#E54A5A',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero-white.svg`,
              description: [
                "Se trata de cómo nos identificamos y deberíamos llamarnos. Uso adecuado de los pronombres masculino (él/él), femenino (ella/ella) y neutro <b class='black tooltip-container'>(elu/delu)<span class='tooltip-text'>El lenguaje neutro es el resultado de los reclamos de personas que no se ven a sí mismas de forma binaria, masculina o femenina, y proponen que los pronombres y adjetivos se declinen sin marca de género. Las letras “e” y “u”, como en Elu/Delu, enriquecen nuestro vocabulario, permitiendo “nuevas” formas de denominación y existencia.</span></b> es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/cisgenero.svg`,
                  name: "Cisgénero",
                  description: [
                    "Personas que se identifican con el género atribuido al nacer.",
                    "<b>No es una regla, así que no actúes como si fuera obligatorio.</b>"
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/trans.svg`,
                  name: `Personas trans y <span class="tooltip-container">
                  travestis
                    <span class="tooltip-text bg-red short">
                      Categoría latinoamericana utilizada para referirse a personas que no se identifican con el sexo asignado al nacer y tienen un desempeño de género asociado al femenino. Vale la pena señalar que las categorías mujer trans y travesti surgieron en períodos históricos específicos y se asociaron con valores también específicos, con la identidad travesti asociada a menudo con la marginación social y la prostitución. Suponiendo que también sea un acto político. Las mujeres trans pueden identificarse como mujeres, independientemente de que hayan sido operadas, y los travestis deben ser tratados como mujeres. Ambas identidades surgieron asociadas a lo femenino, pero eso no significa que no existan travestis no binarios o personas que utilicen las categorías trans y travesti al mismo tiempo.
                    </span>
                  </span>`,
                  description: [
                    "Personas que se identifican con un género distinto al que se les atribuye al nacer y determinan su propia identidad.",
                    "<b>La gente es libre para ser lo que quiera.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/nao-binarios.svg`,
                  name: "No binario",
                  description: [
                    "Personas que no se identifican ni con el género masculino ni con el femenino.",
                    "<b>Nadie necesita definirse o rotularse a sí mismo.</b>"
                  ],
                }
              ]
            },
            prev: {
              name: "Ir al Clase",
              slug: "diversidade/perfil/classe",
            },
            next: {
              name: "Continuar el camino",
              slug: "diversidade/perfil/raca",
            },
          },
          {
            color: '#EA6833',
            texture: 'orange',
            name: "Raza",
            slug: 'raca',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#EA6833',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca.svg`,
              description: [
                "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena."
              ],
            },
            hero: {
              name: "Raza",
              color: '#EA6833',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca-white.svg`,
              description: [
                "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena."
              ],
              row: [
                {
                  class: "percent",
                  title: "Clasificación del color/raza IBGE (IBGE - PNAD, 2012-2019)",
                  list: [
                    {
                      percent: "56,2%",
                      name: `Negros y <span class="tooltip-container">
                                pardos
                                <span class="tooltip-text bg-orange short">
                                  Término unificador utilizado en el censo brasileño de la década de 2000 “para la persona que califica como moreno o se declara mulato, cabocla, cafuza, mameluca o mestiça” (Manual del Censista – IBGE, 2000). Cuando se le pregunta al entrevistado su color, tiene la opción de responder marrón, negro, indígena, amarillo y blanco; el entrevistador no debe asumir que negro, por ejemplo, es sinónimo de negro o marrón. Corresponde al entrevistado autoclasificarse. Aunque el censo brasileño de la década de 2000 sugiere similitudes entre las categorías pardo y mestizo, optamos por no traducir inmediatamente porque estas categorías tienen diferentes historias sociales y administrativas, especialmente con respecto, en Brasil, a la relación entre el mestizaje y la política de blanqueamiento. de la NACION.
                                </span>
                              </span>`,
                    },
                    {
                      percent: "42,7%",
                      name: "Blancos"                      
                    },
                    {
                      percent: "0,7%",
                      name: "Amarillos"                      
                    },
                    {
                      percent: "0,4%",
                      name: "Indígenas"                      
                    }
                  ],
                  text: [
                    "Las minorías, como los amarillos y los indígenas, deben ser respetadas. Y las mayorías, como los negros y los pardos, no deben ser minorizadas.",
                  ]
                },
                {
                  class: "colorism",
                  title: "Colorismo",
                  text: [
                    "Es una forma de discriminación basada en el tono de piel. Cuanto más pigmentada una persona, más exclusión sufre. En la publicidad, el colorismo da como resultado una preferencia por los negros de piel clara, como si todos los que se acercan a los blancos fueran más humanos.",
                    "<b>'Las vidas negras importan', sin selectividad.</b>",
                  ]
                },
                {
                  class: "racism",
                  title: "Racismo",
                  text: [
                    "El racismo se produce cuando un grupo poderoso, históricamente blanco, discrimina a los indígenas, los negros y a las personas de origen asiático, entre otros. Esta discriminación puede ser violenta o silenciosa, pero siempre da lugar a jerarquías: los blancos son alzados a la cima mientras que los demás humanos son inferiorizados.",
                    "<b>No basta con no ser racista, ¡hay que ser <span>Antirracista!</span></b>",
                  ]
                }
              ]
            },
            prev: {
              name: "Ir al Género",
              slug: "diversidade/perfil/genero",
            },
            next: {
              name: "Continuar el camino",
              slug: "diversidade/perfil/lgbtqia",
            },
          },
          {
            color: '#F0AB1B',
            texture: 'yellow',
            name: "LGBTQIA+",
            slug: 'lgbtqia',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#F0AB1B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia.svg`,
              description: [
                "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
              ],
            },
            hero: {
              name: "LGBTQIA+",
              color: '#EA6833',
              type: 'carousel',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia-white.svg`,
              description: [
                "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad.",
              ],
              carousel: [
                {index:0},
                {index:1},
                {index:2},
              ],
            },
            prev: {
              name: "Ir al Raza",
              slug: "diversidade/perfil/raca",
            },
            next: {
              name: "Continuar el camino",
              slug: "diversidade/perfil/corpos-plurais",
            },
          },
          {
            color: '#94C15B',
            texture: 'green',
            name: "Cuerpos Plurales",
            slug: 'corpos-plurais',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#94C15B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais.svg`,
              description: [
                "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
              ],
            },
            hero: {
              name: "Cuerpos Plurales",
              color: '#94C15B',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais-white.svg`,
              description: [
                "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/capacitismo.svg`,
                  name: "Capacitismo",
                  description: [
                    "Prejuicios contra las personas con discapacidad. Es creer que algunos seres humanos son inferiores y/o menos capaces que otros por algunas de sus características.",
                    "<b>Diferente es quien no respeta las diferencias de los otros.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/etarismo.svg`,
                  name: "Etarismo",
                  description: [
                    "Intolerancia contra los individuos o grupos de edad basada en estereotipos asociados a la edad. Las personas mayores no son limitadas.",
                    "<b>Ese pensamiento es limitado.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/gordofobia.svg`,
                  name: "Gordofobia",
                  description: [
                    "Manifestaciones discriminatorias contra las personas gordas que dan lugar a la marginación e inferiorización de sus cuerpos.",
                    "<b>No quieras cambiar la forma de las personas, sino la manera de verlas.</b>",
                  ],
                }
              ]
            },
            prev: {
              name: "Ir al LGBTQIA+",
              slug: "diversidade/perfil/lgbtqia",
            },
            next: {
              name: "Continuar el camino",
              slug: "diversidade/perfil/classe",
            },
          },
          {
            color: '#3594C9',
            texture: 'blue',
            name: "Clase",
            slug: 'classe',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#3594C9',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe.svg`,
              description: [
                "La desigualdad económica implica en desigualdad de oportunidades y afecta a la calidad de vida de la mayoría de los brasileños."
              ],
            },
            hero: {
              name: "Clase",
              color: '#3594C9',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe-white.svg`,
              description: [
                "La desigualdad económica implica en desigualdad de oportunidades y afecta a la calidad de vida de la mayoría de los brasileños."
              ],
            },
            prev: {
              name: "Ir al Cuerpos",
              slug: "diversidade/perfil/corpos-plurais",
            },
            next: {
              name: "Continuar el camino",
              slug: "representatividade",
            },
          }
        ]
      },
      prev: {
        name: "Ir al Publicidad",
        slug: "diversidade/publicidade-brasileira",
      },
      next: {
        name: "Continuar el camino",
        slug: "diversidade/perfil/genero",
      },
    },
  ]
};

let represents = {
  color: '#EA6833',
  slug: 'representatividade',
  title: "Representatividad",
  description: `
    <span class="paragraph">Aquí encontrarás argumentos y números que te ayudarán a darte cuenta de que realmente importa traer diferentes cuerpos a la publicidad.</span>
    En un mundo en transformación, es necessário abrir espacios para las múltiples <span class='tooltip-container'>identidades<span class='tooltip-text bg-orange'>El conjunto de atributos que caracterizan a una persona, pudiendo distinguirla o ubicarla en un grupo. Por ejemplo, Beyoncé es una mujer negra cisgénero. Esta es tu identidad.</span></span> para que haya el desvio del peligro de uma historia única sobre quien las personas son y lo que pueden ser.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Género",
      slug: 'genero-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/his), femenino (she/her) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
      },
      hero: {
        name: "Género",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/his), femenino (she/her) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
        row: [
          '<p>Hombres, mujeres, personas cis, trans, travestis o no binarias deben estar representados en la publicidad y en las compañías, <b>pero algunas personas son invisibilizadas simplemente por ser quienes son.</b></p>',
          `
            <div class="box">
              <div class="percent">74%</div>
              <div class="text">
                <p>de las brasileñas que aparecen son blancas.</p>
                <p class="legend"><b>(Todxs, 9º Onda)</b></p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>Hay pocos datos sobre personas trans. Eso también es consecuencia de la invisibilidad. ¿Y qué sabemos?  Que Brasil es el país que más mujeres trans y travestis mata por 13º año consecutivo.</p>
                <p class="legend"><b>(Antra, 2022)</b></p>
              </div>
            </div>
          `,
          '<p>La publicidad debe aprovechar su alcance para reforzar la idea de que todas las personas merecen ocupar espacios por igual.</p>',
        ],
      },
      prev: {
        name: "Ir al Clase Latam",
        slug: "representatividade/classe-latam",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/genero-latam",
      },
    },
    {
      index: 1,
      name: "Género",
      slug: 'genero-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/his), femenino (she/her) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
      },
      hero: {
        name: "Género",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/his), femenino (she/her) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
        text_title: "Eso no es solo en Brasil.",
        text: [
          "<p>En toda América Latina, las mujeres y las personas LGBTQIA+ siguen luchando por una mayor igualdad e inclusión. <b>Dentro de estos dos grupos, hay un alto índice de personas que consideran que estas demandas son importantes.</b> Esto demuestra que son los más comprometidos no sólo con la comprensión, sino también con la búsqueda de aquello en lo que creen. </p>",
        ],
        table_title: "Relevancia de la agenda",
        table: {
          type: "title",
          row_1: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
              title: "Mujeres"
            },
            {
              color: "#64193A",
              percent: "91%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#792240",
              percent: "90%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#97314A",
              percent: "89%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#B54253",
              percent: "88%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#D4565E",
              percent: "88%",
              country: "Perú",
              flag: "🇵🇪"
            },
            {
              color: "#DF7C7A",
              percent: "85%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ],
          row_2: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
              title: "LGBTQIA+"
            },
            {
              color: "#64193A",
              percent: "72%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "70%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "67%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#D4565E",
              percent: "64%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#DF7C7A",
              percent: "52%",
              country: "Perú",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversidad e Inclusión - Facebook, 2020)",
      },
      prev: {
        name: "Ir al Género Brasil",
        slug: "representatividade/genero-brasil",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/raca-brasil",
      },
    },
    {
      index: 2,
      name: "Raza",
      slug: 'raca-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena.",
        ],
      },
      hero: {
        name: "Raza",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena.",
        ],
        row: [
          '<p>Sabemos que Brasil tiene una estructura racista que privilegia a los blancos en detrimento de los negros y los indígenas.</p>',
          '<p><b>Es necesario cambiar este escenario y construir equipos racialmente diversos para que las minorías sean contempladas en su pluralidad.</b></p>',
          `
            <div class="box">
              <div class="text black">
                <p>Las mujeres negras son solo el <span>3%</span> entre los líderes empresariales.</p>
                <p class="legend">Disponible: <a href="https://www.terra.com.br/nos/mulheres-negras-sao-apenas-3-entre-lideres-nas-empresas-diz-estudo,cc51a786e4402676743ce2163f45fce9hj9rhyxq.html" target="_blank">Las mujeres negras son solo el 3% de los líderes empresariales, según un estudio</a> (terra.com.br)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>Solo el <span>4%</span> de las telenovelas de la principal emisora brasileña fueron protagonizadas por mujeres no blancas y el <span>1%</span> por hombres no blancos.</p>
                <p class="legend">(GEMAA, 2015)</p>
              </div>
            </div>
          `,
          '<p><b> El casting debe reflejar la diversidad entre los negros y los indígenas, incluyendo tanto a los negros retintos como a los indígenas en puestos destacados.</b></p>',
        ],
      },
      prev: {
        name: "Ir al Gênero Latam",
        slug: "representatividade/genero-latam",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/raca-latam",
      },
    },
    {
      index: 3,
      name: "Raza",
      slug: 'raca-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena.",
        ],
      },
      hero: {
        name: "Raza",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena.",
        ],
        text_title: "Eso no es solo en Brasil.",
        text: [
          "<p><b>A los ojos de la población latinoamericana, falta diversidad e inclusión en las campañas,</b> ya que la publicidad sigue insistiendo en reproducir estereotipos, incluso raciales.</p>",
          "<p>En su mayoría, más de la mitad de las personas entrevistadas están de acuerdo en que los anuncios presenten a las personas negras o indígenas en una <b>posición menos destacada.</b></p>",
        ],
        table_title: "Falta de importancia de las personas no blancas.",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "60%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "60%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#97314A",
              percent: "59%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "54%",
              country: "Perú",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "49%",
              country: "Brasil",
              flag: "🇧🇷"   
            },
            {
              color: "#DF7C7A",
              percent: "48%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ]
        },
        legend: "(Diversidad e Inclusión - Facebook, 2020)",
      },
      prev: {
        name: "Ir al Raza Brasil",
        slug: "representatividade/raca-brasil",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/lgbtqia-brasil",
      },
    },
    {
      index: 4,
      name: "LGBTQIA+",
      slug: 'lgbtqia-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
        row: [
          '<p><b>Lesbianas, gays, bisexuales, transexuales, travestis, queer, intersexuales y asexuales son algunas de las identidades que contempla el acrónimo LGBTQIA+,</b> valorando y respetando la diversidad de géneros, cuerpos y sexualidades.</p>',
          `
            <div class="box center">
              <p>Conhecer é importante, mas não é tudo.</p>
            </div>
          `,
          '<p><b>Las personas LGBTQIA+ deben estar representadas en la publicidad durante todo el año y no sólo en las fechas conmemorativas o en los periodos de protagonismo. De lo contrario, se leerá que las marcas sólo están interesadas en el <span class="tooltip-container">Pink Money<span class="tooltip-text bg-yellow">Término utilizado para caracterizar la comercialización de productos para el público LGBTQIA+ con el único fin de incrementar el potencial de consumo.</span></span>.</b></p>',
        ],
      },
      prev: {
        name: "Ir al Raza Latam",
        slug: "representatividade/raca-latam",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/lgbtqia-latam",
      },
    },
    {
      index: 5,
      name: "LGBTQIA+",
      slug: 'lgbtqia-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
        text_title: "Eso no es solo en Brasil ",
        text: [
          "<p>Las empresas que no actúan con ética pueden ser mal recibidas por la comunidad LGBTQIA+ latinoamericana. <b>Cuando las marcas se posicionan a favor de la inclusión sólo en periodos limitados, pueden ser vistas como oportunistas.</b> La mayoría de los entrevistados prefieren comprar en empresas que realmente se preocupan por la diversidad y la inclusión.</p>",
        ],
        table_title: "Decisión de compra",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "76%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#792240",
              percent: "71%",
              country: "Perú",
              flag: "🇵🇪"
            },
            {
              color: "#97314A",
              percent: "70%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#D4565E",
              percent: "60%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "58%",
              country: "México",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversidad e Inclusión / LGBTQIA+ - Facebook, 2021)",
      },
      prev: {
        name: "Ir al LGBTQIA+ Brasil",
        slug: "representatividade/lgbtqia-brasil",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/corpos-plurais-brasil",
      },
    },
    {
      index: 6,
      name: "Cuerpos plurales",
      slug: 'corpos-plurais-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
        ],
      },
      hero: {
        name: "Cuerpos <br/> Plurales",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
        ],
        row: [
          '<p>Somos diversos por naturaleza. <b>Sin embargo, algunas personas se ven invalidadas por sus diferencias,</b> como los gordos, los discapacitados, las personas mayores y otros grupos sub-representados. </p>',
          
          `
            <div class="box">
              <div class="text black">
                <p>La publicidad es gordofóbica. Sólo el <span>1%<span> de los personajes de las campañas son gordos. El <span>16%</span> tiene cuerpos de plus size. </p>
                <p class="legend">(Todx, 6º Onda)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>Alrededor del <span>15%</span> de la población mundial, más de mil millones de personas, tiene una discapacidad, pero las personas con discapacidad están mal representadas en las campañas publicitarias.</p>
                <p class="legend">(Relatório Mundial sobre a deficiência - OMS, 2011; Dados, Diversidade e Representação - Facebook)</p>
              </div>
            </div>
          `,
          '<p><b>El papel de la publicidad es pluralizar las representaciones de los cuerpos y las personas, priorizando las escenas cotidianas y disipando el <span class="tooltip-container">exotismo<span class="tooltip-text bg-green short">Término utilizado cuando reducimos la expresión de alguien a la mera cualidad de exótico, extraño, vulgar o extravagante.</span></span>.</b></p>',
        ],
      },
      prev: {
        name: "Ir al LGBTQIA+ Latam",
        slug: "representatividade/lgbtqia-latam",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/corpos-plurais-latam",
      },
    },
    {
      index: 7,
      name: "Cuerpos plurales",
      slug: 'corpos-plurais-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
        ],
      },
      hero: {
        name: "Cuerpos <br/> Plurales",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende las formas de violencia que pueden sufrir estos cuerpos para no reproducirlas."
        ],
        text_title: "Eso no es solo en Brasil",
        text: [
          "<p>Las personas con discapacidad en América Latina, además de considerar que <b>las propagandas en general aún son muy estereotipadas, no se identifican con lo que es representado.</b> Gran parte de los entrevistados que</p>",
          "<p>posee o convive con alguien con alguna discapacidad considera que la mayoría de las propagandas aún representan  prioritariamente personas blancas, delgadas y ricas.</p>",
        ],
        table_title: "Falta de diversidad",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "80%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "73%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#97314A",
              percent: "72%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#B54253",
              percent: "71%",
              country: "México",
              flag: "🇲🇽"
            },
            {
              color: "#D4565E",
              percent: "68%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "67%",
              country: "Perú",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversidad e Inclusión / Pessoas com Deficiência - Facebook, 2021)",
      },
      prev: {
        name: "Ir al Clase Brasil",
        slug: "representatividade/corpos-plurais-brasil",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/classe-brasil",
      },
    },
    {
      index: 8,
      name: "Clase",
      slug: 'classe-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "La  desigualdad económica implica en desigualdad de oportunidades y afecta la calidad de vida de la mayoría de los brasileños."
        ],
      },
      hero: {
        name: "Clase",
        subtitle: 'Brasil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "La  desigualdad económica implica en desigualdad de oportunidades y afecta la calidad de vida de la mayoría de los brasileños."
        ],
        row: [
          '<p>La desigualdade social caracteriza sociedades que tienen alto nivel de concentración de renta.</p>',
          '<p><b>Ese fenómeno impacta no solo a las personas que producen contenido sino también a aquellas que están siendo representadas.</b></p>',
          `
            <div class="box">
              <div class="text">
                <p>“Hombres blancos de 1% más rico se apropian de más renta que todas las mujeres negras del páis.”</p>
                <p class="legend">(NPE nº18, Made USP)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>El 69% de los brasileños considera que el mercado laboral es desigual, especialmente para las minorías y los grupos minoritarios.</p>
                <p class="legend">(Diversidad e Inclusión - Facebook, 2020)</p>
              </div>
            </div>
          `,
          '<p><b>Los equipos sin diversidad, incluso si están motivados por buenas intenciones, es más probable que representen símbolos de clase fuera de contexto y tono. Esto puede suceder, por ejemplo, cuando el casting y el vestuario suenan como caricaturas.</b></p>',
        ],
      },
      prev: {
        name: "Ir al Cuerpos Plurales Latam",
        slug: "representatividade/corpos-plurais-latam",
      },
      next: {
        name: "Continuar el camino",
        slug: "representatividade/classe-latam",
      },
    },
    {
      index: 9,
      name: "Clase",
      slug: 'classe-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "La  desigualdad económica implica en desigualdad de oportunidades y afecta la calidad de vida de la mayoría de los brasileños."
        ],
      },
      hero: {
        name: "Clase",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "La  desigualdad económica implica en desigualdad de oportunidades y afecta la calidad de vida de la mayoría de los brasileños."
        ],
        text_title: "Eso no es solo en Brasil",
        text: [
          "<p>Aunque diversas empresas en América Latina sean más propensas a superar a sus concurrentes <b>(Diversity Matters - Mckinsey, 2020)</b>, el mercado de trabajo es desigual en la percepción de los latinoamericanos. <b>Gran parte de los entrevistados cree que las oportunidades de trabajo son restrictas a grupos sociales históricamente privilegiados.</b></p>",
        ],
        table_title: "Menos oportunidades",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "69%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#792240",
              percent: "69%",
              country: "Brasil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "68%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "68%",
              country: "Perú",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "63%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#DF7C7A",
              percent: "62%",
              country: "México",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversidad e Inclusión - Facebook, 2020)",
      },
      prev: {
        name: "Ir al Clase Brasil",
        slug: "representatividade/classe-brasil",
      },
      next: {
        name: "Continuar el camino",
        slug: "esteriotipos",
      },
    },
  ]
};

let stereotypes = {
  color: '#94C15B',
  slug: 'esteriotipos',
  title: "Estereotipos",
  description: `
    <span class="paragraph">Aquí encontrará qué hacer y qué no hacer cada vez que diseñe una campaña de forma creativa.</span>
    ¡Escápate de los lugares cómunes! Las historias deben contarse de diversas maneras, con respeto a las personas y sin generalizaciones engañosas. La realidad es compleja. 
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Género",
      slug: 'genero',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero.svg`,
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/él), femenino (ella/ella) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
      },
      hero: {
        name: "Género",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image01.png`,
        description: [
          "Se trata de cómo nos identificamos y deberíamos llamarnos. El uso adecuado de los pronombres masculino (ele/él), femenino (ella/ella) y neutro (elu/delu) es una forma de reconocimiento social y afirmación de una existencia digna. Se debe respetar a las personas trans y no binarias."
        ],
        columns: [
          {
            title: '¿Sabías qué?',
            text: 'La sociedad define diferentes roles de género para hombres y mujeres respecto a la profesión, la apariencia, la postura, entre otros.',
          },
          {
            title: 'Ahora ya lo sabes.',
            text: 'No hay que limitar la imagen de la mujer solamente a la apariencia y el cuidado. Tampoco reproduzcas la idea de que todos los hombres tienen que ser viriles, masculinos e insensibles.',
          },
          {
            title: '¿Qué hacer?',
            text: 'Las campañas pueden valorar a las mujeres en diferentes posiciones, como el liderazgo y la fuerza, y revelar otras características masculinas, como la sensibilidad y el compañerismo.',
          },
        ]
      },
      prev: {
        name: "Ir al Clase",
        slug: "esteriotipos/classe",
      },
      next: {
        name: "Continuar el camino",
        slug: "esteriotipos/raca",
      },
    },
    {
      index: 1,
      name: "Raza",
      slug: 'raca',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca.svg`,
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena."
        ],
      },
      hero: {
        name: "Raza",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image02.png`,
        description: [
          "Tiene que ver con el uso histórico de los rasgos corporales, como el cabello rizado, el color de piel negro y ciertas formas de ojos y narices, como signos de inferioridad. Por eso es tan importante la lucha por la afirmación del orgullo negro e indígena."
        ],
        columns: [
          {
            title: '¿Sabías qué?',
            text: 'Las representaciones superficiales de las historias de los negros, indígenas y asiáticos son una forma de violencia. El racismo invisibiliza, limita y/o encierra al otro',
          },
          {
            title: 'Ahora ya lo sabes.',
            text: 'Siendo así, no retrates a los negros de forma hipersexualizada o rabiosa. No demarques a los indígenas como vagos o inocentes. Ni crear la idea de que los asiáticos son una minoría modelo y sin defectos.',
          },
          {
            title: '¿Qué hacer?',
            text: 'La publicidad debe rechazar los estereotipos de raza y destacar la presencia de personas negras, indígenas y asiáticas en diferentes espacios, roles y relaciones.',
          },
        ]
      },
      prev: {
        name: "Ir al Género",
        slug: "esteriotipos/genero",
      },
      next: {
        name: "Continuar el camino",
        slug: "esteriotipos/lgbtqia",
      },
    },
    {
      index: 2,
      name: "LGBTQIA+",
      slug: 'lgbtqia',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia.svg`,
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image03.png`,
        description: [
          "La sigla paraguas representa el movimiento político y social que defiende la diversidad de género y sexualidad."
        ],
        columns: [
          {
            title: '¿Sabías qué?',
            text: 'Las personas LGBTQIA+ se sienten menos representadas en las campañas*. No sólo porque están menos presentes, sino también porque se habla sobre ellas de forma caricaturizada.<p><b>(Diversidad y Inclusión - Facebook, 2020)</b></p>',
          },
          {
            title: 'Ahora ya lo sabes.',
            text: `Por lo tanto, no asocies la identidad de los hombres homosexuales <b class="tooltip-container" style="z-index: 3"><u>sólo</u><span class="tooltip-text bg-yellow short">Hay hombres homosexuales que se adhieren a los símbolos femeninos, hay hombres homosexuales que se adhieren a los símbolos masculinos y hay hombres homosexuales que rechazan ambos símbolos. Todas las expresiones son válidas. Lo importante aquí no es limitar una identidad plural a una sola representación de lo que es ser gay.</span></b> con símbolos femeninos, ni la identidad de las mujeres lesbianas <b class="tooltip-container" style="z-index: 2"><u>sólo</u><span class="tooltip-text bg-yellow">Hay mujeres lesbianas que se adhieren a los símbolos femeninos, hay mujeres lesbianas que se adhieren a los símbolos masculinos y hay mujeres lesbianas que rechazan ambos símbolos. Todas las expresiones son válidas. Lo importante aquí no es limitar una identidad plural a una sola representación de lo que es ser lesbiana.</span></b> con símbolos masculinos. La sexualidad y <b class="tooltip-container"><u>la expresión de género</u><span class="tooltip-text bg-yellow">La forma en que alguien manifiesta públicamente su identidad de género, ya sea a través del nombre, la vestimenta, los símbolos o el comportamiento. Todas las expresiones de género son válidas independientemente de la identidad de género de la persona.</span></b> son temas distintos! <b>Asimismo, no trates a los transexuales y travestis como alivio cómico o como exotización.</b>`,
          },
          {
            title: '¿Qué hacer?',
            text: 'El papel de la publicidad es ampliar el abanico de representaciones LGBTQIA+, sin apostar siempre por narrativas de sufrimiento, sátira o superación.',
          },
        ]
      },
      prev: {
        name: "Ir al Raza",
        slug: "esteriotipos/raca",
      },
      next: {
        name: "Continuar el camino",
        slug: "esteriotipos/corpos-plurais",
      },
    },
    {
      index: 3,
      name: "Cuerpos Plurales",
      slug: 'corpos-plurais',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais.svg`,
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende qué formas de violencia pueden sufrir estos cuerpos para no reproducirlas."
        ],
      },
      hero: {
        name: "Cuerpos <br/> Plurales",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image04.png`,
        description: [
          "Ningún cuerpo es igual a otro y esta diferencia debe ser valorada y respetada por el mercado publicitario. Entiende qué formas de violencia pueden sufrir estos cuerpos para no reproducirlas."
        ],
        columns: [
          {
            title: '¿Sabías qué?',
            text: 'La presencia mayoritaria de personas blancas y delgadas en las campañas (66%)* excluye a las personas con discapacidad, a los gordos y a los ancianos. No basta con incluirlos, hay que representarlos con dignidad.<p><b>(Diversidad y Inclusión - Facebook, 2020)</b></p>',
          },
          {
            title: 'Ahora ya lo sabes.',
            text: 'No reduzcas a los gordos solamente a situaciones humorísticas. No trates siempre a las personas mayores como gruñonas o dependientes. Tampoco hay que limitar la historia de las PCDs únicamente a casos de superación o tristeza.',
          },
          {
            title: '¿Qué hacer?',
            text: 'Crear un espacio para desmitificar los tabúes en torno a estos cuerpos. Representa a las PCDs ocupando diversos espacios, como el mercado laboral y el deporte; a los gordos como símbolo de belleza y seguridad personal; y a los mayores como capaces y felices.',
          },
        ]
      },
      prev: {
        name: "Ir al LGBTQIA+",
        slug: "esteriotipos/lgbtqia",
      },
      next: {
        name: "Continuar el camino",
        slug: "esteriotipos/classe",
      },
    },
    {
      index: 4,
      name: "Clase",
      slug: 'classe',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe.svg`,
        description: [
          "La desigualdad económica implica desigualdad de oportunidades y afecta a la calidad de vida de la mayoría de los brasileños."
        ],
      },
      hero: {
        name: "Clase",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image05.png`,
        description: [
          "La desigualdad económica implica desigualdad de oportunidades y afecta a la calidad de vida de la mayoría de los brasileños."
        ],
        columns: [
          {
            title: '¿Sabías qué?',
            text: 'Los patrones de consumo, deseo y comportamiento pueden atravesar las clases sociales. A menudo, son los prejuicios los que crean fronteras, haciendo que las personas y sus gustos se asocien exclusivamente a la cantidad de dinero que tienen.',
          },
          {
            title: 'Ahora ya lo sabes.',
            text: 'Los miembros de la clase C no deben ser vistos sólo como deseosos de riqueza o como personas con estilos visuales cuestionables, mientras que los miembros de la clase superior son héroes, amables y respetables.',
          },
          {
            title: '¿Qué hacer?',
            text: 'Apuesta en las historias de personas reales, disipando la suposición de que hay lugares a los que una clase no puede ir o formas de comportarse en esos lugares que son más adecuadas.',
          },
        ]
      },
      prev: {
        name: "Ir al Cuerpos Plurales",
        slug: "esteriotipos/corpos-plurais",
      },
      next: {
        name: "Continuar el camino",
        slug: "rotina",
      },
    },
  ]
};

let routine = {
  color: '#3594C9',
  slug: 'rotina',
  title: "Rutina",
  description: `
    <span class="paragraph">Aquí entiendes la importancia de alinear lo que los latinoamericanos esperan de las empresas con su rutina laboral</span>
    Crear una publicidad más inclusiva y diversa requiere un ejercicio constante de análisis. Una excelente forma de empezar es analizar los procesos cotidianos en relación con lo que el público espera.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Patrón Global",
      slug: 'padrao-global',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        description: [],
      },
      hero: {
        name: "Patrón <br /> Global",
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        title: 'Carencia de identificación',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '86%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '81%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '79%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '78%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '76%',
            country: 'Brasil',
            flag: '🇧🇷',
          }
        ],
        legend: '(Diversidad y Inclusión - Facebook, 2020)',
        columns: [
          [
            'La diversidad presente en las campañas debe ajustarse a las realidades nacionales para generar identificación y demostrar compromiso.',
            '<b>Pero los latinoamericanos no sienten que esto ocurra.</b>',
            '<b class="colorfull">Hay que hacer algo.</b>',
          ],
          [
            '<b>Uno de los caminos es crear oportunidades de adaptación de las campañas globales para conseguir mejores resultados.</b> Ello comienza con preguntas sobre la no identificación.',
            '¿Los latinoamericanos no se ven en la publicidad <b>porque ésta reproduce un patrón de diversidad global estereotipado,</b> con poco atractivo y reflejo en cada uno de los países mencionados?',
          ],
        ]
      },
      prev: {
        name: "Ir al Negocio",
        slug: "rotina/negocio",
      },
      next: {
        name: "Continuar el camino",
        slug: "rotina/fornecedores",
      },
    },
    {
      index: 1,
      name: "Proveedores",
      slug: 'fornecedores',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        description: [],
      },
      hero: {
        name: "Provee-</br>dores",
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        title: 'Grado de optimismo',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '61%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '55%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '53%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '52%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#78B8DA',
            percent: '51%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversidad y Inclusión - Facebook, 2020)',
        columns: [
          [
            'Hay optimismo entre los latinoamericanos. Se espera que la publicidad sea más inclusiva y diversa en los próximos 5 años.',
            'Para construir hoy lo que la gente espera ver mañana, <b>hay que crear y contratar equipos plurales capaces de hacer realidad las expectativas</b>.',
          ],
          [
            'Tómate un tiempo para pensar: ¿tienes en cuenta la diversidad de género, raza, sexualidad y región a la hora de contratar o subcontratar agencias? ',
            '<b>¿Tienes una amplia lista de proveedores plurales, o repite los mismos una y otra vez? <span class="colorfull">¿Hay diversidad entre los líderes de los equipos con los que trabaja?</span></b>',
          ],
        ]
      },
      prev: {
        name: "Ir al Patrón Global",
        slug: "rotina/padrao-global",
      },
      next: {
        name: "Continuar el camino",
        slug: "rotina/influenciadores",
      },
    },
    {
      index: 2,
      name: "Influencers",
      slug: 'influenciadores',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        description: [],
      },
      hero: {
        name: "Influen-</br>cers",
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        title: 'Falta de representatividad',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '94%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '85%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#3C73A7',
            percent: '84%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#5093C4',
            percent: '81%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '80%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversidad y Inclusión - Facebook, 2020)',
        columns: [
          [
            'El mundo está cambiando rápidamente.',
            '<b>La generación Z es la que menos cree que la publicidad representa la pluralidad latinoamericana.</b> El marketing inclusivo será cada vez más necesario.',
            '<b>De él depende el éxito de la comunicación en las plataformas sociales que permiten una mayor segmentación de la audiencia</b> y, al mismo tiempo, amplifican y pluralizan voces que antes no tenían espacio.'
          ],
          [
            '<b>¿Has dado prioridad en tu estrategia de contratación a los influencers que representan la diversidad de la audiencia? <span class="colorfull">¿Cuáles son las historias que cuentan y qué importancia tienen para tu marca?</span></b>',
            '<b>¿Aportan valor independientemente del número de seguidores?</b>'
          ],
        ],
      },
      prev: {
        name: "Ir al Proveedores",
        slug: "rotina/fornecedores",
      },
      next: {
        name: "Continuar el camino",
        slug: "rotina/comunicacao",
      },
    },
    {
      index: 3,
      name: "Comunicación",
      slug: 'comunicacao',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        description: [],
      },
      hero: {
        name: "Comuni-</br>cación",
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        title: 'Índice de satisfacción',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '36%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#2B578C',
            percent: '33%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '32%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '26%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '21%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#97D4EB',
            percent: '21%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversidad y Inclusión - Facebook, 2020)',
        columns: [
          [
            'El porcentaje de latinoamericanos satisfechos con el desempeño de su empresa en cuanto a diversidad e inclusión es bajo.',
            '<b>El cambio es necesario y requiere que todas las personas sean activas en este proceso.</b>',
            'Es necesario promover debates y propagar buenas prácticas, con responsabilidad y sin miedo a la indisposición.',
          ],
          [
            '<b class="colorfull">Las conversaciones difíciles no tienen por qué ser irrespetuosas.</b>',
            'Puede que no sea fácil hablar de temas como la raza, el género y la sexualidad en el lugar de trabajo, pero es necesario. El debate es una parte esencial del cambio y debe ser valorado.',
          ],
        ]
      },
      prev: {
        name: "Ir al Influencers",
        slug: "rotina/influenciadores",
      },
      next: {
        name: "Continuar el camino",
        slug: "rotina/negocio",
      },
    },
    {
      index: 4,
      name: "Negocio",
      slug: 'negocio',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
      },
      hero: {
        name: "Negocio",
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
        title: 'Criterio para  la Adquisición',
        list: [
          {
            color: '#1D3E70',
            percent: '64%',
            country: 'ColOmbia',
            flag: '🇨🇴',
          },
          {
            color: '#2B578C',
            percent: '60%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '59%',
            country: 'Brasil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '55%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '50%',
            country: 'México',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversidad y Inclusión - Facebook, 2020)',
        columns: [
          [
            'La mayoría de los latinoamericanos cree que el compromiso de las marcas con la diversidad <b>es esencial para su decisión de compra.</b>',
            '<b>En otras palabras, ignorar el tema puede significar perder la conexión con los consumidores.</b> Las prioridades públicas están cambiando, pero ¿no es',
          ],
          [
            'hora de entender la diversidad y la inclusión como objetivos para toda la cadena de producción?',
            '<b>El tiempo para la diversidad es el tiempo dedicado al desarrollo del negocio.</b> Ahora es el momento de crear una rutina de trabajo en la que la inclusión no sea secundaria, sino estratégica.',
            '<b class="colorfull">Dedícate a lo que importa a los consumidores.</b>',
          ],
        ]
      },
      prev: {
        name: "Ir al Comunicación",
        slug: "rotina/comunicacao",
      },
      next: {
        name: "Continuar el camino",
        slug: "boas-praticas",
      },
    },
  ]
};

let practices = {
  color: '#4CBF85',
  slug: 'boas-praticas',
  title: "Buenas Prácticas",
  description: `
    <span class="paragraph">Conocer errores, anticipar riesgos e identificar oportunidades exige que se eche un vistazo atento para las campañasy sus mensajes. Hacer eso cuando lidiamos con regiones y países diferentes es aún más urgente. Aquí están buenas prácticas para campañas inclusivas e innovadoras.</span>
    Aquí se entiende la importancia de llevar la América Latina real en cada mensaje de las marcas de la empresa
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Contexto",
      slug: 'contexto',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
      },
      hero: {
        name: "Contexto",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
        columns: [
          [
            '<b>Hacer que los mensajes de las marcas se adapten a los tiempos actuales requiere innovación.</b>',
            'Representan formas de consumo menos habituales y amplían la diversidad en contextos como fiestas, reuniones entre amigos, cenas familiares, entre otros.',
            '<span><b>Al hablar de la Navidad, por ejemplo, puedes elegir una familia liderada por madres solas y sus hijos. Cuando se retratan bares o lugares típicamente vinculados a hombres cis, se puede incluir a mujeres cis como propietarias del lugar.</b></span>',
          ],
          [
            'En los escenarios deportivos, las mujeres negras trans pueden ser las protagonistas. Al igual que en las ceremonias, los indígenas pueden recibir su diploma.',
            'Como nuestra sociedad está en constante cambio, hay que preguntarse todo el tiempo cómo representar a las personas de carne y hueso: ¿cómo se comportan, qué les gusta, cómo se relacionan?',
            '<b>Si te quedas desactualizado, tu marca puede quedar atrás, apostando en escenarios que perdieron atractivo.</b>',
          ],
        ]
      },
      prev: {
        name: "Ir al Liderazgo",
        slug: "boas-praticas/lideranca",
      },
      next: {
        name: "Continuar el camino",
        slug: "boas-praticas/local",
      },
    },
    {
      index: 1,
      name: "Local",
      slug: 'local',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
      },
      hero: {
        name: "Local",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
        columns: [
          [
            '<b>Las campañas no necesitan hablar de países repitiendo una y otra vez los mismos símbolos y lugares.</b>',
            '¿Por qué limitar las culturas? América Latina no es exclusivamente Brasil. Brasil no es sólo Río de Janeiro o el Cristo Redentor. Colombia no es solamente Bogotá o las playas de su región caribeña. Y Perú definitivamente no es solo Machu Picchu.',
            '<span><b>La representación de los países debe tener en cuenta las diversidades regionales. Del norte al sur de América Latina, tenemos diferentes tipos de bellezas y experiencias.</b></span>',
          ],
          [
            'Los veranos en Argentina son diferentes en los parques de Córdoba y los Lagos Patagónicos. En Brasil, los veranos tienen la playa de Copacabana, pero también los ríos de Belém y las cascadas de Chapada Diamantina. En cada rincón, personas delgadas, en forma, gordas, discapacitadas, de todos los colores y características, disfrutan del sol, de la piscina, de la losa.',
            '<b>Los veranos son muchos y de todos. ¡Valora la verdadera América Latina!</b>',
          ],
        ]
      },
      prev: {
        name: "Ir al Contexto",
        slug: "boas-praticas/contexto",
      },
      next: {
        name: "Continuar el camino",
        slug: "boas-praticas/apropriacao-cultural",
      },
    },
    {
      index: 2,
      name: "Apropiación cultural",
      slug: 'apropriacao-cultural',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
      },
      hero: {
        name: "Apropiación <br/> Cultural",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
        columns: [
          [
            '<b>En toda América Latina hay tradiciones culturales que deben ser enaltecidas por lo que son.</b>',
            'Los pueblos indígenas, por ejemplo, son numerosos y no deben ser representados como si fueran un bloque homogéneo.',
            'Debemos tener cuidado con las representaciones superficiales de las culturas milenarias.',
            'Como ocurre cuando las vestimentas que tienen un valor ritual y religioso para un grupo son usadas por personas y cuerpos no indígenas. Los elementos de una cultura no pueden sacarse de un contexto y ponerse simplemente en otro.',
            '<span><b>El nombre de eso es <div class="tooltip-container">apropiación cultural<div class="tooltip-text bg-black">Consiste en el uso de símbolos o valores de culturas subrepresentadas de forma ligera y superficial, lo que provoca banalización y violencia. Las marcas no deben apropiarse de la historia de las personas para simplemente vender productos o lograr sus objetivos.</div></div>, y su impacto puede ser negativo para las marcas y las compañías.</b></span>',
          ],
          [
            '¿Cómo se representan los pueblos y sus símbolos en sus campañas?',
            '<b>Recordemos que los indígenas viven en los pueblos, pero también viven en las ciudades, también conducen coches, estudian en las universidades, trabajan en el Congreso Nacional.</b>',
            'En caso de duda, contrate a personas indígenas, hable con expertos y/o estudie sobre los debates antirracistas indígenas.',
          ],
        ]
      },
      prev: {
        name: "Ir al Local",
        slug: "boas-praticas/local",
      },
      next: {
        name: "Continuar el camino",
        slug: "boas-praticas/descontextualizacao",
      },
    },
    {
      index: 3,
      name: "Descontextualización",
      slug: 'descontextualizacao',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
      },
      hero: {
        name: "Descontex- tualización",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
        columns: [
          [
            '<b>Los símbolos pierden fuerza de identificación cuando se mueven de una realidad nacional a otra.</b>',
            'Por ejemplo, si en una campaña  para el público joven brasileño, el tema se basa en las escuelas secundarias estadunidenses, el público objetivo tendrá dificultades para identificarse con el contenido.',
            '<span><b>Si la intención es comunicar al público latinoamericano, ¿por qué reproducir lo que funciona en Estados Unidos?</b></span>',
            'En un proceso creativo estratégico, se tiene en cuenta al público objetivo desde el principio, conociéndolo y valorando sus particularidades para generar identificación.',
          ],
          [
            '<b>Crear una comunicación que dialogue efectivamente con el otro exige la verdad, es decir, exige mensajes que se basen en hechos concretos.</b>',
            'La publicidad estratégica no debería basarse en las suposiciones de los profesionales de un país sobre lo que quiere el público de otro.',
            '<b>La publicidad aspiracional es una mera idealización y no una realidad.</b>  La investigación y los datos, sí, aportan seguridad sobre los contextos nacionales y los deseos de cada objetivo.',
          ],
        ]
      },
      prev: {
        name: "Ir al Apropiación Cultural",
        slug: "boas-praticas/apropriacao-cultural",
      },
      next: {
        name: "Continuar el camino",
        slug: "boas-praticas/lideranca",
      },
    },
    {
      index: 4,
      name: "Liderazgo",
      slug: 'lideranca',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
      },
      hero: {
        name: "Liderazgo",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
        columns: [
          [
            '<b>Para cada público objetivo, el líder debe ser capaz de evaluar si el mensaje de una campaña contempla la realidad a la que se dirige.</b>',
            '¿Has pensado en esto? En caso afirmativo, ¿cómo se validan sus campañas? ¿Cuáles son los criterios utilizados? ¿Son adecuados? ¿Quiénes son las personas que participan en este proceso de certificación de una campaña diversa e inclusiva?',
            '<span><b>El compromiso con la validación es importante porque no todas las ideas que parecen buenas producen un impacto positivo, sea en el público sea en las ventas.</b></span>',
          ],
          [
            'Haz un recorrido por los impactos generados por las campañas tanto a nivel interno, en lo que respecta a la empresa, como a nivel externo, en lo que respecta a la aceptación de la gente.',
            '<b>¿Cuáles son las métricas y los indicadores de diversidad utilizados para evaluar los resultados?</b>',
            '¿Permiten a la compañía saber qué suelo pisa a medida que el mundo cambia? ¿Permiten medir el alcance de su campaña teniendo en cuenta el género, la sexualidad, la clase y la raza?',
            'Los líderes y gestores que promueven una cultura organizacional preocupada con la diversidad, la validación y los resultados son más propensos a ser innovadores e inclusivo.',
          ],
        ]
      },
      prev: {
        name: "Ir al Descontex- tualización",
        slug: "boas-praticas/descontextualizacao",
      },
      next: {
        name: "Continuar el camino",
        slug: "diversidade",
      },
    },
  ]
};

export function getDiversityES() {
  return diversity
}
export function getRepresentsES() {
  return represents
}
export function getStereotypesES() {
  return stereotypes
}
export function getRoutineES() {
  return routine
}
export function getPracticesES() {
  return practices
}
