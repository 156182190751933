import React, { useEffect, useState } from 'react'
import * as S from './styled'

const Overlay = ({ color, bg, texture, perfil }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

	useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

	const posY = scrollPosition / 5
	
  return (
    <S.Container color={color} bg={bg} perfil={perfil}>
      <S.Texture style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/texture${texture ? `-${texture}` : ''}.png)`}} />
      <svg x="0px" y="0px" viewBox="0 0 1920 4350" style={{top: -posY}}>
        <g className='arrow'>
	        <path className="st0" d="M-665.5,464h979.8" />
	        <path className="st1" d="M231.4,566.2l-17.1-18.3l90.3-83.9l-90.2-84l17.1-18.3l110,102.2L231.4,566.2z"/>
        </g>
        <g className='circle'>
	        <path className="st2" d="M260.1,2277.2l7.9-316.7"/>
	        <path className="st2" d="M358.3,2264.1L268,1960.5"/>
	        <path className="st2" d="M447.7,2221.4L268,1960.5"/>
	        <path className="st2" d="M519.5,2153L268,1960.5"/>
	        <path className="st2" d="M566.7,2065.9L268,1960.5"/>
	        <path className="st2" d="M584.6,1968.4l-316.7-7.9"/>
	        <path className="st2" d="M571.6,1870.2L268,1960.5"/>
	        <path className="st2" d="M528.8,1780.7L268,1960.5"/>
	        <path className="st2" d="M460.5,1708.9L268,1960.5"/>
	        <path className="st2" d="M373.3,1661.8L268,1960.5"/>
	        <path className="st2" d="M275.8,1643.8l-7.9,316.7"/>
	        <path className="st2" d="M177.6,1656.9l90.4,303.6"/>
	        <path className="st2" d="M88.2,1699.7L268,1960.5"/>
	        <path className="st2" d="M16.4,1768L268,1960.5"/>
	        <path className="st2" d="M-30.8,1855.1L268,1960.5"/>
	        <path className="st2" d="M-48.7,1952.6l316.7,7.9"/>
	        <path className="st2" d="M-35.7,2050.9l303.6-90.3"/>
	        <path className="st2" d="M7.1,2140.3L268,1960.5"/>
	        <path className="st2" d="M75.4,2212.1L268,1960.5"/>
	        <path className="st2" d="M162.6,2259.3L268,1960.5"/>
        </g>
        <g className='spiral'>
          <path className="st3" d="M192.1,286.6c-55.2-236.1,91.5-472.2,327.6-527.4c188.9-44.1,377.8,73.2,421.9,262.1c35.3,151.1-58.6,302.2-209.7,337.5C611,387.1,490.1,312,461.9,191.1c-10.8-46.4-2.8-95.3,22.4-135.8c25.1-40.5,65.4-69.3,111.8-80.2c37.1-8.7,76.2-2.2,108.6,17.9c32.4,20.1,55.5,52.3,64.2,89.5c3.4,14.7,3.9,30,1.5,44.9c-2.5,14.9-7.8,29.2-15.8,42s-18.4,24-30.7,32.8s-26.2,15.1-40.9,18.5"/>
        </g>
        <g className='spiral'>
          <path className="st3" d="M1233.1,2062.3c-40.1-239.1,121.3-465.5,360.4-505.5c191.3-32.1,372.4,97,404.4,288.3c25.6,153-77.6,297.9-230.7,323.5c-122.4,20.5-238.3-62.1-258.8-184.5c-7.9-47,3.2-95.3,30.9-134.1c27.7-38.8,69.7-65.1,116.7-73c37.6-6.3,76.2,2.6,107.3,24.7c31.1,22.1,52.1,55.7,58.4,93.3c2.5,14.9,2,30.2-1.4,44.9c-3.4,14.7-9.7,28.6-18.4,40.9c-8.8,12.3-19.9,22.8-32.7,30.8c-12.8,8-27.1,13.4-42,15.9"/>
        </g>
        <g className='vase'>
          <path className="st4" d="M474.3,749.2c0,64.4-133.9,64.4-133.9,128.8s133.9,64.4,133.9,128.8s-133.8,64.4-133.8,128.8s133.9,64.4,133.9,128.8s-133.9,64.4-133.9,128.8s133.9,64.4,133.9,128.8"/>
	        <path className="st4" d="M-23.6,1507.1c-0.1-63.4,133.7-63.7,133.6-127.1c-0.1-63.4-134-63.2-134.1-126.6c-0.1-63.4,133.7-63.7,133.6-127.1c-0.1-63.4-134-63.2-134.1-126.6S109.2,936,109.1,872.6S-24.9,809.4-25,746"/>
	        <path className="st4" d="M506.2,2784.8H-6"/>
        </g>
        <g className='star'>
          <path className="st5" d="M1836.9,704L2073,846.2l251-113.9l-62.3,268.5l185.9,203.5L2173,1228l-136.1,239.7l-107.4-253.8l-270-55.4l208.2-180.6L1836.9,704z"/>
        </g>
        <g className='circle'>
          <path className="st2" d="M1687.1-193.5l-7.9,316.7"/>
	        <path className="st2" d="M1588.8-180.5l90.4,303.6"/>
	        <path className="st2" d="M1499.4-137.7l179.8,260.8"/>
	        <path className="st2" d="M1427.6-69.4l251.6,192.5"/>
	        <path className="st2" d="M1380.4,17.8l298.8,105.4"/>
	        <path className="st2" d="M1362.5,115.3l316.7,7.9"/>
	        <path className="st2" d="M1375.6,213.5l303.6-90.4"/>
	        <path className="st2" d="M1418.3,302.9l260.8-179.8"/>
	        <path className="st2" d="M1486.7,374.7l192.5-251.6"/>
	        <path className="st2" d="M1573.8,421.9l105.4-298.8"/>
	        <path className="st2" d="M1671.3,439.9l7.9-316.7"/>
	        <path className="st2" d="M1769.5,426.8l-90.4-303.6"/>
	        <path className="st2" d="M1858.9,384l-179.8-260.9"/>
	        <path className="st2" d="M1930.8,315.7l-251.6-192.5"/>
	        <path className="st2" d="M1977.9,228.5l-298.8-105.4"/>
	        <path className="st2" d="M1995.9,131.1l-316.7-7.9"/>
	        <path className="st2" d="M1982.8,32.8l-303.6,90.4"/>
	        <path className="st2" d="M1940-56.6l-260.8,179.8"/>
	        <path className="st2" d="M1871.7-128.4l-192.5,251.6"/>
	        <path className="st2" d="M1784.6-175.6l-105.4,298.8"/>
        </g>
        <g className='arrow'>
          <path className="st0" d="M-641.7,2502.7h979.8"/>
	        <path className="st1" d="M255.2,2605l-17.1-18.3l90.3-83.9l-90.3-83.9l17.1-18.3l110,102.3L255.2,2605z"/>
        </g>
        <g className='circle'>
          <path className="st2" d="M283.8,4316l7.9-316.7"/>
	        <path className="st2" d="M382.1,4302.9l-90.3-303.6"/>
	        <path className="st2" d="M471.5,4260.1l-179.8-260.9"/>
	        <path className="st2" d="M543.3,4191.8l-251.6-192.5"/>
	        <path className="st2" d="M590.5,4104.7l-298.7-105.4"/>
	        <path className="st2" d="M608.4,4007.2l-316.7-7.9"/>
	        <path className="st2" d="M595.4,3908.9l-303.6,90.4"/>
	        <path className="st2" d="M552.6,3819.5l-260.8,179.8"/>
	        <path className="st2" d="M484.3,3747.7l-192.5,251.6"/>
	        <path className="st2" d="M397.1,3700.5l-105.4,298.8"/>
	        <path className="st2" d="M299.6,3682.6l-7.9,316.7"/>
	        <path className="st2" d="M201.4,3695.7l90.4,303.6"/>
	        <path className="st2" d="M112,3738.4l179.8,260.9"/>
	        <path className="st2" d="M40.2,3806.8l251.6,192.5"/>
	        <path className="st2" d="M-9.5,3901.4l298.7,105.4"/>
	        <path className="st2" d="M-25,3991.4l316.7,7.9"/>
	        <path className="st2" d="M-11.9,4089.6l303.6-90.3"/>
	        <path className="st2" d="M30.9,4179l260.9-179.8"/>
	        <path className="st2" d="M99.2,4250.9l192.5-251.6"/>
	        <path className="st2" d="M186.4,4298l105.4-298.8"/>
        </g>
        <g className='spiral'>
          <path className="st3" d="M1256.8,4101.1c-40.1-239.1,121.3-465.5,360.4-505.5c191.3-32.1,372.4,97,404.4,288.3c25.6,153-77.6,297.9-230.7,323.5c-122.4,20.5-238.3-62.1-258.8-184.5c-7.9-47,3.2-95.3,30.9-134.1c27.7-38.8,69.7-65.1,116.7-73c37.6-6.3,76.2,2.6,107.3,24.7c31.1,22.1,52.1,55.7,58.4,93.3c2.5,14.9,2,30.2-1.4,44.9s-9.7,28.6-18.4,40.9c-8.8,12.3-19.9,22.8-32.7,30.8c-12.8,8-27.1,13.4-42,15.9"/>
        </g>
        <g className='vase'>
          <path className="st4" d="M498.1,2788c0,64.4-133.9,64.4-133.9,128.8c0,64.4,133.9,64.4,133.9,128.8c0,64.4-133.8,64.4-133.8,128.8c0,64.4,133.9,64.4,133.9,128.8s-133.9,64.4-133.9,128.8c0,64.4,133.9,64.4,133.9,128.8"/>
	        <path className="st4" d="M0.2,3545.9c-0.1-63.4,133.7-63.7,133.6-127.1c-0.1-63.4-134-63.2-134.1-126.6s133.7-63.7,133.6-127.1c-0.1-63.4-134-63.2-134.1-126.6c-0.1-63.4,133.7-63.7,133.6-127.1c-0.1-63.4-134-63.2-134.1-126.6"/>
	        <path className="st4" d="M479.4,746H-32.7"/>
        </g>
        <g className='star'>
          <path className="st5" d="M1774.7,2742.8l236.1,142.2l251-113.9l-62.3,268.5l185.9,203.5l-274.6,23.7l-136.1,239.7l-107.4-253.8l-270-55.4l208.2-180.6L1774.7,2742.8z"/>
        </g>          
      </svg>
    </S.Container>     
  )
}
export default Overlay