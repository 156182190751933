let classes = [
  {
    title: "Igualdad",
    text: [
      '<p><b>Es cuando tratamos a todos por igual.</b> Como si en una corrida todos partieran del mismo punto de partida, pero unos llevasen mochilas más pesadas y otros más ligeras.</p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_01.jpg`
  },
  {
    title: "Desigualdad",
    text: [
      '<p>Es cuando las personas no tienen acceso a las mismas oportunidades.</p>',
      '<p>Mientras que las clases sociales y económicas más favorecidas tienen acceso a buenas escuelas, buenas facultades y, en consecuencia, buenos empleos, <b>otras, por falta de acceso, no pueden ni siquiera participar en la corrida por las buenas oportunidades.</b></p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_03.jpg`
  },
  {
    title: "Equidad",
    text: [
      '<p><b>Es cuando tratamos a las distintas personas en función de sus diferencias,</b> teniendo en cuenta que cada una parte de un punto de partida específico, ya que llevan distintos pesos sobre sus espaldas.</p>'
    ],
    img : `${process.env.PUBLIC_URL}/assets/diversidade/classe_02.png`
  },

]
export function getClassesES() {
  return classes
}