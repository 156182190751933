import React from 'react'
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import GlobalStyle from './globalStyles'
import Home from './pages/Home/'
import Trilhas from './pages/Trilhas/'
import About from './pages/About/'
import Page from './pages/Page/'
import Subpage from './pages/Subpage/'
import PerfilSubpage from './pages/PerfilSubpage/'
import { diversity, represent, stereotypes, routine, practices } from './json/'

let profile = diversity()
profile = profile.menu.filter(item => item.slug === 'perfil')

const lang = process.env.REACT_APP_CURRENT_LANG

const title = lang === 'pt' ? "Guia da diversidade" : lang === 'en' ?  "Diversity guide" : lang === 'es' ? "Guía de la diversidad" : null

function App() {  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <GlobalStyle />

      <Routes>
        <Route path="/dos-and-donts" element={<About title="Do's and dont's" />} />
        <Route path="/" element={<Home />} />
        <Route path="/trilhas" element={<Trilhas />} />

        <Route path="/diversidade" element={ <Page data={ diversity() } /> } />
        <Route path="/representatividade" element={<Page data={ represent() } />} />
        <Route path="/esteriotipos" element={<Page data={ stereotypes() } />} />
        <Route path="/rotina" element={<Page data={ routine() } />} />
        <Route path="/boas-praticas" element={<Page data={ practices() } />} />

        <Route path="/diversidade/:pageId" element={<Subpage data={ diversity() } />} />
        <Route path="/representatividade/:pageId" element={<Subpage data={ represent() } />} />
        <Route path="/esteriotipos/:pageId" element={<Subpage data={ stereotypes() } />} />
        <Route path="/rotina/:pageId" element={<Subpage data={ routine() } />} />
        <Route path="/boas-praticas/:pageId" element={<Subpage data={ practices() } />} />

        <Route path="/diversidade/perfil/:pageId" element={<PerfilSubpage data={ profile[0].hero.cards } />} />
      </Routes>
    </>
  );
}
export default App;
