import React from 'react'
import * as S from './styled'
import { footer } from '../../json'

const Footer = ({ modal, intro }) => {
  const data = footer()
  return (
    <S.Container 
      modal={modal}
      intro={intro}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/footer-texture.png)` }}
    >
      <p>{data.text}</p>
      <p>{data.copyright}</p>
    </S.Container>     
  )
}
export default Footer
