import React from 'react'
import { useParams } from 'react-router-dom'
import CallToAction from '../../components/CallToAction'
import Cards from '../../components/Card'

import Raca from '../../components/Diversidade/Perfil/Raca'
import Genero from '../../components/Diversidade/Perfil/Genero'
import CorposPlurais from '../../components/Diversidade/Perfil/CorposPlurais'
import LGBTQIA from '../../components/Diversidade/Perfil/LGBTQIA'
import Classes from '../../components/Diversidade/Perfil/Classe'

import Infos from '../../components/Infos'
import Prev from '../../components/ArrowPrev'
import Next from '../../components/ArrowNext'

import Breadcrumb from '../../components/Breadcrumb'
import Layout from '../../containers/Layout'
import * as S from './styled'

function Subpage({ data }) {
  const params = useParams()
  const currentiItem = data.filter(item => item.slug === params.pageId)
  const cards = data
  let length = data.length - 1

  return (
    <Layout bg={currentiItem[0].color} texture={currentiItem[0].texture} modal={true}>
      <Breadcrumb data={data} current={currentiItem[0]} page="perfil" />
      <S.Container>
        <S.Hero>
          <Prev current={ currentiItem[0] } color="#fff" />
          <S.Carousel className={`hero-${currentiItem[0].slug}`} bg={currentiItem[0].color}>
            <S.Columns>
              <Infos current={currentiItem[0]} type={`hero-${currentiItem[0].slug}`} color={'#fff'} />
              <S.Content className='content' bg={currentiItem[0].color}>
                {currentiItem[0].slug === 'raca' ? 
                  <Raca data={ currentiItem[0].hero } />
                : currentiItem[0].slug === 'genero' ?
                  <Genero data={ currentiItem[0].hero.cards } margin={'0'} />
                : currentiItem[0].slug === 'corpos-plurais' ?
                  <CorposPlurais data={ currentiItem[0].hero.cards } margin={'0'} />
                : currentiItem[0].slug === 'lgbtqia' ?
                  <LGBTQIA data={ currentiItem[0].hero } /> 
                : currentiItem[0].slug === 'classe' ?
                  <Classes data={ currentiItem[0].hero } /> 
                : null }
              </S.Content>
            </S.Columns>
          </S.Carousel>
          <Next current={ currentiItem[0] } last={length} color="#fff" />
        </S.Hero>

        <S.Hero className='mobile'>
          <Prev current={ currentiItem[0] } color="#fff" />
          <Next current={ currentiItem[0] } last={length} color="#fff" />
        </S.Hero>

        <S.CardsContainer>
          <h3 style={{color: currentiItem[0].slug === 'lgbtqia' ? '#EA6833' : '#ffffff' }}>
            {process.env.REACT_APP_CURRENT_LANG === 'pt' ? 
              <span>Veja também</span>
            : process.env.REACT_APP_CURRENT_LANG === 'en' ? 
              <span>See too</span>
            : process.env.REACT_APP_CURRENT_LANG === 'es' ? 
              <span>Vea también</span>
            : null}
          </h3>
          <Cards data={cards} slug={'diversidade/perfil'} />
        </S.CardsContainer>
      </S.Container>
      <CallToAction />
    </Layout>
  )
}
export default Subpage
