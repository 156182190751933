let diversity = {
  color: '#F0AB1B',
  slug: "diversidade",
  title: "Diversity",
  description: `
    <span class="paragraph">Here you understand why business strategies need to consider the uniqueness of people and groups</span>
    “We are not discussing philantrophy, but the sustentability of a business, positive impact and also the well-being and people's development”.
  `,
  cite: "Helena Bertho, Global Director of Diversity and Inclusion from Nubank.",
  menu: [
    {
      index: 0,
      name: "Strategy",
      slug: "estrategia",
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-01.png`,
        description: [
          "Diversity is a business strategy and an opportunity to positively impact an entire productive chain. Diversity is not just a matter of advertising campaign.",
          "You are on trail: DIVERSITY."
        ],
      },
      hero: {
        name: "Strategy",
        color: '#E54A5A',
        description: [
          "Diversity is a business strategy and an opportunity to positively impact an entire productive chain. Diversity is not just a matter of advertising campaign. You are on trail: DIVERSITY."
        ],
        grid: [
          {
            span: 1,
            color: '#EA6833',
            title: 'Impact',
            text: [
              'Diverse teams with continuing education create innovative strategies capable of cativating a larger audience.',
              'In companies commited to diversity, <b>"employees are 64% more compelled to share ideas and good practices".</b>',
              '<b>(Diversity Wins, - McKinsey, 2020)</b>'
            ]
          },
          {
            span: 1,
            color: '#E54A5A',
            title: 'Organizational Atmosphere',
            text: [
              '"Withouth diversity, the company looses their market value, looses in the organizational atmosphere, in the trust and respect of its employees."',
              'Thais Batista, Intern Communication Senior Analyst from Schneider Electric. (Olabi, 2021)',
              '<b>And year after year, McKinsey data reaffirms that</b>',
            ]
          },
          {
            span: 2,
            color: '#3594C9',
            title: 'Lucrativiness',
            text: [
              '<span>2018</span> "Companies with boards of directors with bigger ethnic/cultural diversity around all world are <b>43% more</b> likely to get higher profits.</br>" <b>(Delivering Through Diversity - McKinsey, 2018)</b>',
              '<span>2020</span> “Companies that invest in ethnic-racial diversity are <b>36% more prone</b> to get profits”<br/><b>(Diversity Wins - McKinsey, 2020)</b>',
            ]
          },
        ],
      },
      prev: {
        name: "Go to Innovation",
        slug: "diversidade/perfil",
      },
      next: {
        name: "Keep on trail",
        slug: "diversidade/inovacao",
      },
    },
    {
      index: 1,
      name: "Innovation",
      slug: 'inovacao',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-02.png`,
        description: [
          "Diversity makes it possible for innovative ideas to emerge, with quality products and criativity that a group with unique profile would have difficulty to thinking about and developing;",
          "<b>Alessandra Karine, from Microsoft Brasil <br/>(Olabi, 2021)</b>"
        ],
      },
      hero: {
        name: "Innovation",
        color: '#E54A5A',
        type: 'carousel',
        bg: false,
        description: [
          "Diversity makes it possible for innovative ideas to emerge, with quality products and criativity that a group with unique profile would have difficulty to thinking about and developing;",
          "<b>Alessandra Karine, from Microsoft Brasil <br/>(Olabi, 2021)</b>"
        ],
        carousel: [
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-01.png`,
            title: 'Buying Decision',
            text: [
              'The consumer audience is more and more attemptive to the <b>social responsibility</b> of companies, brands and organizations.',
              'The buying decision of <b>63% of brazilians</b> has diversity and inclusion as a criteria.',
              '<span><b>(Diversity and Inclusion - Facebook, 2020)</b></span>',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-02.png`,
            title: 'Adaptation',
            text: [
              'Diversity and sustainability in companies create organizacional <b>cultures capable of adapting to transformations in the world,</b> respecting the impacts suffered by people based on markers such as genre, race and class.',
            ]
          },
          {
            image: `${process.env.PUBLIC_URL}/assets/diversidade/carousel-03.png`,
            title: 'People',
            text: [
              '“Employees at companies that are commited to diversity are <b>152% more inclined to propose new ideas and 62% more motivated to collborate in teams</b>”.</b>',
              '<span>(Diversity Wins - McKinsey, 2020)</span>',
            ]
          },
        ],
      },
      prev: {
        name: "Go to Strategy",
        slug: "diversidade/estrategia",
      },
      next: {
        name: "Keep on trail",
        slug: "diversidade/publicidade-brasileira",
      },
    },
    {
      index: 2,
      name: "Brazilian Advertising",
      slug: 'publicidade-brasileira',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-03.png`,
        description: [
          "“You have to act as If it was possible to radically change the world. And you have to do it all the time.”.",
          "<b>Angela Davis</b>"
        ],
      },
      hero: {
        name: "Brazilian <br/> Advertising",
        color: '#F0AB1B',
        type: '',
        description: [
          "“You have to act as If it was possible to radically change the world",
          "And you have to do it all the time.”.",
          "<b>Angela Davis</b>"
        ],
      },
      prev: {
        name: "Go to Innovation",
        slug: "diversidade/inovacao",
      },
      next: {
        name: "Keep on trail",
        slug: "diversidade/perfil",
      },
    },
    {
      index: 3,
      name: "Profile",
      slug: 'perfil',
      card: {
        height: 500,
        position: 'default',
        disabled_link: false,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/diversidade/card-image-04.png`,
        description: [
          "Diversity breaks the profiles homogeneity and respect the brazilian reality made by plural stories."
        ],
      },
      hero: {
        name: "Profile",
        color: '#E54A5A',
        type: 'perfil',
        description: [
          "Diversity breaks the profiles homogeneity and respect the brazilian reality made by plural stories."
        ],
        cards: [
          {
            color: '#E54A5A',
            texture: 'red',
            name: "Gender",
            slug: 'genero',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#E54A5A',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero.svg`,
              description: [
                "It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
              ],
            },
            hero: {
              name: "Gender",
              color: '#E54A5A',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-genero-white.svg`,
              description: [
                `It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter <b class='black tooltip-container'>(elu/delu)<span class='tooltip-text'>Neutral language is the result of the claims of people who do not see themselves in a binary way, masculine or feminine, and propose that pronouns and adjectives be inflected without gender marking. The letters “e” and “u”, as in Elu/Delu, enrich our vocabulary, allowing “new” forms of naming and existence.</span></b> pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected.`
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/cisgenero.svg`,
                  name: "Cisgender",
                  description: [
                    "People who identify themselves with the genre assigned at birth.",
                    "<b>It's not a rule, so don't act like it was mandatory.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/trans.svg`,
                  name: `Trans people and <span class="tooltip-container">
                  "travestis"
                    <span class="tooltip-text bg-red short">
                      Latin American category used to refer to people who do not identify with the sex assigned at birth and have gender performance associated with the female. It is worth pointing out that the categories trans woman and transvestite emerged in specific historical periods and were associated with also specific values, with the transvestite identity often associated with social marginalization and prostitution. Assuming it is also a political act. Trans women can identify as women, regardless of whether they have had surgery, and transvestites must be treated as female. Both identities emerged associated with the feminine, but that does not mean that there are no non-binary transvestites or people who use the trans and transvestite categories at the same time.
                    </span>
                  </span>`,
                  description: [
                    "People who identify themselves with a different genre from the one attributed in their birth and determine their own identity.",
                    "<b>People are free to be whatever they want.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/nao-binarios.svg`,
                  name: "Non-binary",
                  description: [
                    "People who do not identify either with the masculine or feminine genre.",
                    "<b>Nobody needs do identify or label themselves.</b>",
                  ],
                }
              ]
            },
            prev: {
              name: "Go to Class",
              slug: "diversidade/perfil/classe",
            },
            next: {
              name: "Keep on trail",
              slug: "diversidade/perfil/raca",
            },
          },
          {
            color: '#EA6833',
            texture: 'orange',
            name: "Race",
            slug: 'raca',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#EA6833',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca.svg`,
              description: [
                "Social categorization that uses from phenotypic characteristics and results in inequality to black and indigenous people."
              ],
            },
            hero: {
              name: "Raça",
              color: '#EA6833',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-raca-white.svg`,
              description: [
                "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important."
              ],
              row: [
                {
                  class: "percent",
                  title: "IBGE race/color classification (IBGE - PNAD, 2012-2019)",
                  list: [
                    {
                      percent: "56,2%",
                      name: `Black and <span class="tooltip-container">
                                </br>"pardos"
                                <span class="tooltip-text bg-orange short">
                                  Unifying term used in the Brazilian census in the 2000s “for the person who qualifies as brown or declares himself to be mulatto, cabocla, cafuza, mameluca or mestiça” (Census taker's manual – IBGE, 2000). When the interviewee is asked his color, he has the option of answering brown, black, indigenous, yellow and white – the interviewer should not assume that black, for example, is synonymous with black or brown. It is up to the interviewee to self-classify. Although the Brazilian census of the 2000s suggests similarities between the brown and mestizo categories, we chose not to translate immediately because these categories have different social and administrative histories, especially with regard, in Brazil, to the relationship between mestizaje and the politics of whitening. of the nation.
                                </span>
                              </span>`,
                    },
                    {
                      percent: "42,7%",
                      name: "White"                      
                    },
                    {
                      percent: "0,7%",
                      name: "Asian"                      
                    },
                    {
                      percent: "0,4%",
                      name: "Indigenous"                      
                    }
                  ],
                  text: [
                    "Minorities, such as asian and indigenous, must be respected. And maijorities, like black and 'pardos' people, should not be minorized.",
                  ]
                },
                {
                  class: "colorism",
                  title: "Colorism",
                  text: [
                    "It's a form of discrimination based on the skin tone. The more pigmented a person is, the more exclusion he or she suffers. In advertising, colorism results in a preference for light-skin blacks, as if everyone who gets close to the white people was more human.",
                    "<b>'Black lives matter', no selectivity.</b>",
                  ]
                },
                {
                  class: "racism",
                  title: "Racism",
                  text: [
                    "Racism hapens when a group of power, historically white, discriminates against indigenous, black and asian people, among others. This discrimination can be violent or silent, but it always results in hierarchies: whites are raised to the top while other humans are inferiorized.",
                    "<b>Not being racist is not enough, you mut to be <span>antiracist!</span></b>",
                  ]
                }
              ]
            },
            prev: {
              name: "Go to Gender",
              slug: "diversidade/perfil/genero",
            },
            next: {
              name: "Keep on trail",
              slug: "diversidade/perfil/lgbtqia",
            },
          },
          {
            color: '#F0AB1B',
            texture: 'yellow',
            name: "LGBTQIA+",
            slug: 'lgbtqia',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#F0AB1B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia.svg`,
              description: [
                "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
              ],
            },
            hero: {
              name: "LGBTQIA+",
              color: '#EA6833',
              type: 'carousel',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-lgbtqia-white.svg`,
              description: [
                "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities.",
              ],
              carousel: [
                {index:0},
                {index:1},
                {index:2},
              ],
            },
            prev: {
              name: "Go to Race",
              slug: "diversidade/perfil/raca",
            },
            next: {
              name: "Keep on trail",
              slug: "diversidade/perfil/corpos-plurais",
            },
          },
          {
            color: '#94C15B',
            texture: 'green',
            name: "Plural Bodies",
            slug: 'corpos-plurais',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#94C15B',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais.svg`,
              description: [
                "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
              ],
            },
            hero: {
              name: "Plural Bodies",
              color: '#94C15B',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-corpos-plurais-white.svg`,
              description: [
                "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
              ],
              cards: [
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/capacitismo.svg`,
                  name: "Ableism",
                  description: [
                    "Prejudice against people with disabilities. To believe that some human beigns are inferior and/or less capable than other because of some of its characteristics.",
                    "<b>Different is who does not respect the differences of the other</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/etarismo.svg`,
                  name: "Ageism",
                  description: [
                    "Intoleranc against individuals or age groups based on the stereotypes related to age. Older people are not limited.",
                    "<b>Limited is this thought.</b>",
                  ],
                },
                {
                  image: `${process.env.PUBLIC_URL}/assets/diversidade/gordofobia.svg`,
                  name: "Fatphobia",
                  description: [
                    "Discriminatory manifestations against fat people that result on an erasure and belittlement of their bodies.",
                    "<b>Do not will to change people's form, but the way how you see them.</b>",
                  ],
                }
              ]
            },
            prev: {
              name: "Go to LGBTQIA+",
              slug: "diversidade/perfil/lgbtqia",
            },
            next: {
              name: "Keep on trail",
              slug: "diversidade/perfil/classe",
            },
          },
          {
            color: '#3594C9',
            texture: 'blue',
            name: "Class",
            slug: 'classe',
            card: {
              height: 420,
              position: 'default',
              disabled_link: true,
              color: '#3594C9',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe.svg`,
              description: [
                "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
              ],
            },
            hero: {
              name: "Class",
              color: '#3594C9',
              type: 'card',
              image: `${process.env.PUBLIC_URL}/assets/diversidade/perfil-classe-white.svg`,
              description: [
                "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
              ],
            },
            prev: {
              name: "Go to Plural Bodies",
              slug: "diversidade/perfil/corpos-plurais",
            },
            next: {
              name: "Keep on trail",
              slug: "representatividade",
            },
          }
        ]
      },
      prev: {
        name: "Ir para Advertising",
        slug: "diversidade/publicidade-brasileira",
      },
      next: {
        name: "Keep on trail",
        slug: "diversidade/perfil/genero",
      },
    },
  ]
};

let represents = {
  color: '#EA6833',
  slug: 'representatividade',
  title: "Representativeness",
  description: `
    <span class="paragraph">Here you will find arguments and numbers that help you to realize that it really matters to bring different bodies to advertising</span>
    In a changing world, opening spaces to mulptiple <span class='tooltip-container'>identities<span class='tooltip-text bg-orange'>The set of attributes that characterize a person, being able to distinguish him or to locate him in a group. For example, Beyoncé is a black cisgender woman. This is your identity.</span></span> is necessary to deviate from the danger of an unique story about who people are and what they can be.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Gender",
      slug: 'genero-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
      },
      hero: {
        name: "Gender",
        subtitle: 'Brazil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
        row: [
          '<p>Men, women, cis, trans, "travestis" or non-binary people must be respresented in advertising and companies, <b>but some people are made invisible simply because who they are.</b></p>',
          `
            <div class="box">
              <div class="percent">74%</div>
              <div class="text">
                <p>of brazilian women that are represented are white.</p>
                <p class="legend"><b>(Todxs, 9º Onda)</b></p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>There is little data on trans people. This is also a consequece of invisibility. And what do we know? That Brazil is the country that most kill trans woman and "travestis" by the 13º consecutive year.</p>
                <p class="legend"><b>(Antra, 2022)</b></p>
              </div>
            </div>
          `,
          '<p>Advertising must use its reach to reinforce the idea that all people deserve to occupy spaces equally.</p>',
        ],
      },
      prev: {
        name: "Go to Class Latam",
        slug: "representatividade/classe-latam",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/genero-latam",
      },
    },
    {
      index: 1,
      name: "Gender",
      slug: 'genero-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero.svg`,
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
      },
      hero: {
        name: "Gênero",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
        color: '#E54A5A',
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (ele/his), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
        text_title: "This is not just in Brazil.",
        text: [
          "<p>For all Latin America, women and LGBTQIA+ still fighting for more equality and inclusion. <b>Whitin these two groups, there's a high rate of people who consider these claims important.</b> This shows how they are the most comitted to not only understanding, but also chasing what they believe.</p>",
        ],
        table_title: "Agenda Relevence",
        table: {
          type: "title",
          row_1: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/genero-black.svg`,
              title: "Women"
            },
            {
              color: "#64193A",
              percent: "91%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#792240",
              percent: "90%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#97314A",
              percent: "89%",
              country: "Mexico",
              flag: "🇲🇽"
            },
            {
              color: "#B54253",
              percent: "88%",
              country: "Brazil",
              flag: "🇧🇷"
            },
            {
              color: "#D4565E",
              percent: "88%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#DF7C7A",
              percent: "85%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ],
          row_2: [
            {
              img: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
              title: "LGBTQIA+"
            },
            {
              color: "#64193A",
              percent: "72%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "70%",
              country: "Brazil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "67%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#D4565E",
              percent: "64%",
              country: "Mexico",
              flag: "🇲🇽"
            },
            {
              color: "#DF7C7A",
              percent: "52%",
              country: "Peru",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversity and Inclusion - Facebook, 2020)",
      },
      prev: {
        name: "Go to Gender Brazil",
        slug: "representatividade/genero-brasil",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/raca-brasil",
      },
    },
    {
      index: 2,
      name: "Race",
      slug: 'raca-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important.",
        ],
      },
      hero: {
        name: "Race",
        subtitle: 'Brazil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important.",
        ],
        row: [
          '<p>We know that Brazil has a racist structure that privileges white people to the detriment of black and indigenous people.</p>',
          "<p><b>It's necessary to change the scenario and build teams with racial diversity so that minoritized maiorities be contemplated in their plurality.</b></p>",
          `
            <div class="box">
              <div class="text black">
                <p>Black woman are only <span>3%</span> among leaders in companies.</p>
                <p class="legend">Available in: <a href="https://www.terra.com.br/nos/mulheres-negras-sao-apenas-3-entre-lideres-nas-empresas-diz-estudo,cc51a786e4402676743ce2163f45fce9hj9rhyxq.html" target="_blank">Mulheres negras são apenas 3% entre líderes nas empresas, diz estudo</a> (terra.com.br)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>Only <span>4%</span> of the soap operas of the main Brazilian broadcaster were carried out by non-white women and <span>1%</span> by non-white men.</p>
                <p class="legend">(GEMAA, 2015)</p>
              </div>
            </div>
          `,
          '<p><b>The casting needs to reflect the diversity among black and indigenous people, including dark-skinned people and indigenous peolple in prominent positions.</b></p>',
        ],
      },
      prev: {
        name: "Go to Gender Latam",
        slug: "representatividade/genero-latam",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/raca-latam",
      },
    },
    {
      index: 3,
      name: "Race",
      slug: 'raca-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca.svg`,
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important.",
        ],
      },
      hero: {
        name: "Race",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/raca-black.svg`,
        color: '#EA6833',
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important.",
        ],
        text_title: "This is not just in Brazil",
        text: [ 
          "<p><b>In the eyes of the latin-american population, there is a lack of diversity and inclusion in campaigns,</b> as advertising still insists on reproducing stereotypes - even racial ones.</p>",
          "<p>In most part, more than half of the people interviewees agree that the ads retrate/depict black or indigenous people in a <b>less prominent position.</b></p>"
        ],
        table_title: "Lack of importance of non-white people",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "60%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "60%",
              country: "Mexico",
              flag: "🇲🇽"
            },
            {
              color: "#97314A",
              percent: "59%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "54%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "49%",
              country: "Brazil",
              flag: "🇧🇷"   
            },
            {
              color: "#DF7C7A",
              percent: "48%",
              country: "Argentina",
              flag: "🇦🇷"
            }
          ]
        },
        legend: "(Diversity and Inclusion - Facebook, 2020)",
      },
      prev: {
        name: "Go to Race Brazil",
        slug: "representatividade/raca-brasil",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/lgbtqia-brasil",
      },
    },
    {
      index: 4,
      name: "LGBTQIA+",
      slug: 'lgbtqia-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Brazil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
        row: [
          '<p><b>Lesbians, gays, bisexuals, transgenders, "travestis", queer, intersex and asexuals are some of the identities that the acrynom LGBTQIA+ contemplates,</b> valuing and respecting the diversity of genres, bodies and sexualities.</p>',
          `
            <div class="box center">
              <p>Knowing is importante, but it is not everything.</p>
            </div>
          `,
          '<p><b>LGBTQIA+ people must be represented in advertising throughout year, not just in comemorative dates or highlight periods. Otherwiser, brands will be read as only interested in <span class="tooltip-container">Pink Money<span class="tooltip-text bg-yellow">Term used to characterize the commercialization of products for the LGBTQIA+ public with the sole purpose of increasing consumption potential.</span></span>.</b></p>',
        ],
      },
      prev: {
        name: "Go to Race Latam",
        slug: "representatividade/raca-latam",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/lgbtqia-latam",
      },
    },
    {
      index: 5,
      name: "LGBTQIA+",
      slug: 'lgbtqia-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia.svg`,
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/lgbtqia-black.svg`,
        color: '#F0AB1B',
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
        text_title: "This is not just in Brazil",
        text: [
          "<p>Companies that do not act ethically can be badly receveid by the latin-american LGBTQIA+ community. <b>When they position themselves in favor of inclusion only in restrict periods, brands can be seen as opportunistic.</b> Most part of the interviewees prefer to buy from companies that really care about diversity and inclusion.</p>",
        ],
        table_title: "Buying decision",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "76%",
              country: "Brazil",
              flag: "🇧🇷"
            },
            {
              color: "#792240",
              percent: "71%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#97314A",
              percent: "70%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "65%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#D4565E",
              percent: "60%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "58%",
              country: "Mexico",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversity and Inclusion / LGBTQIA+ - Facebook, 2021)",
      },
      prev: {
        name: "Go to LGBTQIA+ Brazil",
        slug: "representatividade/lgbtqia-brasil",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/corpos-plurais-brasil",
      },
    },
    {
      index: 6,
      name: "Plural Bodies",
      slug: 'corpos-plurais-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
      },
      hero: {
        name: "Plural <br/> Bodies",
        subtitle: 'Brazil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
        row: [
          '<p>We are diverse by nature. <b>However, some people are invalidated for their differences,</b> such as fat people, people with disabilities, elderly and other underrepresented groups.</p>',
          `
            <div class="box">
              <div class="text black">
                <p>Advertising is fatphobic. Only <span>1%</span> of characters in campaigns are fat. <span>16%</span> have a plus size bodies</p>
                <p class="legend">(Todx, 6º Onda)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text black">
                <p>About <span>15%</span> of the world's population, more than one billion people, has a disability, yet PWDs are poorly represented in advertising campaigns</p>
                <p class="legend">(Relatório Mundial sobre a deficiência - OMS, 2011; Dados, Diversidade e Representação - Facebook)</p>
              </div>
            </div>
          `,
          `<p><b>It is the role of advertising to pluralize the representations of bodies and people, prioritizing everyday scenes and undoing <span class="tooltip-container">exoticisms.<span class="tooltip-text bg-green short">Term used when we reduce someone's expression to the mere quality of exotic, strange, vulgar or extravagant.</span></span>.</b></p>`,
        ],
      },
      prev: {
        name: "Go to LGBTQIA+ Latam",
        slug: "representatividade/lgbtqia-latam",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/corpos-plurais-latam",
      },
    },
    {
      index: 7,
      name: "Plural Bodies",
      slug: 'corpos-plurais-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais.svg`,
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
      },
      hero: {
        name: "Plural <br/> Bodies",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/corpos-plurais-black.svg`,
        color: '#94C15B',
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
        text_title: "This is not just in Brazil",
        text: [
          "<p>People with disabilities from Latin America, in addition to considering that <b>ads in general are still very steretyped, do not identify with what is represented.</b> Most part of the interviewees that</p>",
          "<p>have or live with someone with disabilities consider that most part of the ads still primarily represent white, thin and rich people.</p>",
        ],
        table_title: "Lack of diversity",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "80%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#792240",
              percent: "73%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#97314A",
              percent: "72%",
              country: "Brazil",
              flag: "🇧🇷"
            },
            {
              color: "#B54253",
              percent: "71%",
              country: "Mexico",
              flag: "🇲🇽"
            },
            {
              color: "#D4565E",
              percent: "68%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#DF7C7A",
              percent: "67%",
              country: "Peru",
              flag: "🇵🇪"
            }
          ]
        },
        legend: "(Diversity and Inclusion / Disabled People - Facebook, 2021)",
      },
      prev: {
        name: "Go to Class Brazil",
        slug: "representatividade/corpos-plurais-brasil",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/classe-brasil",
      },
    },
    {
      index: 8,
      name: "Class",
      slug: 'classe-brasil',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
        ],
      },
      hero: {
        name: "Class",
        subtitle: 'Brazil',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
        ],
        row: [
          '<p>Social inequality characterizes societies that have a high level of income concentration.</p>',
          '<p><b>This phenomenon impacts who are the people that produce content and who are those that are represented</b></p>',
          `
            <div class="box">
              <div class="text">
                <p>“White men in the richest 1% appropriate more income than all black women in the country.”</p>
                <p class="legend">(NPE nº18, Made USP)</p>
              </div>
            </div>
          `,
          `
            <div class="box">
              <div class="text">
                <p>69% of Brazilians consider that the labor market is unequal, especially for minorities and minority groups.</p>
                <p class="legend">(Diversity and Inclusion - Facebook, 2020)</p>
              </div>
            </div>
          `,
          '<p><b>Teams without diversity, even if motivated by good intentions, are more likely to represent class symbols out of context and tone. This can happen, for example, when casting and costumes sound like caricatures.</b></p>',
        ],
      },
      prev: {
        name: "Go to Plural Bodies Latam",
        slug: "representatividade/corpos-plurais-latam",
      },
      next: {
        name: "Keep on trail",
        slug: "representatividade/classe-latam",
      },
    },
    {
      index: 9,
      name: "Class",
      slug: 'classe-latam',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe.svg`,
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
        ],
      },
      hero: {
        name: "Classe",
        subtitle: 'Latam',
        image: `${process.env.PUBLIC_URL}/assets/representatividade/classe-black.svg`,
        color: '#3594C9',
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
        ],
        text_title: "Isso não é só no Brasil",
        text: [
          "<p>Although diverse companies in Latin America are more likely to outperform their competitors <b>(Diversity Matters - Mckinsey, 2020)</b>, the labor market is unequal in the perception of Latin Americans. <b>Most of the interviewees believe that job opportunities are restricted to historically privileged social groups.</b></p>",
        ],
        table_title: "Less opportunities",
        table: {
          row_1: [
            {
              color: "#64193A",
              percent: "69%",
              country: "Argentina",
              flag: "🇦🇷"
            },
            {
              color: "#792240",
              percent: "69%",
              country: "Brazil",
              flag: "🇧🇷"
            },
            {
              color: "#97314A",
              percent: "68%",
              country: "Colombia",
              flag: "🇨🇴"
            },
            {
              color: "#B54253",
              percent: "68%",
              country: "Peru",
              flag: "🇵🇪"
            },
            {
              color: "#D4565E",
              percent: "63%",
              country: "Chile",
              flag: "🇨🇱"
            },
            {
              color: "#DF7C7A",
              percent: "62%",
              country: "Mexico",
              flag: "🇲🇽"
            }
          ]
        },
        legend: "(Diversity and Inclusion - Facebook, 2020)",
      },
      prev: {
        name: "Go to Class Brazil",
        slug: "representatividade/classe-brasil",
      },
      next: {
        name: "Keep on trail",
        slug: "esteriotipos",
      },
    },
  ]
};

let stereotypes = {
  color: '#94C15B',
  slug: 'esteriotipos',
  title: "Stereotypes",
  description: `
    <span class="paragraph">Here you find out what to do and what not to do every time you are creatively designing a campaign</span>
    Escape the commonplace! Stories must be told in different ways, with respect to the people and with misleading generalizations. Reality is complex.
    `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Gender",
      slug: 'genero',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#E54A5A',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero.svg`,
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (he/him), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
      },
      hero: {
        name: "Gender",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/genero-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image01.png`,
        description: [
          "It concerns how we identify ourselves and should be called. The proper use of masculine (he/him), feminine (she/her) and neuter (elu/delu) pronouns is a form of social recognition and affirmation of a dignified existence. Trans and non-binary people must be respected."
        ],
        columns: [
          {
            title: 'Did you know?',
            text: 'Society defines different gender roles to men and woman, concerning profession, appearance, posture, among others.',
          },
          {
            title: 'Now you know!',
            text: 'Do not limit women image only to appearance and care. Also do not reproduce the idea that all men need to be virile, manly and insensible.',
          },
          {
            title: 'What to do?',
            text: 'Campaigns can value woman in different positions, how leadership and strength, and reveal other male characteristics, like the sensibility and companionship.',
          },
        ]
      }, 
      prev: {
        name: "Go to Class",
        slug: "esteriotipos/classe",
      },
      next: {
        name: "Keep on trail",
        slug: "esteriotipos/raca",
      },
    },
    {
      index: 1,
      name: "Race",
      slug: 'raca',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#EA6833',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca.svg`,
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important."
        ],
      },
      hero: {
        name: "Race",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/raca-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image02.png`,
        description: [
          "It has to do with the historical use of body traits, such as curly hair, black skin color and certain shapes of eyes and noses, as signs of inferiority. That is why the struggle for the affirmation of black and indigenous pride is so important."
        ],
        columns: [
          {
            title: 'Did you know?',
            text: 'Superficial representations of the stories of black, indigenous and asian people are a way of violence. Racism makes the other invisible, limits and/or encloses.',
          },
          {
            title: 'Now you know!',
            text: 'Thus, do not retract/portay black people in a hypersexualized or angry way. Do not define indigenous people as lazy or naive. Nor create the idea that asian people are a minority model and without flaws.',
          },
          {
            title: 'What to do?',
            text: 'Advertising must refuse the stereotypes of race and higlight the presence of black, indigenous  and asian people in different spaces, functions and relations.',
          },
        ]
      },
      prev: {
        name: "Go to Gender",
        slug: "esteriotipos/genero",
      },
      next: {
        name: "Keep on trail",
        slug: "esteriotipos/lgbtqia",
      },
    },
    {
      index: 2,
      name: "LGBTQIA+",
      slug: 'lgbtqia',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#F0AB1B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia.svg`,
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
      },
      hero: {
        name: "LGBTQIA+",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/lgbtqia-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image03.png`,
        description: [
          "The umbrella acronym represents the political and social movement that defends gender diversity and sexualities."
        ],
        columns: [
          {
            title: 'Did you know?',
            text: 'LGBTQIA+ people feel less represented in campaigns*. This happens not only because they are less presents, but also because thy are talked about them in a caricatured way.<p><b>(Diversity and Inclusion - Facebook, 2020)</b></p>',
          },
          {
            title: 'Now you know!',
            text: `So, don't relate gay men identity to female symbols <b class="tooltip-container" style="z-index: 3"><u>only</u><span class="tooltip-text bg-yellow short">There are gay men who adhere to feminine symbols, there are gay men who adhere to masculine symbols, and there are gay men who reject both symbols. All expressions are valid. The important thing here is not to limit a plural identity to a single representation of what it is to be gay.</span></b> nor the identity of lesbian women <b class="tooltip-container" style="z-index: 2"><u>only</u><span class="tooltip-text bg-yellow">There are lesbian women who adhere to feminine symbols, there are lesbian women who adhere to masculine symbols, and there are lesbian women who reject both symbols. All expressions are valid. The important thing here is not to limit a plural identity to a single representation of what it is to be a lesbian.</span></b> to masculine symbols. Sexuality and <b class="tooltip-container"><u>genre expression</u><span class="tooltip-text bg-yellow">The way someone publicly manifests their gender identity, whether through name, dress, symbols or behavior. All gender expressions are valid regardless of the person's gender identity.</span></b> are distinct themes! <b>In the same way, do not treat trans people and "travestis" with the comic relief or with exoticization.</b>`,
          },
          {
            title: 'What to do?',
            text: "It's advertising's role to broaden the range of LGBTQIA+ representation, without always beting in a suffering narrative, satire or overcoming.",
          },
        ]
      },
      prev: {
        name: "Go to Race",
        slug: "esteriotipos/raca",
      },
      next: {
        name: "Keep on trail",
        slug: "esteriotipos/corpos-plurais",
      },
    },
    {
      index: 3,
      name: "Plural Bodies",
      slug: 'corpos-plurais',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#94C15B',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais.svg`,
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
      },
      hero: {
        name: "Plural <br/> Bodies",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/corpos-plurais-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image04.png`,
        description: [
          "No body is the same and this difference must be valued and respected by the advertising market. Understand wich ways of violence these bodies can suffer in order to do not reproduce them."
        ],
        columns: [
          {
            title: 'Did you know?',
            text: "The majority presence of white and thin people in campaigns (66%)* keep people with disabilities, fat people and elderly away. Include them is not enough, it's necessary to represent them with dignity.<p><b>(Diversity e Inclusion - Facebook, 2020)</b></p>",
          },
          {
            title: 'Now you know!',
            text: 'Do not reduce fat people to humor situations only. Do not always treat elderly as grumpy or dependents. Also do not limit PwDs stories with only cases of overcoming or sadness',
          },
          {
            title: 'What to do?',
            text: 'Create spaces to demystify taboos around these bodies. Represent PwDs occupying various spaces, like labor market and sports, fat people as a symbol of beauty and personal security; and elderly as capable and happy.',
          },
        ]
      },
      prev: {
        name: "Go to LGBTQIA+",
        slug: "esteriotipos/lgbtqia",
      },
      next: {
        name: "Keep on trail",
        slug: "esteriotipos/classe",
      },
    },
    {
      index: 4,
      name: "Class",
      slug: 'classe',
      card: {
        height: 420,
        position: 'default',
        disabled_link: true,
        color: '#3594C9',
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe.svg`,
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians."
        ],
      },
      hero: {
        name: "Class",
        image: `${process.env.PUBLIC_URL}/assets/esteriotipos/classe-black.svg`,
        banner: `${process.env.PUBLIC_URL}/assets/esteriotipos/banner-image05.png`,
        description: [
          "Economic inequality implies in the inequality of opportunities and affects the life quality of most brazilians. "
        ],
        columns: [
          {
            title: 'Did you know?',
            text: 'Consumption patterns, desire and behavior can cross social classes. For many times, prejudice creates barriers, making people and their taste being related exclusively to the amount of money they have.',
          },
          {
            title: 'Now you know!',
            text: 'Class C members must not be seen only as eager to wealth or as people with questionable visual styles, while members of higher classes are heroes, kind and respectable.',
          },
          {
            title: 'What to do?',
            text: "Bet on stories of real people, breaking the assumption that there are places a class can't go or ways to behave in those places that are more appropriate.",
          },
        ]
      },
      prev: {
        name: "Go to Plural Bodies",
        slug: "esteriotipos/corpos-plurais",
      },
      next: {
        name: "Keep on trail",
        slug: "rotina",
      },
    },
  ]
};

let routine = {
  color: '#3594C9',
  slug: 'rotina',
  title: "Routine",
  description: `
    <span class="paragraph">Here you understand the importance of aligning what Latin Americans expect from companies with their work routine</span>
    Creating more inclusive and diverse advertising demands a constant exercise of analysis. Looking to the daily life processes in relation to what the audience expects is a great way to start.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Global Pattern",
      slug: 'padrao-global',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        description: [],
      },
      hero: {
        name: "Global <br /> Pattern",
        image: `${process.env.PUBLIC_URL}/assets/rotina/padrao.png`,
        title: 'Lack of Identification',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '86%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '81%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '79%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '78%',
            country: 'Mexico',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '76%',
            country: 'Brazil',
            flag: '🇧🇷',
          }
        ],
        legend: '(Diversity and Inclusion - Facebook, 2020)',
        columns: [
          [
            'The diversity present in campaigns must be aligned with national realities in order to generate identification and demonstrate commitment.',
            "<b>But Latin American people don't feel that this happens.</b>",
            '<b class="colorfull">Something needs to be done.</b>',
          ],
          [
            '<b>One of the ways is to create opportunities for the adaptation of global campaigns to achieve better results.</b> This starts with questions about non-identification.',
            'Do Latin Americans not see themselves in advertising because it reproduces a stereotyped pattern of global diversity,</b> <span class="colorfull">with little appeal and reflection in each of the aforementioned countries?</span>',
          ],
        ]
      },
      prev: {
        name: "Go to Business",
        slug: "rotina/negocio",
      },
      next: {
        name: "Keep on trail",
        slug: "rotina/fornecedores",
      },
    },
    {
      index: 1,
      name: "Suppliers",
      slug: 'fornecedores',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        description: [],
      },
      hero: {
        name: "Suppliers",
        image: `${process.env.PUBLIC_URL}/assets/rotina/fornecedores.png`,
        title: 'Degree of Optimism',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '61%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '55%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '53%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#5093C4',
            percent: '52%',
            country: 'Brazil',
            flag: '🇧🇷',
          },
          {
            color: '#78B8DA',
            percent: '51%',
            country: 'Mexico',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversity and Inclusion  - Facebook, 2020)',
        columns: [
          [
            'There is optimism among Latin American people. Advertising is expected to be more inclusive and diverse in the next 5 years.',
            'To build today what people expect to see tomorrow, <b>you must create and hire plural teams capable of making expectations come true.</b>',
          ],
          [
            'Take some time to think: do you consider diversity of gender, race, sexuality and region when hiring or subcontracting agencies?',
            '<b>Do you have an extensive list of plural suppliers, or do you always repeat the same ones? Is there diversity among the leaders of the teams you work with?"</b>',
          ],
        ]
      },
      prev: {
        name: "Go to Global Pattern",
        slug: "rotina/padrao-global",
      },
      next: {
        name: "Keep on trail",
        slug: "rotina/influenciadores",
      },
    },
    {
      index: 2,
      name: "Influencers",
      slug: 'influenciadores',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        description: [],
      },
      hero: {
        name: "Influencers",
        image: `${process.env.PUBLIC_URL}/assets/rotina/influenciadores.png`,
        title: 'Lack of Representativeness',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '94%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#2B578C',
            percent: '85%',
            country: 'Brazil',
            flag: '🇧🇷',
          },
          {
            color: '#3C73A7',
            percent: '84%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#5093C4',
            percent: '81%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#78B8DA',
            percent: '80%',
            country: 'Mexico',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '79%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversity and Inclusion - Facebook, 2020)',
        columns: [
          [
            'The world is changing rapidly.',
            '<b>Generation Z is the one that least believes that advertising represents Latin American plurality.</b> Increasingly, inclusive marketing will be necessary.',
            '<b>The success of communication on social platforms depends on it,</b>  which allow for greater audience segmentation and, at the same time, amplify and pluralize voices that previously had no space.',
          ],
          [
            '<b>Has your hiring strategy prioritized influencers who represent audience diversity? <span class="colorfull">What stories do they tell that interest your brand?</span></b>',
            '<b>Do these aggregate regardless of the number of followers?</b>',
          ],
        ]
      },
      prev: {
        name: "Go to Suppliers",
        slug: "rotina/fornecedores",
      },
      next: {
        name: "Keep on trail",
        slug: "rotina/comunicacao",
      },
    },
    {
      index: 3,
      name: "Communication",
      slug: 'comunicacao',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        description: [],
      },
      hero: {
        name: "Communi-<br/>cation",
        image: `${process.env.PUBLIC_URL}/assets/rotina/comunicacao.png`,
        title: 'Satisfaction Index',
        description: [],
        list: [
          {
            color: '#1D3E70',
            percent: '36%',
            country: 'Mexico',
            flag: '🇲🇽',
          },
          {
            color: '#2B578C',
            percent: '33%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#3C73A7',
            percent: '32%',
            country: 'Brazil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '26%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '21%',
            country: 'Argentina',
            flag: '🇦🇷',
          },
          {
            color: '#97D4EB',
            percent: '21%',
            country: 'Peru',
            flag: '🇵🇪',
          }
        ],
        legend: '(Diversity and Inclusion - Facebook, 2020)',
        columns: [
          [
            'The percentages of Latin American people who are satisfied with the performance of the company they work for are low in terms of diversity and inclusion.',
            '<b>Change is necessary, and it requires all people to be active in this process.</b>',
            'It is necessary to promote debates and propagate good practices, with responsibility and without fear of discomfort.',
          ],
          [
            "<b class='colorfull'>Difficult conversations don't have to be disrespectful!</b>",
            'It may not be easy to talk about topics like race, gender and sexuality in the workplace, but it is necessary! Debate is an essential part of change and must be valued.',
          ],
        ]
      },
      prev: {
        name: "Go to Influencers",
        slug: "rotina/influenciadores",
      },
      next: {
        name: "Keep on trail",
        slug: "rotina/negocio",
      },
    },
    {
      index: 4,
      name: "Business",
      slug: 'negocio',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
      },
      hero: {
        name: "Business",
        image: `${process.env.PUBLIC_URL}/assets/rotina/tempo.png`,
        description: [],
        title: 'Aquisition Criteria',
        list: [
          {
            color: '#1D3E70',
            percent: '64%',
            country: 'Colombia',
            flag: '🇨🇴',
          },
          {
            color: '#2B578C',
            percent: '60%',
            country: 'Peru',
            flag: '🇵🇪',
          },
          {
            color: '#3C73A7',
            percent: '59%',
            country: 'Brazil',
            flag: '🇧🇷',
          },
          {
            color: '#5093C4',
            percent: '55%',
            country: 'Chile',
            flag: '🇨🇱',
          },
          {
            color: '#78B8DA',
            percent: '50%',
            country: 'Mexico',
            flag: '🇲🇽',
          },
          {
            color: '#97D4EB',
            percent: '46%',
            country: 'Argentina',
            flag: '🇦🇷',
          }
        ],
        legend: '(Diversity and Inclusion - Facebook, 2020)',
        columns: [
          [
            "Most Latin Americans believe that brands' commitment to diversity <b>is essential to their purchasing decision.</b>",
            "<b>In other words, ignoring the theme can mean losing connection with consumers.</b> The audience's priorities are changing, but isn't it",
          ],
          [
            'time to understand diversity and inclusion as goals for the entire production chain?',
            '<b>Time for diversity is time dedicated to business development.</b> Now is the time to create a work routine in which inclusion is not secondary, but strategic.',
            '<b class="colorfull">Dedicate yourself to what matters to consumers.</b>',
          ],
        ]
      },
      prev: {
        name: "Go to Communi-<br/>cation",
        slug: "rotina/comunicacao",
      },
      next: {
        name: "Keep on trail",
        slug: "boas-praticas",
      },
    },
  ]
};

let practices = {
  color: '#4CBF85',
  slug: 'boas-praticas',
  title: "Good Pratices",
  description: `
    <span class="paragraph">Knowing mistakes, anticipating risks and identifying opportunities demands looking again and again at campaigns and their messages. Doing this when dealing with different regions and countries is even more urgent.</span>
    Here are best practices for inclusive and innovative campaigns.
  `,
  cite: "",
  menu: [
    {
      index: 0,
      name: "Context",
      slug: 'contexto',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
      },
      hero: {
        name: "Context",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_01.png`,
        description: [],
        columns: [
          [
            '<b>Making brand messages fit the present time requires innovation.</b>',
            'Represent less usual forms of consumption and expand diversity within contexts such as parties, meetings between friends, family dinners, among others',
            '<span><b>When talking about Christmas, for example, you might choose a family led by single mothers and their children. By depicting bars or places typically associated with cis men, you can bring in cis women as the owners of the place.</b></span>',
          ],
          [
            'On the other hand, in sports scenarios, black trans women can be the protagonists. As well as in graduations, indigenous people can receive the certificate.',
            'As our society is constantly changing, we have to ask ourselves all the time how to represent people of flesh and blood: how do they behave, what do they like, how do they relate?',
            '<b>If you go out of date, your brand can be left behind, betting on scenes that have lost appeal!</b>',
          ],
        ]
      },
      prev: {
        name: "Go to Leadership",
        slug: "boas-praticas/lideranca",
      },
      next: {
        name: "Keep on trail",
        slug: "boas-praticas/local",
      },
    },
    {
      index: 1,
      name: "Local",
      slug: 'local',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
      },
      hero: {
        name: "Local",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_02.png`,
        description: [],
        columns: [
          [
            "<b>Campaigns don't need to talk about countries repeating the same symbols and places over and over.</b>",
            'Why limit cultures? Latin America is not just Brazil. Brazil is not just Rio de Janeiro or Christ the Redeemer. Colombia is not just Bogotá or the beaches of its Caribbean region. And Peru is definitely not just Machu Picchu.',
            '<span><b>Why limit cultures? Latin America is not just Brazil. Brazil is not just Rio de Janeiro or Christ the Redeemer. Colombia is not just Bogotá or the beaches of its Caribbean region. And Peru is definitely not just Machu Picchu.</b></span>',
          ],
          [
            'Summers in Argentina are different in the parks of Cordoba and in the Lakes of Patagonia. In Brazil, summers do have Copacabana beach, but they also have the rivers of Belém and the waterfalls of Chapada Diamantina. In every corner, thin people, well-toned, fat, with disabilities and of all colors and characteristics enjoy the sun, the pool, the slab.',
            "<b>Summers are many and everyone's. Cherish real Latin America!</b>",
          ],
        ]
      },
      prev: {
        name: "Go to Context",
        slug: "boas-praticas/contexto",
      },
      next: {
        name: "Keep on trail",
        slug: "boas-praticas/apropriacao-cultural",
      },
    },
    {
      index: 2,
      name: "Cultural Appropriation",
      slug: 'apropriacao-cultural',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
      },
      hero: {
        name: "Apropriação <br/> Cultural",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_03.png`,
        description: [],
        columns: [
          [
            '<b>Across Latin America, there are cultural traditions that should be praised for what they are.</b>',
            'Indigenous peoples, for example, are numerous and should not be portrayed as if they were a homogeneous block.',
            'We must be careful with shallow representations of millenary cultures.',
            'As happens when clothes that have ritual and religious value for a group are used by non-indigenous people and bodies. Elements of a culture cannot be taken out of one context and simply placed in another.',
            `<span><b>This is called <div class="tooltip-container">cultural appropriation<div class="tooltip-text bg-black">It consists in the use of symbols or values ​​of under-represented cultures in a light and shallow way, which causes trivialization and violence. Brands should not take possession of people's history to simply sell products or achieve their goals.</div></div>, and its impact can be negative for brands and companies.</b></span>`,
          ],
          [
            'How are peoples and their symbols being represented in your campaigns? ',
            '<b>Remember that indigenous people live in villages, but they also live in cities, they also drive cars, study in colleges, work in the National Congress.</b>',
            'When in doubt, hire indigenous people, talk to experts and/or study about indigenous anti-racist discussions.',
          ],
        ]
      },
      prev: {
        name: "Go to Local",
        slug: "boas-praticas/local",
      },
      next: {
        name: "Keep on trail",
        slug: "boas-praticas/descontextualizacao",
      },
    },
    {
      index: 3,
      name: "Decontextualization",
      slug: 'descontextualizacao',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
      },
      hero: {
        name: "Decontex- tualization",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_04.png`,
        description: [],
        columns: [
          [
            '<b>Symbols lose strength of identification when they are moved from one national reality to another.</b>',
            'For example, if, in a campaign aimed at young Brazilian audiences, the theme is based on North American high schools, the target audience will find it difficult to identify with the content.',
            '<span><b>If the intention is to communicate to the latin-american audience, why reproduce what works in the United States?</b></span>',
            'In a strategic creative process, the target audience is taken into account from the beginning, getting to know them and valuing their particularities to generate identification.',
          ],
          [
            '<b>Creating a communication that effectively dialogues with the other requires truth, that is, it demands messages that are supported by concrete facts.</b>',
            'Strategic advertising should not be based on assumptions by professionals in one country about what audiences in another want.',
            '<b>Aspirational advertising is mere idealization and not reality.</b> Research and data, yes, bring certainty about the national contexts and the desires of each target.',
          ],
        ]
      },
      prev: {
        name: "Go to Cultural Appropriation",
        slug: "boas-praticas/apropriacao-cultural",
      },
      next: {
        name: "Keep on trail",
        slug: "boas-praticas/lideranca",
      },
    },
    {
      index: 4,
      name: "Leadership",
      slug: 'lideranca',
      card: {
        height: 500,
        position: 'center',
        disabled_link: true,
        color: '#000000',
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
      },
      hero: {
        name: "Leadership",
        image: `${process.env.PUBLIC_URL}/assets/praticas/boa_05.png`,
        description: [],
        columns: [
          [
            '<b>For each target audience, the leader must be able to assess whether the message of a campaign take into consideration the reality addressed.</b>',
            'Have you ever thought about it? If yes, how are your campaigns validated? What criteria are used? Are they appropriate? Who are the people involved in this process of certifying a diverse and inclusive campaign?',
            '<span><b>Commitment to validation is important because not every idea that looks good has a positive impact, whether on audiences or sales.</b></span>',
          ],
          [
            'Then monitor the impacts generated by campaigns both internally, in terms of business, and externally, regarding the adhesion of people.',
            '<b>What are the diversity metrics and indicators used to assess results?</b>',
            "Do they let the company know what ground it's standing on as the world changes? Do they allow the reach of their campaign to be measured, considering gender, sexuality, class and race?",
            'Leaders and managers who promote an organizational culture concerned with diversity, validation and results are more likely to be innovative and inclusive.',
          ],
        ]
      },
      prev: {
        name: "Go to Decontex- tualization",
        slug: "boas-praticas/descontextualizacao",
      },
      next: {
        name: "Keep on trail",
        slug: "diversidade",
      },
    },
  ]
};

export function getDiversityEN() {
  return diversity
}
export function getRepresentsEN() {
  return represents
}
export function getStereotypesEN() {
  return stereotypes
}
export function getRoutineEN() {
  return routine
}
export function getPracticesEN() {
  return practices
}