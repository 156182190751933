import React from 'react'
import * as S from './styled'
import Header from '../Header'
import Footer from '../Footer'
import Overlay from '../../components/Overlay';

function Layout({ children, modal, color, bg, texture, currentPage, intro }) {
  return (
    <>
      <Header modal={modal} currentPage={currentPage} intro={intro} />
      <S.Main modal={modal} intro={intro}>
        { children }
      </S.Main>
      <Footer modal={modal} intro={intro} />
      <Overlay color={color} bg={bg} texture={texture} perfil={modal} />
    </>
  )
}

export default Layout