import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Container = styled.div`
  width: 100%;
`
export const Img = styled.img`
  border-radius: 12px;
  display: block;
  max-width: 100%;
  width: auto;
  margin: 0 auto 20px;
`
export const Columns = styled.div`
  display: grid;
  gap: clamp(1.125rem, -0.125rem + 1.5625vw, 1.75rem); // 18 - 28
  grid-template-columns: repeat(3, 1fr);
  padding: 0 30px;
  @media screen and (max-width: 700px) {
    padding: 0 15px;
  }
`
export const Column = styled.div`
  @media screen and (max-width: 700px) {
    grid-column: span 3;
  }
`
export const Title = styled.h4`
  color: ${colors.black};
  font-size: clamp(1rem, 0.5rem + 0.625vw, 1.25rem); // 16 - 20
  font-weight: 700;
  font-family: 'TCCC-UnityText-Bold';
  margin: 0 0 12px;
`
export const Text = styled.p`
  font-size: clamp(0.75rem, 0.25rem + 0.625vw, 1rem); // 12 - 16
`
