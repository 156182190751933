import styled from 'styled-components'
import { colors } from '../../globalStyles';

export const Arrow = styled.div`
  >a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 1000px) {
    >a {
      position: absolute;
      top: 0px;
      left: clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    }
  }
`
export const NavArrow = styled.span`
  width: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem); // 40 - 50
  height: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem); // 40 - 50
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color ? props.color : colors.red};
  border-radius: 100%;
  margin: 0 0 16px;
  img {
    display: block;
  }
`
export const NavText = styled.span`
  text-align: center;
  display: block;
  font-size: clamp(0.625rem, 0.575rem + 0.25vw, 0.875rem); // 10 - 14
  font-weight: 700;
  max-width: 90px;
  height: 60px;
  color: ${props => props.color ? props.color : colors.black};
  @media screen and (max-width: 1000px) {
    display: none;
  }
`